import angular from 'angular';

import {
    WidgetType, WidgetTypeGrouping
} from 'coreModules/design/widget/design.widget.constants';

import exportBuilderMenuHtmlUrl from './exportbuilder.menu.html';
import exportBuilderMenuPageHtmlUrl from './exportbuilder.menu.page.html';
import exportBuilderMenuPagePreviewHtmlUrl from './exportbuilder.menu.pagepreview.html';
import exportBuilderMenuElementHtmlUrl from './exportbuilder.menu.element.html';

/**
 * @ngInject
 */
function ExportBuilderMenuExportElement(
    AppFactory,
    $ExportBuilderDashboardEvents,
    $WidgetBuilderEvents,
    WidgetCreateFactory,
    ExportBuilderDashboardService,
    ExportBuilderFacadeUIService,
    WidgetType,
    WidgetFactory,
    DrawOption,
    PubSub,
    UIFactory,
    ReportElementTypes,
    ReportElementMacroTypes,
    WidgetUtilService,
    WidgetFilterFactory,
    ExportBuilderDashboardItemService,
    ExportBuilderElementActionService,
    WidgetDateRangeFactory,
    gettextCatalog,
    WidgetHeaderModalFactory,
    ReportStudioTemplateDataService,
    ExportBuilderMenuService
) {
    var vm = this;
    vm.ReportElementTypes = ReportElementTypes;
    vm.ReportElementMacroTypes = ReportElementMacroTypes;
    vm.canEditReport = false;

    vm.$onInit = $onInit;
    vm.onWidgetItemEdit = onWidgetItemEdit;
    vm.canIncludeEditWidget = canIncludeEditWidget;
    vm.onWidgetFilterClicked = onWidgetFilterClicked;
    vm.onWidgetFilterDeleteClicked = onWidgetFilterDeleteClicked;
    vm.canShowFilters = canShowFilters;
    vm.canShowOverrideDataRange = canShowOverrideDataRange;
    vm.showDeleteFilter = showDeleteFilter;
    vm.onCustomDateRangeClicked = onCustomDateRangeClicked;
    vm.onWidgetRefreshData = onWidgetRefreshData;
    vm.sendToBack = sendToBack;
    vm.sendToFront = sendToFront;
    vm.moveZ = moveZ;
    vm.onImageClicked = onImageClicked;
    vm.onDeleteClicked = onDeleteClicked;
    vm.onSizeToPageClicked = onSizeToPageClicked;
    vm.onCopyToPageClicked = onCopyToPageClicked;
    vm.onCopyToPreviousPageClicked = onCopyToPreviousPageClicked;
    vm.onCopyToNextPageClicked = onCopyToNextPageClicked;

    vm.canIncludeDevTools = canIncludeDevTools;
    vm.onEditWidgetHeader = onEditWidgetHeader;
    vm.canShowEditWidgetHeaderOption = canShowEditWidgetHeaderOption;
    vm.canShowWidgetOptions = canShowWidgetOptions;
    vm.canShowChatGptOption = canShowChatGptOption;
    vm.sendWidgetDataToChatGpt = sendWidgetDataToChatGpt;

    function $onInit() {
        vm.addEditFilterTitle = vm.element.hasWidgetFilters()
            ? gettextCatalog.getString('Edit Filter')
            : gettextCatalog.getString('Add Filter')

        vm.canEditReport = ExportBuilderDashboardService.canEditReport();
        const report = ExportBuilderDashboardService.getReport();
        vm.hasGranularAccess = hasGranularAccess(report);
    }

    function hasGranularAccess(report) {
        if (!report.has_granular_permissions) return true;

        return report.granular_access_levels.can_be_edited;
    }

    function sendToBack() {
        ExportBuilderElementActionService.setElementToBack(vm.element);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function canIncludeEditWidget() {
        if (AppFactory.getUser().isAgent() || AppFactory.getUser().isSuperAdmin()) {
            const widget = vm.element.widget;
            if (widget.has_live_integration && !widget.can_be_edited) {
                // can_be_edited will be false for ondemand widgets which were created with deprecated services
                // and in that case we cannot edit the widget further
                return 'disabled';
            }
            return (
                widget.metadata.dynamic?.is_inactive ||
                (!widget.has_live_integration && widget.metadata.dynamic?.errors?.length) // On Demand widgets should be editable in case of errors
            )
                ? 'disabled'
                : '';
        }
        return '';
    }

    function sendToFront() {
        ExportBuilderElementActionService.setElementToFront(vm.element);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function moveZ(increment) {
        ExportBuilderElementActionService.setZIndexForElement(vm.element, increment);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onImageClicked(event) {
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
        ExportBuilderFacadeUIService.changeImage(event, vm.element);
    }

    function onDeleteClicked() {
        ExportBuilderFacadeUIService.removeItem(vm.element);

        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onWidgetFilterClicked() {
        const defaultState = WidgetFactory.getDefaultState();
        WidgetFactory.setStateForWidgetType(defaultState, vm.element.widget.type, vm.element.widget.metadata);
        const widget = angular.copy(vm.element.widget);
        WidgetFilterFactory.$init(defaultState, widget);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onWidgetFilterDeleteClicked() {
        ExportBuilderElementActionService.deleteWidgetFilter(vm.element);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function canShowFilters() {
        return !(
          vm.element.widget.type === WidgetType.ACCOUNTMANAGER ||
          vm.element.widget.type === WidgetType.MEDIA ||
          vm.element.widget.type === WidgetType.EXECUTIVESUMMARY ||
          vm.element.widget.has_live_integration
        );
    }

    function canShowOverrideDataRange() {
        return !(
          vm.element.widget.type === WidgetType.ACCOUNTMANAGER ||
          vm.element.widget.type === WidgetType.MEDIA ||
          vm.element.widget.type === WidgetType.EXECUTIVESUMMARY
        );
    }

    function showDeleteFilter() {
        return vm.element.widget.metadata.dynamic
           && !_.isEmpty(vm.element.widget.metadata.dynamic.filters)
           && canShowFilters();
    }

    function onWidgetItemEdit() {
        var defaultState = WidgetFactory.getDefaultState();
        let widget = angular.copy(vm.element.widget);
        if (widget.type === WidgetType.MEDIA) {
            WidgetCreateFactory.$initEdit(defaultState, widget);
        } else {
            PubSub.emit($WidgetBuilderEvents.INIT_PANEL, {
                model: widget,
                isDataWidget: !WidgetUtilService.isAdminWidget(widget.type)
            });
        }

        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onCustomDateRangeClicked() {
        WidgetDateRangeFactory.initModal(angular.copy(vm.element.widget));
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onWidgetRefreshData() {
        ExportBuilderDashboardService.emptyWidgetCache(vm.element.widget);
        WidgetFactory.$rebuildWidget(vm.element.widget.id, true);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onSizeToPageClicked() {
        ExportBuilderElementActionService.setFitToPage(vm.element);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onCopyToPageClicked() {
        ExportBuilderElementActionService.setCopyInPlace(vm.element);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onCopyToPreviousPageClicked() {
        if (canCopyElement('previous')) {
            ExportBuilderElementActionService.setCopyOnPreviousPage(vm.element);
            PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
        }
    }

    function onCopyToNextPageClicked() {
        if (canCopyElement('next')) {
            ExportBuilderElementActionService.setCopyOnNextPage(vm.element);
            PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
        }
    }

    function canIncludeDevTools() {
        return vm.element.isTypeWidget()
            && AppFactory.getUser().hasDevTools;
    }

    function onEditWidgetHeader() {
        WidgetHeaderModalFactory.initModal(vm.element.widget);
    }

    function canShowEditWidgetHeaderOption() {
        return (vm.element.widget.type !== WidgetType.MEDIA);
    }

    /**
     * Returns true for all non-widget elements
     * Returns true for all non-executive summary widget elements
     *
     * @returns {boolean}
     */
    function canShowWidgetOptions() {
        if(!vm.hasGranularAccess)
            return false;
        if (vm.element.isTypeWidget()) {
            return vm.element.widget.type !== WidgetType.EXECUTIVESUMMARY;
        }
        return true;
    }

    function canShowChatGptOption() {
      return (
        !ReportStudioTemplateDataService.isDemoModeEnabled() &&
        AppFactory.canShowChatGptOption() &&
        WidgetUtilService.isDataWidget(vm.element.widget.type) &&
        vm.element.type === vm.ReportElementTypes.WIDGET &&
        canShowWidgetOptions() &&
        !isEmptyDataWidget(vm.element.widget.metadata)
      );
    }

    function sendWidgetDataToChatGpt() {
      ExportBuilderMenuService.sendWidgetDataToChatGpt(
        angular.copy(vm.element.widget)
      );
    }

    function isEmptyDataWidget(metadata) {
      return (
        !_.isEmpty(metadata.dynamic.errors) ||
        !metadata.dynamic.raw_data ||
        _.isNull(metadata.dynamic.raw_data.data) ||
        _.isEmpty(metadata.dynamic.raw_data.data) ||
        _.isNull(metadata.dynamic.raw_data.data[0].aaData) ||
        metadata?.dynamic?.raw_data?.data[0]?.aaData?.length === 0
      );
    }

    /**
     * Returns true if destination page is not executive summary page
     * or if the element is not a widget
     *
     * @param pageType
     * @returns {boolean}
     */
    function canCopyElement(pageType) {
        const report = ExportBuilderDashboardService.getBuilder().report;
        const pageIndex =
          pageType === "next"
            ? ExportBuilderDashboardService.getNextDesiredPageIndex()
            : ExportBuilderDashboardService.getPreviousDesiredPageIndex();
        const page = report.pages[pageIndex];
        const canCopyElement = page && (!page.is_executive_summary_page || !vm.element.isTypeWidget());

        if (!page) {
            return true;
        }
        if (!canCopyElement) {
            UIFactory.notify.showWarning('Widget cannot be copied to Executive Summary Page');
        }
        return canCopyElement;
    }
}

angular.module('exportbuilder.dashboard.components')
    .component('exportBuilderRightClickMenu', {
        bindings: {
        },
        templateUrl: exportBuilderMenuHtmlUrl,
        controller: ExportBuilderMenuContainerController,
        controllerAs: 'vm'
    })
    .component('exportBuilderMenuPage', {
        bindings: {
            page: '<'
        },
        templateUrl: exportBuilderMenuPageHtmlUrl,
        controller: ExportBuilderMenuPage,
        controllerAs: 'vm'
    })
    .component('exportBuilderMenuPagePreview', {
        bindings: {
            page: '<'
        },
        templateUrl: exportBuilderMenuPagePreviewHtmlUrl,
        controller: ExportBuilderMenuPagePreview,
        controllerAs: 'vm'
    })
    .component('exportBuilderMenuExportElement', {
        bindings: {
            element: '<'
        },
        templateUrl: exportBuilderMenuElementHtmlUrl,
        controller: ExportBuilderMenuExportElement,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderMenuContainerController(
    $element,
    PubSub,
    $ExportBuilderDashboardEvents,
    WidgetBuilderUIService,
    MenuType,
    ExportBuilderMenuService
) {
    var vm = this;
    vm.MenuType = MenuType;

    vm.state = {
        isActive: false,
        style: {}
    };
    vm.data = {};

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onMouseClickedOutside = onMouseClickedOutside;
    vm.isActive = isActive;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _showMenu(data) {
        vm.data = data;
        vm.state.isActive = true;
        ExportBuilderMenuService.setPosition(data.position);
    }

    function onMouseClickedOutside() {
        if (!vm.state.isActive) {
            return;
        }

        _closeMenu();
    }

    function isActive() {
        return !WidgetBuilderUIService.isActive() && vm.state.isActive;
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderDashboardEvents.RIGHT_CLICK_MENU, _showMenu);
        PubSub.on($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU, _closeMenu);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderDashboardEvents.RIGHT_CLICK_MENU, _showMenu);
        PubSub.off($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU, _closeMenu);
    }

    function _closeMenu() {
        vm.state.isActive = false;
    }
}

/**
 * @ngInject
 */
function ExportBuilderMenuPage(
    $timeout,
    $WidgetBuilderEvents,
    ReportElementMacroTypes,
    ExportBuilderDashboardService,
    ExportBuilderElementActionService,
    ExportBuilderFacadeUIService,
    ReportElementShapeTypes,
    $ExportBuilderDashboardEvents,
    $ExportBuilderIconModalEvents,
    ReportElementTypes,
    PubSub,
    WidgetCreateFactory
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.onDataWidgetClicked = onDataWidgetClicked;
    vm.onMediaWidgetClicked = onMediaWidgetClicked;
    vm.onAdminWidgetClicked = onAdminWidgetClicked;
    vm.onImageElementClicked = onImageElementClicked;
    vm.onTextElementClicked = onTextElementClicked;
    vm.onIconElementClicked = onIconElementClicked;
    vm.onMacroDateRangeClicked = onMacroDateRangeClicked;
    vm.onMacroCompanyLogoClicked = onMacroCompanyLogoClicked;
    vm.onMacroRecipientNameClicked = onMacroRecipientNameClicked;
    vm.onMacroPageTitleClicked = onMacroPageTitleClicked;
    vm.onMacroPageNumberClicked = onMacroPageNumberClicked;
    vm.onMacroCreatedOnClicked = onMacroCreatedOnClicked;
    vm.onSquareShapeClicked = onSquareShapeClicked;
    vm.onCircleShapeClicked = onCircleShapeClicked;

    /**
     * @type {boolean}
     */
    vm.isExecutiveSummaryPage = false;

    function $onInit() {
        vm.currentPage = ExportBuilderDashboardService.getBuilder().currentPage;
        vm.isExecutiveSummaryPage = vm.currentPage.is_executive_summary_page;
    }
    function onDataWidgetClicked() {
        PubSub.emit($WidgetBuilderEvents.INIT_PANEL, {model: null, isDataWidget: true});
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onMediaWidgetClicked() {
        WidgetCreateFactory.$initNew(WidgetTypeGrouping.DISPLAY);
    }

    function onAdminWidgetClicked() {
        PubSub.emit($WidgetBuilderEvents.INIT_PANEL, {model: null, isDataWidget: false});
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onImageElementClicked(event) {
        ExportBuilderFacadeUIService.setElementChangingImage(null);
        ExportBuilderFacadeUIService.showImageDialog(event);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onTextElementClicked() {
        var params = {
            isEditing: true,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
        // var item = ExportBuilderDashboardService.addNewItem(ReportElementTypes.TEXT, params);
        // _postAddNewItem(item);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onMacroDateRangeClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.DATE_RANGE,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
        // var item = ExportBuilderDashboardService.addNewItem(ReportElementTypes.TEXT, params);
        // _postAddNewItem(item);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onMacroCompanyLogoClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.COMPANY_LOGO,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.IMAGE, params);
        // var item = ExportBuilderDashboardService.addNewItem(ReportElementTypes.IMAGE, params);
        // _postAddNewItem(item);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onMacroRecipientNameClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.RECIPIENT_NAME,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
        // var item = ExportBuilderDashboardService.addNewItem(ReportElementTypes.TEXT, params);
        // _postAddNewItem(item);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onMacroPageTitleClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.PAGE_TITLE,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
        // var item = ExportBuilderDashboardService.addNewItem(ReportElementTypes.TEXT, params);
        // _postAddNewItem(item);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onMacroPageNumberClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.PAGE_NUMBER,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
        // var item = ExportBuilderDashboardService.addNewItem(ReportElementTypes.TEXT, params);
        // _postAddNewItem(item);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onMacroCreatedOnClicked() {
        var params = {
            macro_type: ReportElementMacroTypes.CREATED_ON,
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.TEXT, params);
        // var item = ExportBuilderDashboardService.addNewItem(ReportElementTypes.TEXT, params);
        // _postAddNewItem(item);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onSquareShapeClicked() {
        var params = {
            shape_type: ReportElementShapeTypes.SQUARE
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.SHAPE, params);
        // var item = ExportBuilderDashboardService.addNewItem(ReportElementTypes.SHAPE, params);
        // _postAddNewItem(item);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onCircleShapeClicked() {
        var params = {
            shape_type: ReportElementShapeTypes.CIRCLE
        };
        ExportBuilderElementActionService.addNewItem(ReportElementTypes.SHAPE, params);
        // var item = ExportBuilderDashboardService.addNewItem(ReportElementTypes.SHAPE, params);
        // _postAddNewItem(item);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onIconElementClicked() {
        PubSub.emit($ExportBuilderIconModalEvents.OPEN_NEW);
    }

    function _postAddNewItem(item) {
        vm.showOnEveryPage = false;
        focusOnItem(item);
    }

    function focusOnItem(item) {
        $timeout(function() {
            ExportBuilderDashboardService.focusOnItem(item);
        }, 0);
    }
}

/**
 * @ngInject
 */
function ExportBuilderMenuPagePreview(
    ExportBuilderDashboardService,
    $ExportBuilderDashboardEvents,
    PubSub
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.onAddPageBeforeClicked = onAddPageBeforeClicked;
    vm.onAddPageAfterClicked = onAddPageAfterClicked;
    vm.onCopyClicked = onCopyClicked;
    vm.onDeleteClicked = onDeleteClicked;
    vm.onCoverPageClicked = onCoverPageClicked;
    vm.onBackPageClicked = onBackPageClicked;
    vm.isExecSummaryPage = !!vm.page.is_executive_summary_page;

    function $onInit() {
        vm.report = ExportBuilderDashboardService.getReport();
        vm.hasGranularAccess = hasGranularAccess(vm.report);
    }

    function hasGranularAccess(report) {
        if (!report.has_granular_permissions) return true;

        return report.granular_access_levels.can_be_edited;
    }

    function onAddPageBeforeClicked() {
        PubSub.emit($ExportBuilderDashboardEvents.CREATE_PAGE, parseInt(vm.page.page_index));
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onAddPageAfterClicked() {
        PubSub.emit($ExportBuilderDashboardEvents.CREATE_PAGE, parseInt(vm.page.page_index) + 1);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onCopyClicked() {
        PubSub.emit($ExportBuilderDashboardEvents.COPY_PAGE, parseInt(vm.page.id));
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onDeleteClicked() {
        PubSub.emit($ExportBuilderDashboardEvents.DELETE_PAGE, parseInt(vm.page.page_index));
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onCoverPageClicked() {
        PubSub.emit($ExportBuilderDashboardEvents.MAKE_COVER_PAGE, vm.page);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }

    function onBackPageClicked() {
        PubSub.emit($ExportBuilderDashboardEvents.MAKE_BACK_PAGE, vm.page);
        PubSub.emit($ExportBuilderDashboardEvents.CLOSE_RIGHT_CLICK_MENU);
    }
}