import ClientResource from '@/modules/ta/client/resources/ClientResource';
import { BaseListService } from '@/modules/core/list/services/BaseListService';
import { dispatches } from '@/modules/core/app/helpers/store';

export class ClientListDataService extends BaseListService {
  constructor() {
    super(ClientResource);
  }

  /**
   * @param id
   * @returns {Promise<AxiosPromise<any>>}
   */
  async copyItem(id) {
    const result = await super.copyItem(id);
    dispatches.client.refreshActiveClientValues();
    return result;
  }

  /**
   * @param id
   * @returns {Promise<AxiosPromise<any>>}
   */
  async deleteItem(id) {
    const result = await super.deleteItem(id);
    dispatches.client.refreshActiveClientValues();
    dispatches.dashboard.setFilterOptions();
    return result;
  }

  /**
   * saves selected column preferences in list page
   * @param data
   * @returns {*}
   */
  saveColumnPreference(data) {
    return this.resource.saveColumnPreferences(data);
  }
}

export default new ClientListDataService();
