import { BaseEntityResource } from '@/modules/core/app/resources/BaseEntityResource';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import { Role } from '@/modules/ta/role/models/Role';
import { GenericModel } from '@/modules/core/app/models/GenericModel';
import { apiAxios } from '@/modules/core/app/helpers/AxiosHelper';

export class RoleResource extends BaseEntityResource {
  constructor() {
    super(RouteGroup.ROLES, Role);
  }

  /**
   * @param userType
   * @returns {Promise<*>}
   */
  async getRolePermissionSchema(userType) {
    const subRoute = userType ? `schema/${userType}` : 'schema';
    return super.getData(GenericModel, subRoute, { override: true });
  }

  /**
   * @returns {Promise<*>}
   */
  async getUserTypeModules() {
    return super.getData(GenericModel, 'usertypemodules');
  }

  async setIsSelectable(id, value) {
    return super.postData({ id, value }, `setselectable`);
  }

  async checkSetDefault(id, userType) {
    return super.getData(GenericModel, `checksetdefault/${id}`, { userType });
  }

  /**
   *
   * @param roleId
   * @returns {Promise<{string}>}
   */

  async getUsages(roleId) {
    return apiAxios.get(`/${this.routeGroup}/usage/${roleId}`);
  }

  /**
   *
   * @param params
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  async getRoleUsersWithPermissions(params) {
    // eslint-disable-next-line tap/no-raw-text-js
    return apiAxios.get('/granularpermissions/roleuserswithpermissions', { params });
  }
}

export default new RoleResource();
