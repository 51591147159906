import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';

export class Cluster extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  name;

  /**
   * @var {string}
   */
  type;

  /**
   * @var {boolean}
   */
  has_light_logo;

  /**
   * @var {boolean}
   */
  has_dark_logo;

  /**
   * @var {int}
   */
  notification_profile_id;

  /**
   * @var {?string}
   */
  external_cluster_id;

  /**
   * @var {boolean}
   */
  enable_inventory_calendar;

  /**
   * @var {boolean}
   */
  enable_in_out_status_board;

  /**
   * @var {boolean}
   */
  is_cluster_group;

  /**
   * @var {string}
   */
  is_cluster_group_display;

  /**
   * @var {?string}
   */
  member_cluster_ids;

  /**
   * @var {?string}
   */
  member_clusters;

  /**
   * @var {string}
   */
  status;

  /**
   * @var {string}
   */
  status_display;

  /**
   * @var {?string}
   */
  inherited_whitelabel_cluster_id;

  /**
   * @var {boolean}
   */
  is_modal_enabled;

  /**
   * @var {boolean}
   */
  show_name_in_tab;

  /**
   * @var {boolean}
   */
  use_logo_as_favicon;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.name = model.name;
    this.type = model.type;
    this.type_display = model.type_display;
    this.has_light_logo = model.has_light_logo;
    this.has_dark_logo = model.has_dark_logo;
    this.notification_profile_id = model.notification_profile_id;
    this.external_cluster_id = model.external_cluster_id;
    this.enable_inventory_calendar = model.enable_inventory_calendar;
    this.enable_in_out_status_board = model.enable_in_out_status_board;
    this.is_cluster_group = model.is_cluster_group;
    this.is_cluster_group_display = model.is_cluster_group_display;
    this.member_cluster_ids = model.member_cluster_ids;
    this.member_clusters = model.member_clusters;
    this.status = model.status;
    this.status_display = model.status_display;
    this.inherited_whitelabel_cluster_id = model.inherited_whitelabel_cluster_id;
    this.is_modal_enabled = model.is_modal_enabled;
    this.show_name_in_tab = model.show_name_in_tab;
    this.use_logo_as_favicon = model.use_logo_as_favicon;
  }
}
