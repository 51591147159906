import { isNil } from 'lodash';
import { rem } from '@/modules/core/helper';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import {
  DataFontMultiplier,
  DeltaFontMultiplier,
  DeltaIconFontMultiplier,
  LabelFontMultiplier,
  DataFontMultiplierSquare,
  DataComaprisonFontMultiplierSquare,
  DataComaprisonMultiplierSquare,
  DataLabelMultiplierSquare,
  FONT_SIZE_THRESHOLD,
  BackgroudGradient,
  DataFontMultiplierMultiMetric,
  DataComaprisonMultiplierMultiMetric,
  MaxFontSizes,
} from '@/modules/core/scorecard/scorecard.constants';
import NumeralService from '@/modules/core/app/services/NumeralService';

export const ScoreCardMixin = {
  props: {
    value: {
      type: [Number, String],
    },
    label: {
      type: String,
    },
    emptyDataMessage: {
      type: String,
    },
    showMetricName: {
      type: String,
    },
    isCompactNumber: {
      type: Boolean,
    },
    comparisonValue: {
      type: [Number, String],
    },
    format: {
      type: String,
    },
    precision: {
      type: [Number, String],
    },
    isComparison: {
      type: Boolean,
    },
    showComparisonArrows: {
      type: Boolean,
      default: true,
    },
    showDefaultComparisonArrowColor: {
      type: Boolean,
      default: true,
    },
    fontSize: {
      type: Number,
    },
    compareAsValue: {
      type: Boolean,
      default: false,
    },
    compareInverted: {
      type: Boolean,
      default: false,
    },
    fontProperties: {
      type: [Object, Array],
      default: () => ({}),
    },
    containerStyles: {
      type: Object,
      default: () => ({}),
    },
    wrapSize: {
      type: Number,
      default: 100,
    },
    wrapMetricName: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: '',
    },
    borderWidth: {
      type: Number,
      default: 0,
    },
    backgroundGradient: {
      type: String,
      default: 'solid',
    },
    gradientColor: {
      type: String,
      default: '',
    },
    cornerRadius: {
      type: Number,
      default: 0,
    },
    isComparisonOverridden: {
      type: Boolean,
      default: false,
    },
    isExportingReportStudio: {
      type: Boolean,
      default: false,
    },
    currencySymbol: {
      type: String,
      default: '',
    },
    isRankingMetric: {
      type: Boolean,
      default: false,
    },
  },
  static: {
    LabelFontMultiplier,
    DataFontMultiplier,
    DeltaFontMultiplier,
    DeltaIconFontMultiplier,
    DataFontMultiplierSquare,
    DataComaprisonMultiplierSquare,
    DataLabelMultiplierSquare,
    DataComaprisonFontMultiplierSquare,
    DataFontMultiplierMultiMetric,
    DataComaprisonMultiplierMultiMetric,
    MaxFontSizes,
  },
  computed: {
    isLoadingValue() {
      return this.value === undefined;
    },
    formattedValue() {
      return NumeralService.formatValue(
        this.value,
        this.format,
        this.precision,
        this.isCompactNumber,
        this.currencySymbol
      );
    },
    formattedValueTooltip() {
      return NumeralService.formatValue(this.value, this.format, this.precision, false);
    },
    comparisonValueTooltip() {
      return NumeralService.formatValue(this.comparisonValue, this.format, this.precision, false);
    },
    formattedComparisonValue() {
      return NumeralService.formatValue(
        this.comparisonValue,
        this.format,
        this.precision,
        this.isCompactNumber,
        this.currencySymbol
      );
    },
    formattedDeltaValue() {
      return NumeralService.formatValue(
        Math.abs(this.deltaValue),
        ColumnFormat.FORMAT_INTEGER,
        0,
        this.isCompactNumber,
        this.currencySymbol
      );
    },
    deltaValue() {
      if (!this.isComparison) {
        return null;
      }

      return NumeralService.calculateDelta(
        this.value,
        this.comparisonValue,
        this.compareAsValue,
        this.format
      );
    },
    hasComparison() {
      return this.isComparison && !isNil(this.comparisonValue);
    },
    deltaValueClasses() {
      const classes = [];

      if (!this.showDefaultComparisonArrowColor || this.deltaValue === 0) {
        classes.push('neutral-delta');
      } else if (this.deltaValue > 0) {
        this.isRankingMetric
          ? classes.push(this.getPlusOrMinusDelta('down'))
          : classes.push(this.getPlusOrMinusDelta('up'));
      } else if (this.deltaValue < 0) {
        this.isRankingMetric
          ? classes.push(this.getPlusOrMinusDelta('up'))
          : classes.push(this.getPlusOrMinusDelta('down'));
      }

      return classes;
    },
    comparisonValueClasses() {
      return this.formattedComparisonValue === '-' ? ['p-r-2'] : [];
    },
    iconClass() {
      if (this.isRankingMetric) {
        return this.deltaValue >= 0 ? 'fa-caret-down' : 'fa-caret-up';
      }
      return this.deltaValue >= 0 ? 'fa-caret-up' : 'fa-caret-down';
    },
    fontStyles() {
      const styles = { 'text-decoration': '' };
      if (this.fontProperties.bold) {
        styles['font-weight'] = '900';
      }
      if (this.fontProperties.italic) {
        styles['font-style'] = 'italic';
      }
      if (this.fontProperties.underline) {
        styles['text-decoration'] = 'underline';
      }
      if (this.fontProperties.strikethrough) {
        styles['text-decoration'] += ' line-through';
      }
      if (this.fontProperties.text_color) {
        styles.color = this.fontProperties.text_color;
      }
      return styles;
    },
    fontColorStyles() {
      if (this.fontProperties.text_color) {
        return { color: this.fontProperties.text_color };
      }
      return {};
    },
    borderStyles() {
      if (this.borderColor && this.borderWidth) {
        return { border: `${this.borderWidth}px solid ${this.borderColor} ` };
      }
      return {};
    },
    radiantStyles() {
      if (this.backgroundGradient === BackgroudGradient.LINEAR) {
        return {
          'background-image': `linear-gradient(to right, ${this.containerStyles.background} , ${this.gradientColor})`,
        };
      }
      if (this.backgroundGradient === BackgroudGradient.RADIAL) {
        return {
          'background-image': `radial-gradient(${this.containerStyles.background} , ${this.gradientColor})`,
        };
      }
      return {};
    },
    radiusStyles() {
      return { 'border-radius': `${this.cornerRadius}px` };
    },
    deltaIconClass() {
      if (this.isRankingMetric) {
        return this.deltaValue >= 0 ? 'icon-caret-down' : 'icon-caret-up';
      }
      return this.deltaValue >= 0 ? 'icon-caret-up' : 'icon-caret-down';
    },
  },
  methods: {
    getFontSizeStyleMaxSize(multiplier, maxSize) {
      const scaledSize = this.fontSize * multiplier;
      if (scaledSize > maxSize) {
        return maxSize === 0 ? scaledSize : maxSize;
      }

      return scaledSize;
    },
    getFontSizeStyle(minMultiplier, maxMultiplier, maxSize = 0) {
      const styles = {};

      if (this.fontSize) {
        const fontSize =
          this.fontSize < FONT_SIZE_THRESHOLD
            ? this.getFontSizeStyleMaxSize(maxMultiplier, maxSize)
            : this.getFontSizeStyleMaxSize(minMultiplier, maxSize);
        styles.fontSize = `${fontSize}px`;
      }
      return styles;
    },
    getPlusOrMinusDelta(deltaDirection = 'up') {
      let plusOrMinusDelta;
      if (deltaDirection === 'up') {
        plusOrMinusDelta = this.compareInverted ? 'negative-delta' : 'positive-delta';
      } else {
        plusOrMinusDelta = this.compareInverted ? 'positive-delta' : 'negative-delta';
      }

      if (this.isComparisonOverridden) {
        plusOrMinusDelta =
          plusOrMinusDelta === 'negative-delta' ? 'positive-delta' : 'negative-delta';
      }

      return plusOrMinusDelta;
    },
    isFormattedEllipsisActive() {
      if (
        ((this.isCompactNumber && this.format !== ColumnFormat.FORMAT_PERCENT) ||
          this.$refs.formattedTooltip?.offsetWidth < this.$refs.formattedTooltip?.scrollWidth) &&
        this.value > 1000
      ) {
        return this.formattedValueTooltip;
      }
      return '';
    },
    getIconSizeStyle(minMultiplier, maxMultiplier) {
      const styles = {};

      if (this.fontSize) {
        const size =
          this.fontSize < FONT_SIZE_THRESHOLD
            ? this.fontSize * maxMultiplier
            : this.fontSize * minMultiplier;
        styles.width = `${size}px`;
        styles.height = `${size}px`;
        styles['padding-top'] = rem('2px');
      }
      return styles;
    },
  },
};
