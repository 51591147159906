import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';
import { OverviewDisplay, UserType } from '@/modules/ta/user/user.constants';
import { PredefinedRoleValues } from '@/modules/ta/role/role.constants';

export class User extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {?string}
   */
  client_id;

  /**
   * @var {string}
   */
  client_name;

  /**
   * @var {string}
   */
  client_reporting_status;

  /**
   * @var {string}
   */
  user_type;

  /**
   * @var {?string}
   */
  company_name;

  /**
   * @var {string}
   */
  first_name;

  /**
   * @var {string}
   */
  last_name;

  /**
   * @var {string}
   */
  display_name;

  /**
   * @var {string}
   */
  display_id;

  /**
   * @var {string}
   */
  address_1;

  /**
   * @var {string}
   */
  address_2;

  /**
   * @var {string}
   */
  city;

  /**
   * @var {string}
   */
  state;

  /**
   * @var {string}
   */
  zip;

  /**
   * @var {string}
   */
  phone;

  /**
   * @var {string}
   */
  email;

  /**
   * @var {string}
   */
  password;

  /**
   * @var {?string}
   */
  cluster_id;

  /**
   * @var {?string}
   */
  cluster_name;

  /**
   * @var {?boolean}
   */
  cluster_is_cluster_group;

  /**
   * @var {string}
   */
  external_user_id;

  /**
   * @var {string}
   */
  additional_details_1;

  /**
   * @var {string}
   */
  additional_details_2;

  /**
   * @var {string}
   */
  additional_details_3;

  /**
   * @var {boolean}
   */
  enable_lead_management_module;

  /**
   * @var {boolean}
   */
  enable_io_tool_module;

  /**
   * @var {string}
   */
  lead_notification_frequency;

  /**
   * @var {string}
   */
  lead_notification_frequency_display;

  /**
   * @var {boolean}
   */
  has_light_logo;

  /**
   * @var {boolean}
   */
  has_dark_logo;

  /**
   * @var {boolean}
   */
  override_dashboard_pages_from_reporting_profile;

  /**
   * @var {String}
   */
  override_dashboard_page_ids;

  /**
   * @var {?string}
   */
  default_home_page;

  /**
   * @var {?string}
   */
  io_tool_notification_frequency;

  /**
   * @var {string}
   */
  country;

  /**
   * @var {string}
   */
  timezone;

  /**
   * @var {string}
   */
  timegroup;

  /**
   * @var {string}
   */
  status;

  /**
   * @var {number}
   */
  hipaa_acknowledgement_timestamp;

  /**
   * @var {?datetime}
   */
  creation_time;

  /**
   * @var {?string}
   */
  created_by_user_id;

  /**
   * @var {?string}
   */
  created_by_user;

  /**
   * @var {string}
   */
  show_services_overview;

  /**
   * @var {string}
   */
  show_categories_overview;

  /**
   * @var {string}
   */
  show_services_overview_display;

  /**
   * @var {string}
   */
  show_categories_overview_display;

  /**
   * @var {?string}
   */
  role_id;

  /**
   * @var {string}
   */
  role_name;

  /**
   * @var {?string}
   */
  role_predefined_role_key;

  /**
   * @var {boolean}
   */
  role_is_accessible;

  /**
   * @var {?string}
   */
  io_tool_role_id;

  /**
   * @var {?string}
   */
  io_tool_role_name;

  /**
   * @var {?string}
   */
  io_tool_role_is_accessible;

  /**
   * @var {boolean}
   */
  io_tool_use_in_out_status;

  /**
   * @var {boolean}
   */
  io_tool_extended_absence;

  /**
   * @var {?string}
   */
  client_group_id;

  /**
   * @var {string}
   */
  client_group_name;

  /**
   * @var {string}
   */
  reporting_profile_id;

  /**
   * @var {string}
   */
  reporting_profile_name;

  /**
   * @var {string}
   */
  user_image_id;

  /**
   * @var {string}
   */
  user_type_display;

  /**
   * @var {string}
   */
  default_home_page_display;

  /**
   * @var {string}
   */
  io_tool_notification_frequency_display;

  /**
   * @var {array}
   */
  io_tool_queue_ids;

  /**
   * @var {array}
   */
  io_tool_queues;

  /**
   * @var {string}
   */
  io_tool_notification_own_activity;

  /**
   * @var {string}
   */
  country_display;

  /**
   * @var {string}
   */
  timezone_display;

  /**
   * @var {string}
   */
  status_display;

  /**
   * @var {string}
   */
  formatted_creation_time;

  /**
   * @var {string}
   */
  user_image_metadata;

  /**
   * @var {string}
   */
  report_language;

  /**
   * @var {string}
   */
  report_language_display;

  /**
   * @var {string}
   */
  last_login_timestamp;

  /**
   * @var {string}
   */
  formatted_last_login_timestamp;

  /**
   * @var {boolean}
   */
  show_welcome_modal;

  /**
   * @var {boolean}
   */
  use_nui;

  /**
   * @var {boolean}
   */
  can_access_nui;

  /**
   * @var {boolean}
   */
  can_access_new_services;

  /**
   * @var {string}
   */
  nui_feedback;

  /**
   * @var {boolean}
   */
  use_sup_goals;

  /**
   * @var {boolean}
   */
  enable_gds_module;

  /**
   * @var {boolean}
   */
  enable_sheets_module;

  /**
   * @var {boolean}
   */
  enable_stellar_module;

  /**
   * @var {boolean}
   */
  enable_planner_module;

  /**
   * @var {boolean}
   */
  enable_tap_gpt_module;

  /**
   * @var {Object}
   */
  granular_permissions_by_entity;

  /**
   * @var {string}
   */
  first_login;

  /**
   * @var {string}
   */
  formatted_first_login;

  /**
   * @var {int}
   */
  login_count;

  /**
   * @var {boolean}
   */
  two_step_verification_enabled;

  constructor(model = {}) {
    super(model);
    this.id = model.id;
    this.client_id = model.client_id;
    this.client_name = model.client_name;
    this.client_reporting_status = model.client_reporting_status;
    this.user_type = model.user_type;
    this.company_name = model.company_name;
    this.first_name = model.first_name;
    this.last_name = model.last_name;
    this.display_name = model.display_name;
    this.display_id = model.display_id;
    this.address_1 = model.address_1;
    this.address_2 = model.address_2;
    this.city = model.city;
    this.state = model.state;
    this.zip = model.zip;
    this.phone = model.phone;
    this.email = model.email;
    this.password = model.password;
    this.cluster_id = model.cluster_id;
    this.cluster_name = model.cluster_name;
    this.cluster_is_cluster_group = model.cluster_is_cluster_group;
    this.external_user_id = model.external_user_id;
    this.additional_details_1 = model.additional_details_1;
    this.additional_details_2 = model.additional_details_2;
    this.additional_details_3 = model.additional_details_3;
    this.enable_lead_management_module = model.enable_lead_management_module;
    this.enable_io_tool_module = model.enable_io_tool_module;
    this.lead_notification_frequency = model.lead_notification_frequency;
    this.lead_notification_frequency_display = model.lead_notification_frequency_display;
    this.granular_permissions_by_entity = model.granular_permissions_by_entity;
    this.has_light_logo = model.has_light_logo;
    this.has_dark_logo = model.has_dark_logo;
    this.override_dashboard_pages_from_reporting_profile =
      model.override_dashboard_pages_from_reporting_profile;
    this.default_home_page = model.default_home_page;
    this.io_tool_notification_frequency = model.io_tool_notification_frequency;
    this.country = model.country;
    this.timezone = model.timezone ?? 'default';
    this.timegroup = model.timegroup;
    this.status = model.status;
    this.hipaa_acknowledgement_timestamp = model.hipaa_acknowledgement_timestamp;
    this.creation_time = model.creation_time;
    this.created_by_user_id = model.created_by_user_id;
    this.created_by_user = model.created_by_user;
    this.show_services_overview = model.show_services_overview;
    this.show_categories_overview = model.show_categories_overview;
    this.show_services_overview_display = model.show_services_overview_display;
    this.show_categories_overview_display = model.show_categories_overview_display;
    this.role_id = model.role_id;
    this.role_name = model.role_name ?? this.getPredefinedRoleBasedOnUserType();
    this.role_predefined_role_key = model.role_predefined_role_key;
    this.role_is_accessible = model.role_is_accessible;
    this.io_tool_role_id = model.io_tool_role_id;
    this.io_tool_role_name = model.io_tool_role_name;
    this.io_tool_role_is_accessible = model.io_tool_role_is_accessible;
    this.io_tool_use_in_out_status = model.io_tool_use_in_out_status;
    this.io_tool_extended_absence = model.io_tool_extended_absence;
    this.client_group_id = model.client_group_id;
    this.client_group_name = model.client_group_name;
    this.reporting_profile_id = model.reporting_profile_id;
    this.reporting_profile_name = model.reporting_profile_name;
    this.user_image_id = model.user_image_id;
    this.user_type_display = model.user_type_display;
    this.default_home_page_display = model.default_home_page_display;
    this.io_tool_notification_frequency_display = model.io_tool_notification_frequency_display;
    this.io_tool_queue_ids = model.io_tool_queue_ids;
    this.io_tool_queues = model.io_tool_queues;
    this.io_tool_notification_own_activity = model.io_tool_notification_own_activity;
    this.country_display = model.country_display;
    this.timezone_display = model.timezone_display;
    this.status_display = model.status_display;
    this.formatted_creation_time = model.formatted_creation_time;
    this.user_image_metadata = model.user_image_metadata;
    this.override_dashboard_page_ids = model.override_dashboard_page_ids;
    this.report_language = model.report_language;
    this.report_language_display = model.report_language_display;
    this.last_login_timestamp = model.last_login_timestamp;
    this.formatted_last_login_timestamp = model.formatted_last_login_timestamp;
    this.login_count = model.login_count;
    this.first_login = model.first_login;
    this.formatted_first_login = model.formatted_first_login;
    this.show_welcome_modal = model.show_welcome_modal;
    this.use_nui = model.use_nui;
    this.can_access_nui = model.can_access_nui;
    this.can_access_new_services = model.can_access_new_services;
    this.nui_feedback = model.nui_feedback;
    this.enable_lead_management_module = model.enable_lead_management_module;
    this.use_sup_goals = model.use_sup_goals;
    this.enable_gds_module = model.enable_gds_module;
    this.enable_sheets_module = model.enable_sheets_module;
    this.enable_stellar_module = model.enable_stellar_module;
    this.enable_planner_module = model.enable_planner_module;
    this.enable_tap_gpt_module = model.enable_tap_gpt_module;
    this.two_step_verification_enabled = model.two_step_verification_enabled;
  }

  /**
   * @returns {boolean}
   */
  isSuperAdmin() {
    return this.user_type === UserType.SUPERADMIN;
  }

  /**
   * @returns {boolean}
   */
  isClusterAdmin() {
    return this.user_type === UserType.CLUSTERADMIN;
  }

  /**
   * @returns {boolean}
   */
  isAdmin() {
    return this.user_type === UserType.SUPERADMIN || this.user_type === UserType.CLUSTERADMIN;
  }

  /**
   * @returns {boolean}
   */
  isAgent() {
    return this.user_type === UserType.AGENT;
  }

  /**
   * @returns {boolean}
   */
  isClient() {
    return this.user_type === UserType.CLIENT;
  }

  getLabel() {
    const label = this.first_name
      ? `${this.first_name || ''} ${this.last_name || ''} - ${this.email}`
      : this.email;
    return this.isClient() && this.client_name ? `${this.client_name} - ${label}` : label;
  }

  initializeDefaults(model) {
    model.show_services_overview = OverviewDisplay.DEFAULT;
    model.show_categories_overview = OverviewDisplay.DEFAULT;
  }

  getDisplayName() {
    let displayName = '';
    if (this.user_type === UserType.CLIENT) {
      displayName = this.company_name;
    } else {
      displayName = this.first_name ? `${this.first_name} ${this.last_name}` : this.email;
    }
    return displayName;
  }

  /**
   * @returns {boolean}
   */
  isUsingNewApp() {
    return this.can_access_nui && this.use_nui;
  }

  getPredefinedRoleBasedOnUserType() {
    switch (this.user_type) {
      case UserType.SUPERADMIN:
        return PredefinedRoleValues.SUPER_ADMIN_ROLE;
      case UserType.CLUSTERADMIN:
        return PredefinedRoleValues.CLUSTER_ADMIN_ROLE;
      case UserType.AGENT:
        return PredefinedRoleValues.AGENT_ROLE;
      case UserType.CLIENT:
        return PredefinedRoleValues.CLIENT_ROLE;
      default:
        return false;
    }
  }
}
