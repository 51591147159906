'use strict';
import angular from 'angular';
import _ from 'lodash';
import drawOptionPanelItemHtmlUrl from './drawoptionpanel.item.html';
import drawOptionPanelItemLabelHtmlUrl from './drawoptionpanel.item.label.html';
import drawOptionPanelItemLayerHtmlUrl from './drawoptionpanel.item.layer.html';
import {DrawOption} from "../drawoptionpanel.constants";
import {$MapBoxEvents, STATE, ZIP_CODE} from "../../../../../../../grok/src/modules/ta/widget/mapbox.constants";

angular.module('drawoptionpanel.directives')
    .component('drawOptionPanelItem', {
        bindings: {
            drawOptionValue: '=', // value property in the metadata
            drawOptionOverridden: '=', // overriden valuepropertyin the metadata
            drawOptionItem: '=', // key/value pair of the draw option
            drawOptionItemDependency: '<', // draw option item that is toggled by the value of another
            drawOptionItemToggleType: '<', // once dependency is triggered, how should item toggle
            isLayout: '<',
            isExport: '<',
            onUpdate: '<',
            drawOptions: '<',
            drawOptionItems: '<',
            reportId: '='
        },
        controller: DrawOptionItemController,
        controllerAs: 'vm',
        templateUrl: drawOptionPanelItemHtmlUrl
    })
    .component('drawOptionPanelItemLabel', {
        bindings: {
            drawOptionItem: '<',
            showOverride: '<',
            canDisableLookFeelToggle: '='
        },
        replace: true,
        templateUrl: drawOptionPanelItemLabelHtmlUrl,
        controllerAs: 'vm'
    })
    .component('drawOptionPanelItemLayer', {
        bindings: {
            drawOptions: '=',
            datasource: '=',
        },
        controller: DrawOptionItemLayerController,
        templateUrl: drawOptionPanelItemLayerHtmlUrl,
        controllerAs: 'vm'
    });

/**
 *  @ngInject
 */
function DrawOptionItemController(
    $scope,
    $timeout,
    DrawOption,
    DrawOptionPanelFactory,
    ChartPlotType
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.showOverride = showOverride;
    vm.toggleVisibility = toggleVisibility;
    vm.onBoolInputChange = onBoolInputChange;
    vm.onStringSelectChange = onStringSelectChange;
    vm.onSliderUp = onSliderUp;
    vm.shouldIndent = shouldIndent;
    vm.canSelectFontColor = canSelectFontColor;
    vm.canDisableFontColor = canDisableFontColor;
    vm.canDisableLookFeelToggle = canDisableLookFeelToggle;
    vm.canShowDrawOption = canShowDrawOption;
    vm.canShowPlotTypeTooltip = canShowPlotTypeTooltip;
    vm.getPlotTypeToolTipMessage = getPlotTypeToolTipMessage;

    function $onInit() {
        // Set defaults
        _setOverridenValue();
        _updateOverriddenStatus();
        _staggerSlider();
    }

    /**
     * Handle toggle input
     */
    function onBoolInputChange() {
        _updateOverriddenStatus();
        $timeout(function () {
            DrawOptionPanelFactory.updateDrawOptions(vm.drawOptionItem);
        }, 0);
    }

    /**
     * Handle to show the font color
     * @returns {false|boolean|boolean}
     */
    function canSelectFontColor() {
        return (
          DrawOptionPanelFactory.getSelectedWidgetType() &&
          vm.drawOptions?.grid_alternating_row_color &&
          vm.drawOptions?.plot_type === ChartPlotType.DEFAULT
        );
    }

    /**
     * handle to show the font the color when alternate row color is disabled for data grid widget
     * @returns {{"pointer-events": string}|{}}
     */
    function canDisableFontColor(){
        return canSelectFontColor() ? { "pointer-events": "none" } : {};
    }



    /**
     * Handle string select
     */
    function onStringSelectChange() {
        $timeout(function () {
            DrawOptionPanelFactory.updateDrawOptions(vm.drawOptionItem);
        }, 0);
    }

    /**
     * Handle slider
     */
    function onSliderUp() {
        vm.drawOptionValue = vm.drawOptionItem.slider.value;
        if (vm.drawOptionItem.key == DrawOption.CIRCLE_SIZE) {
            let fontSizeDrawOption = vm.drawOptionItems.find(option => option.key === DrawOption.WRAPPED_FONT_SIZE);
            fontSizeDrawOption.slider.options.ceil = Math.round(vm.drawOptionValue * 0.4);
            fontSizeDrawOption.slider.options.floor = Math.round(vm.drawOptionValue * 0.1);
            fontSizeDrawOption.slider.value = Math.round(vm.drawOptionValue * 0.3);
            vm.drawOptions[DrawOption.WRAPPED_FONT_SIZE] = Math.round(vm.drawOptionValue * 0.3);
        }
        DrawOptionPanelFactory.updateDrawOptions(vm.drawOptionItem);
    }

    /**
     * Condition for css class `indent`
     * @returns {boolean}
     */
    function shouldIndent() {
        return vm.drawOptionItemDependency !== undefined && vm.drawOptionItemToggleType === DrawOption.TOGGLE_TYPE_SHOW;
    }

    /**
     * @returns {boolean}
     */
    function canShowDrawOption() {
        const {key} = vm.drawOptionItem;
        if (key !== DrawOption.OPTION_GEO_LIMIT) {
            return true;
        }
        const {layers} = vm.drawOptions;
        return !!(layers && (layers.includes(DrawOption.ZIPCODE) || layers.includes(DrawOption.COUNTY) || layers.includes(DrawOption.CITY)));
    }

    /**
     * @returns {boolean}
     */
    function canShowPlotTypeTooltip() {
        const {key} = vm.drawOptionItem;
        return key === DrawOption.PLOT_TYPE && !_.isUndefined(vm.drawOptions.plot_type) && [ChartPlotType.GROUPED_COLUMN, ChartPlotType.EMBEDDED_SPARKLINES, ChartPlotType.CONDITIONAL_MAP].includes(vm.drawOptions.plot_type);
    }

    function getPlotTypeToolTipMessage() {
        let toolTipMessage = "Go to ‘Configure Selected Metrics’ in Data tab to select the fields";

        if (!_.isUndefined(vm.drawOptions.plot_type)) {
            switch (vm.drawOptions.plot_type) {
                case ChartPlotType.GROUPED_COLUMN:
                    toolTipMessage += " to form Field Groups";
                    break;
                case ChartPlotType.EMBEDDED_SPARKLINES:
                    toolTipMessage += " to embed sparklines";
                    break;
                case ChartPlotType.CONDITIONAL_MAP:
                    toolTipMessage += " for Conditional Map";
                    break;
                default:
                    break;
            }
        }

        return toolTipMessage;
    }

    /**
     * Show override flag or not
     * @returns {boolean}
     */
    function showOverride() {
        return _canBeOverriden() && !_.isUndefined(vm.drawOptionOverridden) && vm.drawOptionOverridden;
    }

    /**
     * Toggle dependent item's visiblity
     * @returns {boolean}
     */
    function toggleVisibility(toggleType) {
        if (Array.isArray(toggleType)) {
            for (let i = 0; i < toggleType.length; i++) {
                if(toggleType[i][0] === '!'){
                    if (toggleType[i].substring(1) === vm.drawOptions[vm.drawOptionItem.dependency[i]]) {
                        return false;
                    }
                } else {
                    const toggleValues = typeof toggleType[i] == 'string' && toggleType[i].includes('|')
                        ? toggleType[i].split('|') : [toggleType[i]];
                    let doesMatch = false;
                    for (let j = 0; j < toggleValues.length; j++) {
                        if (toggleValues[j] === vm.drawOptions[vm.drawOptionItem.dependency[i]]) {
                            doesMatch = true;
                        }
                    }
                    if (!doesMatch) {
                        return false;
                    }
                }
            }
            return true;
        }
        if (vm.drawOptionItem.key === DrawOption.HAS_TOOLTIP && vm.drawOptions?.plot_type === ChartPlotType.MAPBOX) {
            return false;
        }
        if (toggleType == vm.drawOptionItemDependency) {
            return true;
        }
        if (toggleType) {
            var canShow = vm.drawOptionItemDependency === true;
            if (toggleType === DrawOption.TOGGLE_TYPE_SHOW) {
                return canShow;
            }
            else if (toggleType === DrawOption.TOGGLE_TYPE_HIDE) {
                return !canShow;
            }
        }
        else {
            return true;
        }
    }

    /**
     * @returns {boolean}
     */
    function _canBeOverriden() {
        return vm.isExport || !vm.isLayout;
    }

    /**
     * If draw option value is not overriden, it sets it to the layout draw option value
     * @private
     */
    function _setOverridenValue() {
        if (!_.isUndefined(vm.drawOptionOverridden) && _canBeOverriden() && !vm.drawOptionOverridden) {
            var key = vm.drawOptionItem.key;
            var layoutDrawOptions = DrawOptionPanelFactory.getLayoutDrawOptions();
            if (key in layoutDrawOptions) {
                vm.drawOptionValue = layoutDrawOptions[key].value;
            }
        }
    }

    /**
     * Updates the draw option overridden if different from layout value
     * @private
     */
    function _updateOverriddenStatus() {
        var key = vm.drawOptionItem.key;
        // If draw option can be overridden
        if (!_.isUndefined(vm.drawOptionOverridden)) {
            // We also need to make sure if layout options have changed and are the same
            // as the widget option then we remove the overridden flag
            var layoutDrawOptions = DrawOptionPanelFactory.getLayoutDrawOptions();
            if (key in layoutDrawOptions) {
                vm.drawOptionOverridden = layoutDrawOptions[key].value !== vm.drawOptionValue;
            }
        }
    }

    /**
     * Wait before showing slider so that value is properly set
     * @private
     */
    function _staggerSlider() {
        if (vm.drawOptionItem.slider) {
            $timeout(function() {
                // $timeout may finish after digest cycles are done running,
                // and ready may never be updated on the directive
                $scope.$evalAsync(function () {
                    if (vm.drawOptionItem.slider) {
                        vm.drawOptionItem.slider.ready = true;
                        vm.drawOptionItem.slider.options.onEnd = onSliderUp;
                        vm.drawOptionItem.slider.options.onChange = onSliderUp;
                    }
                });
            }, 100, false);
        }
    }

    function canDisableLookFeelToggle() {
        return vm.reportId && [DrawOption.SHOW_BACKGROUND, DrawOption.SHOW_BORDERS, DrawOption.SHOW_TITLE, DrawOption.SHOW_HEADER, DrawOption.SHOW_DATA_SOURCE, DrawOption.SHOW_SHADOW].includes(vm.drawOptionItem.key);
    }
}

function DrawOptionItemLayerController($scope, $timeout, WidgetFactory, DrawOptionPanelFactory, WidgetBuilderService, PubSub) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.onLayerInputClick = onLayerInputClick;
    vm.isLayerSupported = isLayerSupported;
    vm.onMapLayerTypeChange = onMapLayerTypeChange;

    $scope.$watch('vm.datasource.id', function(nV, oV) {
        if (nV !== oV) {
            _initMap();
        }
    });

    function $onInit() {
        _initMap();
    }

    function _initMap() {
        if (!vm.drawOptions.layers) {
            vm.drawOptions.layers = [];
        }
        if (parseInt(vm.datasource.id) > 1000000 && !vm.datasource.geo_columns?.pin) {
            vm.mapLayerTypes = [{key: DrawOption.BOUNDARY, value: 'Boundary'}];
            vm.mapLayerType = DrawOption.BOUNDARY;
            onMapLayerTypeChange(false);
        } else {
            vm.mapLayerTypes = [{key: DrawOption.BOUNDARY, value: 'Boundary'}, {key: DrawOption.CLUSTER, value: 'Cluster'}];
        }

        _getMapBoxLayers();
        _setMapLayerType();
    }

    function _setMapLayerType() {
        if(vm.drawOptions.layers.includes(DrawOption.LATLONG)){
            vm.mapLayerType = DrawOption.CLUSTER;
            return;
        }
        vm.mapLayerType = DrawOption.BOUNDARY;
    }

    function _getMapBoxLayers() {
        WidgetFactory.getMapBoxLayers().then(function (data) {
            vm.mapBoxLayers = data.plain();
            _setDefaultLayer();
        });
    }

    function isLayerSupported(layer) {
        const { layers } = vm.datasource.mapbox_config;
        if (!layers || _.isEmpty(layers)) return false;
        return layers[vm.datasource.data_view] && Object.keys(layers[vm.datasource.data_view]).includes(layer.key);
    }

    function onLayerInputClick(key) {
        const layers = [];
        if(vm.drawOptions.layers.includes(key)){
           return;
        }
        if (!vm.drawOptions.layers.includes(key)) {
            layers.push(key);
        }
        vm.drawOptions.layers = layers;
        $timeout(function () {
            PubSub.emit($MapBoxEvents.LAYER_UPDATE, key);
            DrawOptionPanelFactory.updateDrawOptions({key: DrawOption.LAYERS});
        }, 0);
    }

    function onMapLayerTypeChange(resetLayer = true) {
        if (resetLayer) {
            vm.drawOptions.layers= [];
        }
        if (vm.mapLayerType === DrawOption.CLUSTER) {
            onLayerInputClick(DrawOption.LATLONG);
        } else {
            _setDefaultLayer();
        }
    }

    function _setDefaultLayer() {
        let layerSelected = false;
        if(_.isEmpty(vm.drawOptions.layers)) {
            const layers = [STATE, ZIP_CODE];
            for(let i=0; i < layers.length; i++) {
                if(isLayerSupported({key: layers[i]})) {
                    onLayerInputClick(layers[i]);
                    layerSelected = true;
                    break;
                }
            }
            for(let i=0; !layerSelected && vm.mapBoxLayers && i < vm.mapBoxLayers.length; i++) {
                if(isLayerSupported(vm.mapBoxLayers[i])) {
                    onLayerInputClick(vm.mapBoxLayers[i].key);
                    break;
                }
            }
        }
    }
}