'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import initAngularTemplatedDirectives from '../../../../shared/scripts/app.services';


angular.module('dashboardfilterpanel.services', [])

    .factory('DashboardFilterPanelFactory', DashboardFilterPanelFactory);

/**
 * @ngInject
 */
function DashboardFilterPanelFactory(
    $q,
    $timeout,
    $templateCache,
    DashboardFilterPanelResource,
    FilterParam,
    ColumnFormat,
    AppFactory,
    AppModelFactory,
    DataSourceFactory,
    DesignFactory,
    LayoutFactory,
    ClientFactory,
    ClientGroupFactory,
    ClusterService,
    SmartCampaignConstants,
    DataSourceType,
    LoadingState,
    SlidePanelFactory,
    PubSub,
    gettextCatalog,
    SmartCampaignResource,
    PageEvents,
    DashboardFilterEvents,
    DashboardContextService,
    AppModule
) {

    /**
     * Property to specify filter is widget exposed
     * @type {string}
     */
    var widgetSourcedKey = 'widget_sourced';

    /**
     * Property to specify widget data warehouse filters
     * @type {string}
     */
    var widgetDataWarehouseKey = 'widget_data_warehouse';

    /**
     * Keeps track of how many predefined dash filters are still waiting for their values
     * @type {number}
     */
    var dashFilterValuesToLoad = 0;

    /**
     * Keeps track of how many widget exposed filters are still waiting for their values
     * @type {number}
     */
    var widgetFilterValuesToLoad = 0;

    /**
     * Exposed view model object for controller
     * @type {{}}
     */
    var shared = {
        panelId: 'dashboard-filter-panel',
        isShowing: false,
        isLoading: true,
        isLayout: false,
        pageRebuildRequired: false,
        canShowWidgetFilters: false,
        currentTab: 0,
        filters: {},
        dashFilterOptionList: [],
        widgetFilterOptionList: [],
        widgetDataWarehouseFilterOptionList: [],
        hasWidgetFilters: hasWidgetFilters,
        hasWidgetDataWarehouseFilters: hasWidgetDataWarehouseFilters,
        hasFilterValues: hasFilterValues,
        closePanel: closePanel,
        toggleTab: toggleTab,
        applyFilters: applyFilters,
        resetFilterValues: resetFilterValues,
        getFilterValues: getFilterValues
    };

    /**
     * Reset filter option list when either client, client group or business unit is created
     */
    PubSub.on('customBranding:new_entity', function () {
        resetFilterOptionList();
    });

    return {
        init: init,
        setFilters: setFilters,
        resetWidgetFilterOptionList: resetWidgetFilterOptionList,
        resetFilterOptionList: resetFilterOptionList,
        getAffectedWidgetIds: getAffectedWidgetIds,
        shared: shared
    };

    /**
     * Initializes dashboard filter shared and set defaut state
     */
    function init() {
        // Stop execution if already active
        if (shared.isShowing) {
            return;
        }
        resetState();
        SlidePanelFactory.show(shared);

        var currentPage = DesignFactory.getCurrentPage();
        let currentUser = AppFactory.getUser();
        shared.canShowWidgetFilters = !currentPage.is_predefined;
        shared.canShowAllDashFilters =
          (!currentUser.isClient() ||
            (currentUser.isClient() &&
              currentUser.isModuleAvailable(AppModule.SMART_CAMPAIGNS) &&
              currentUser.isSmartCampaignFilteringEnabled)) &&
          (_.isNull(currentPage.metadata.selected_entity) ||
            currentPage.metadata.selected_entity?.type ===
              DataSourceType.CLIENT_GROUP ||
            (currentUser.isClient() &&
              currentPage.metadata.selected_entity?.type ===
                DataSourceType.CLIENT));

        // Toggle the widget filter tab if user can't see the All Dashboards tab
        if (!shared.canShowAllDashFilters) {
            shared.currentTab = 1;
        }
        _initDashFilters();
    }

    /**
     * Original dashboard filter panel state
     */
    function resetState() {
        shared.currentTab = 0;
        $timeout(function() {toggleTab(shared.currentTab);}, 0, false);
        shared.isLoading = false;
        shared.pageRebuildRequired = false;
        shared.canShowWidgetFilters = false;
        shared.canShowAllDashFilters = false;
        dashFilterValuesToLoad = 0;
        widgetFilterValuesToLoad = 0;

    }

    /**
     * @param filters
     */
    function setFilters(filters) {
        shared.filters = filters || {};
    }

    function updateDashboardFilterInNewUIReload() {
        import(
            '../../../../../../../grok/src/modules/core/app/helpers/EventBus'
            ).then(module => {
            const eventBus = module.default;
            eventBus.signal('DashboardFilterNewUIReload');
        });
    }

    /**
     * Apply currently selected filters
     * @param forceRebuild
     */
    function applyFilters(forceRebuild) {
        setSelectedFilters();
        PubSub.emit(PageEvents.PERFORMANCE_TRACKING, {
            event: DashboardFilterEvents.FILTERS_UPDATED
        })
        var needsRebuild = forceRebuild || isPageRebuildRequired();
        if (needsRebuild) {
            DesignFactory.$triggerPageLoader();
        }
        var model = angular.copy(shared.filters);
        DashboardFilterPanelResource.saveFilters(model).then(function(json) {
            if (window.isNUI) {
                updateDashboardFilterInNewUIReload()
            }
            if (needsRebuild) {
                DesignFactory.$rebuildPage();
            }
            else {
                LayoutFactory.$rebuildAllWidgets();
            }

            if (json && json.plain().status) {
              $.core.main.notify(json.plain().text, null, {
                autoHideDelay: 10000,
              });
            } else {
              _updateLegacyViewAs(json ? json.plain() : null);
            }

            /**
             * NOTE: Filters must be sent to the backend in an array, however in
             * order for select2 to display the correct selected value, filter
             * values must be in an object and not an array, since filters are single selects
             */
            _.each(shared.dashFilterOptionList, function(filter) {
                filter.currentValues = filter.currentValues.length
                    ? _.first(angular.copy(filter.currentValues))
                    : !_.isEmpty(filter.currentValues)
                        ? angular.copy(filter.currentValues)
                        : {};
            });
        });
    }

    /**
     * Page needs rebuilding if there at least one value for dashboard filters
     * @returns {boolean}
     */
    function isPageRebuildRequired() {
        var needsRebuild = false;
        _.each(shared.filters, function(filter, key) {
            if ((key === widgetDataWarehouseKey) || (key !== widgetSourcedKey && filter.values.length)) {
                needsRebuild = true;
                return false;
            }
        });
        return needsRebuild;
    }

    /**
     * Copy back options set into the filters to be saved
     */
    function setSelectedFilters() {
        _.each(shared.dashFilterOptionList, function(option) {
            var value = option.currentValues;
            var filter = shared.filters[option.key];
            if (filter) {
                filter.type = option.type;
                if (Array.isArray(value)) {
                    filter.values = value.length && !_.isNull(value[0]) ? value : [];
                } else if (!_.isEmpty(value)) {
                    filter.values = [value];
                    if (shared.previousClientGroupFilter) {
                        filter.globalFilter = shared.previousClientGroupFilter;
                    }
                } else {
                    filter.values = [];
                }
            }
        });

        _.each(shared.widgetFilterOptionList, function(option) {
            var value = option.currentValues;
            if (option.is_live_integration && option.is_enum && !Array.isArray(value)) {
                value = [value];
            }
            var pageId = DesignFactory.getCurrentPage().id;
            var filter = shared.filters[widgetSourcedKey][pageId][option.key];
            if (filter) {
                filter.type = option.type;
                filter.values = value.length && !_.isNull(value[0]) ? value : [];
            }
        });

        _.each(shared.widgetDataWarehouseFilterOptionList, function(option) {
            var value = option.currentValues;
            if (!Array.isArray(value)) {
                value = [value];
            }
            var pageId = DesignFactory.getCurrentPage().id;
            var filter = shared.filters[widgetDataWarehouseKey][pageId][option.key];
            if (filter) {
                filter.type = option.type;
                filter.values = value?.length && !_.isNull(value[0]) ? value : [];
            }
        });
    }

    /**
     * LEGACY SUPPORT
     *
     * @param serviceIds
     * @private
     */
    function _updateLegacyViewAs(serviceIds) {
        var $main = $.core.main;
        $templateCache.removeAll();
        initAngularTemplatedDirectives($templateCache);

        if (_.isNull(serviceIds)) {
            // view as nav bar must be hidden when enabled from service details and disabling in NWA
            $('#specific-client-view').hide();
            $('#dash-view-as-client-filter').find('span.icon').removeClass('active');
            AppFactory.setCurrentClient(null, null);
            AppFactory.refreshServices();
            $main && $main.reEnableServiceNavItems();
        }
        else {
            var serviceObjects = _.map(serviceIds, function (id) {
                return {service_id: id};
            });
            $main && $main.disableServiceNavItems(serviceObjects);
        }
    }

    /**
     * Reset currently selected filters
     */
    function resetFilterValues(forceReload, resetAll, isClearAction) {
        var pageId = DesignFactory.getCurrentPage().id;
        forceReload = _.isNil(forceReload) ? true : forceReload;
        resetAll = _.isNil(resetAll) ? false : resetAll;

        // DO NOT REMOVE - Need to determine rebuild before resetting filter values
        var needsRebuild = isPageRebuildRequired() || DesignFactory.widgetsMayBeHidden();
        // this use case is for when filters are reset and show_widgets_with_no_data is disabled

        var shouldResetAllDashFilters = resetAll || shared.currentTab === 0;
        var shouldResetWidgetFilters = resetAll || shared.currentTab === 1;

        // Tab is all dash filters
        if (shouldResetAllDashFilters) {
            _.each(shared.dashFilterOptionList, function (option) {
                option.currentValues = [];
            });
        }

        // Tab is widget dash filters
        if (shouldResetWidgetFilters) {
            if (!isClearAction) {
                _.each(shared.widgetFilterOptionList, function (option) {
                    option.currentValues = [];
                });
            }
            _.each(shared.widgetDataWarehouseFilterOptionList, function (option) {
                option.currentValues = [];
            });
        }

        _.each(shared.filters, function(filter, key) {
            if (key === widgetDataWarehouseKey
                && !_.isUndefined(filter[pageId])
                && shouldResetWidgetFilters
            ) {
                _.each(filter[pageId], function(item) {
                    item.values = [];
                });
            } else if (key === widgetSourcedKey
                && !_.isUndefined(filter[pageId])
                && shouldResetWidgetFilters
                && !isClearAction
            ) {
                _.each(filter[pageId], function(item) {
                    item.values = [];
                });
            }
            else if (shouldResetAllDashFilters) {
                filter.values = [];
            }
        });

        if (forceReload) {
            applyFilters(needsRebuild);
        } else if (window.isNUI) {
            updateDashboardFilterInNewUIReload()
        }
    }

    function getFilterValues() {
        return DashboardFilterPanelResource.getList();
    }

    /**
     * Reset a select2 and it's filter values that currently has a value
     */
    function resetFilter(key, $el) {
        var option = _.find(shared.dashFilterOptionList, {key: key});
        option.currentValues = [];
        $el.select2('data', null);
    }

    /**
     * Used to for force reloading the filter option list
     */
    function resetFilterOptionList() {
        shared.dashFilterOptionList = [];
    }

    /**
     * Used to for force reloading the filter option list
     */
    function resetWidgetFilterOptionList() {
        shared.widgetFilterOptionList = [];
        shared.widgetDataWarehouseFilterOptionList = [];
    }

    /**
     * Close and restore panel
     */
    function closePanel() {
        // need to remove the glow class from highlighted widgets
        DesignFactory.$getAllWidgetElements().removeClass('glow').removeClass('faded');
        SlidePanelFactory.hide(shared);
    }

    /**
     * Proxy for inherited toggle tab fn
     * @param index
     * @returns {*}
     */
    function toggleTab(index) {
        shared.currentTab = index;
        if (index === 1 && _.isEmpty(shared.widgetFilterOptionList)) {
            initWidgetFilters();
        }
        if (index === 1 && _.isEmpty(shared.widgetDataWarehouseFilterOptionList)) {
            initWidgetDataWarehouseFilters();
        }
        return SlidePanelFactory.toggleTab(index);
    }


    /**
     * Determines if we show the empty widget filter panel
     */
    function hasWidgetFilters() {
        return !_.isEmpty(shared.widgetFilterOptionList) && !shared.isLoading;
    }

    /**
     * Determines if we show the empty widget filter panel
     */
    function hasWidgetDataWarehouseFilters() {
        return !_.isEmpty(shared.widgetDataWarehouseFilterOptionList) && !shared.isLoading;
    }

    /**
     * Determines if there are any filter values set in any of the select2 dropdowns
     */
    function hasFilterValues() {
        var hasValues = false;
        var optionList = null;

        if (shared.currentTab === 0) {
            // All Dashboards Tab
            optionList = shared.dashFilterOptionList;
        } else if (shared.currentTab === 1) {
            // Current Dashboard Tab
            optionList = shared.widgetFilterOptionList;
        }

        _.each(optionList, function(option) {
            if (!_.isEmpty(option.currentValues)) {
                hasValues = true;
                return false;
            }
        });
        return hasValues;
    }


    /**
     * Check if done loading filter values
     * @private
     */
    function _checkIfDone() {
        if (widgetFilterValuesToLoad === 0) {
            shared.isLoading = LoadingState.DONE;
        }
    }

    /**
     * Get and set dashboard filters
     *
     * @private
     */
    function _initDashFilters() {
        let currentPage = DesignFactory.getCurrentPage();
        shared.isLoading = LoadingState.FETCHING;

        if (shouldFetchFiltersData(currentPage, shared)) {
            shared.dashFilterOptionList = [];
        }

        // Avoid refetching data
        if (!_.isEmpty(shared.dashFilterOptionList) || !shared.canShowAllDashFilters) {
            shared.isLoading = LoadingState.DONE;
            return;
        }
        DashboardFilterPanelResource.getList().then(function (json) {
            var filterOptions = json.plain();
            dashFilterValuesToLoad = filterOptions.length; // # of possible filters

            if (_.isEmpty(filterOptions)) {
                shared.canShowAllDashFilters = false;
                shared.isLoading = LoadingState.DONE;
                toggleTab(1);
                return;
            }

            var brandMappings = AppFactory.getBrandMappings();

            shared.dashFilterOptionList = _.map(filterOptions, function (option) {
                shared.previousClientGroupFilter = {};

                switch (option.key) {
                    case DataSourceType.CLIENT:
                        option.label = brandMappings.client;
                        break;
                    case DataSourceType.CLIENT_GROUP:
                        option.label = brandMappings.client_group;
                        break;
                }

                // Select2 inputs
                if (option.format === ColumnFormat.FORMAT_ID || option.format === ColumnFormat.FORMAT_STRING || option.format === ColumnFormat.FORMAT_URL) {
                    // Create a list of selectable filters
                    addFilter(option);

                    option.onChange = function(key, $el) {
                        // Only reset values if change did not come from the source select2 emitting the vent
                        if ((option.key !== key) && key !== widgetDataWarehouseKey) {
                            resetFilter(option.key, $el);
                        }
                    };

                    option.onReset = function(e, $el) {
                        if (shared.currentTab === 0) {
                            $el.select2('data', null);
                        }
                    };

                    // Set current values based on values already in session
                    option.currentValues = shared.filters[option.key].values.length
                        ? _.first(angular.copy(shared.filters[option.key].values))
                        : {};

                    option.queryCallback = function (values, selectedValues) {
                        if (_.isEmpty(values)) {
                            selectedValues.results = [];
                        }
                    };

                    switch (option.key) {
                        case (DataSourceType.CLIENT):
                            option.values = [];
                            option.getDataCall = function(query) {
                                var params = {};
                                if (query) {
                                    params.q = query + '|company_name';
                                }
                                params['reporting_status[]'] = ['active', 'pending_mapping'];
                                return ClientFactory.getFieldValues(option.field, params);
                            };

                            break;

                        case (DataSourceType.CLIENT_GROUP):
                            option.values = [];
                            option.getDataCall = function(query) {
                                var params = {};
                                if (query) {
                                    params.q = query + '|name';
                                }
                                return ClientGroupFactory.getFieldValues(option.field, params);
                            };

                            break;

                        case (DataSourceType.CLUSTER):
                            option.values = [];
                            option.getDataCall = function(query) {
                                var params = {status: 'active'};
                                if (query) {
                                    params.q = query + '|name';
                                }

                                return ClusterService.getFieldValues(option.field, params);
                            };
                            break;

                        case (DataSourceType.SMART_CAMPAIGN):
                            option.values = [];
                            option.getDataCall = function(query) {
                                let params = { [SmartCampaignConstants.CAMPAIGN_TYPE]: SmartCampaignConstants.TYPE.FILTERING};
                                if (query) {
                                    params.q = query + '|name';
                                }

                                return SmartCampaignResource.getFieldValues(option.field, params);
                            }
                    }
                    setSelect2Options(option);
                }

                return option;
            });

            if (currentPage.metadata.selected_entity?.type === DataSourceType.CLIENT_GROUP) {
                /**
                 * If dashboard is pre-filtered by client group,
                 * then showing only client filters
                 */
                shared.dashFilterOptionList = _.filter(shared.dashFilterOptionList, function (filterOption) {
                    return filterOption.key === DataSourceType.CLIENT;
                });
                shared.previousClientGroupFilter = {id: currentPage.metadata.selected_entity.id, name: currentPage.metadata.selected_entity.name};
            }
        });
    }

    /**
     * Get and set widget exposed filters
     */
    function initWidgetFilters() {
        shared.isLoading = LoadingState.FETCHING;

        // Avoid refetching data
        if (!_.isEmpty(shared.widgetFilterOptionList)) {
            shared.isLoading = LoadingState.DONE;
            return;
        }

        var pageId = DesignFactory.getCurrentPage().id;
        DashboardFilterPanelResource.getWidgetFilters(pageId).then(function(json) {
            var filterOptions = json.plain();
            widgetFilterValuesToLoad = filterOptions.length; // # of possible filters

            // No widget filters
            if (_.isEmpty(filterOptions)) {
                shared.isLoading = LoadingState.DONE;
                return;
            }

            shared.widgetFilterOptionList = _.map(filterOptions, function (option) {
                var _initialValues = [];
                option.affectedWidgetIds = [];
                option.isShowingAffectedWidgets = false;
                option.acceptWildcard = true;

                /**
                 * Reset initial values on clicks filters
                 */
                option.onChange = function (e, $el) {
                    setWidgetFilterOptionsValues(option, _initialValues);
                };

                option.onReset = function(e, $el) {
                    if (shared.currentTab === 1) {
                        $el.select2('data', null);
                    }
                };

                // Select2 inputs
                if (option.format === ColumnFormat.FORMAT_ID || option.format === ColumnFormat.FORMAT_STRING || option.format === ColumnFormat.FORMAT_URL) {

                    option.filter_set_id_param = option.source_filter_set_id + '|' + option.field;
                    option.tooMuchDataCallback = function(query) {
                        // In order to enable tags, the query must be the first item in results
                        var valuesWithTag = query.term.length > 1 ?
                            [{id: query.term, text: query.term}].concat(option.selectOptions.tags) :
                            option.selectOptions.tags;

                        var selectedValues = {results: valuesWithTag};
                        // Query length must be checked here, or else the selectable values will never be set
                        if (query.term.length >= 2) {
                            var params = {
                                q: query.term + '|' + option.field,
                            };
                            if (option.limit_available_values) {
                                params.filter_set_id = option.filter_set_id_param;

                                // We need to pass a widget_page_id to the backend to allow for pre-filtered dashboards
                                var page = DesignFactory.getCurrentPage();
                                if (!_.isNil(page.metadata.selected_entity)) {
                                    params.widget_page_id = page.id;
                                }
                            }

                            DataSourceFactory.getFieldValues(option.data_source, option.field, params).then(function(item) {
                                if (item.values.length) {
                                    selectedValues.results = [{id: query.term, text: query.term}];
                                    _.each(item.values, function (item) {
                                        selectedValues.results.push({id: item.key, text: item.value});
                                    });
                                    setWidgetFilterOptionsValues(option, item.values);
                                }
                                selectedValues.results = _.filter(selectedValues.results, value => !_.isUndefined(value));
                                query.callback(selectedValues);
                            }, function (error) {
                                console.error("Error getting data", error);
                            });
                        }
                        else {
                            query.callback(selectedValues);
                        }
                    };

                    // Set select2 default options
                    setSelect2Options(option);

                    // Create a list of selectable filters
                    addFilter(option, pageId);

                    // Set current values based on values already in session
                    option.currentValues = angular.copy(shared.filters[widgetSourcedKey][pageId][option.key].values);

                    var queryParams = {};
                    if (option.limit_available_values && !_.isNull(option.source_filter_set_id)) {
                        queryParams.filter_set_id = option.filter_set_id_param;
                    }

                    // We need to pass a widget_page_id to the backend to allow for pre-filtered dashboards
                    var page = DesignFactory.getCurrentPage();
                    if (option.limit_available_values && !_.isNil(page.metadata.selected_entity)) {
                        queryParams.widget_page_id = page.id;
                    }
                    if (option.is_live_integration) {
                        queryParams.has_live_integration = option.is_live_integration;
                    }
                    if (option.skip_hidden_filter) {
                        queryParams.skip_hidden_filter = true;
                    }
                    $q.resolve(DataSourceFactory.getFieldValues(option.data_source, option.field, queryParams)).then(function (json) {
                        widgetFilterValuesToLoad--;
                        if (json.values.length) {
                          const values = json.values.filter(function(item) {
                            return item.key && item.value;
                          });
                          option.values = values;
                        } else {
                            option.values = json.values;
                        }
                        _initialValues = angular.copy(option.values);
                        option.selectOptions.loaded = true;
                        option.selectOptions.hasMoreData = json.has_more_data;
                        option.selectOptions.relaxMatch = json.relax_match;
                        option.selectOptions.acceptWildcard = true;
                        if (queryParams.has_live_integration) {
                            if (json.is_enum) {
                                option.selectOptions.acceptWildcard = null;
                                option.selectOptions.multiple = false;
                                option.selectOptions.allowClear = false;
                                option.selectOptions.values = _initialValues;
                                option.is_enum = true;
                                delete option.selectOptions.tags;
                                option.ignoreExclude = true;
                                if (option.currentValues?.length) {
                                    option.currentValues = option.currentValues[0];
                                }
                            } else {
                                // If On Demand filters have no un-disabled values then matcher should return null
                                option.selectOptions.matcher = function () {
                                    return null;
                                };
                            }
                        }
                        _checkIfDone();
                    });
                }

                // Determines which widgets match the data source of the current filter
                option.affectedWidgetIds = getAffectedWidgetIds(option.data_source, option.is_live_integration);

                return option;
            });
        });
    }


    /**
     * Get and set widget exposed filters
     */
    function initWidgetDataWarehouseFilters() {
        shared.isLoading = LoadingState.FETCHING;

        // Avoid refetching data
        if (!_.isEmpty(shared.widgetDataWarehouseFilterOptionList)) {
            shared.isLoading = LoadingState.DONE;
            return;
        }

        var pageId = DesignFactory.getCurrentPage().id;
        DashboardFilterPanelResource.getWidgetFilters(pageId, { type: widgetDataWarehouseKey }).then(function(json) {
            var filterOptions = json.plain();
            widgetFilterValuesToLoad = filterOptions.length; // # of possible filters

            // No widget filters
            if (_.isEmpty(filterOptions)) {
                shared.isLoading = LoadingState.DONE;
                return;
            }

            shared.widgetDataWarehouseFilterOptionList = _.map(filterOptions, function (option) {
                var _initialValues = [];

                /**
                 * Reset initial values on clicks filters
                 */
                option.onChange = function (e, $el) {
                    setWidgetDataWarehouseFilterOptionsValues(option, _initialValues);
                };

                option.onReset = function(e, $el) {
                    if (shared.currentTab === 1) {
                        $el.select2('data', null);
                    }
                };

                // Set select2 default options
                setSelect2Options(option);

                // Create a list of selectable filters
                addFilter(option, pageId);

                // Set current values based on values already in session
                option.currentValues = angular.copy(shared.filters[widgetDataWarehouseKey][pageId][option.key].values);
                option.isShowingAffectedWidgets = false;
                _initialValues = angular.copy(option.values);
                option.selectOptions.loaded = true;
                option.selectOptions.multiple = false;
                option.selectOptions.allowClear = true;
                option.selectOptions.hasMoreData = false;
                option.selectOptions.relaxMatch = json.relax_match;
                option.selectOptions.acceptWildcard = false;
                if (option.currentValues?.length) {
                    option.currentValues = option.currentValues[0];
                }

                return option;
            });
        });
    }

    /**
     * Sets source of truth data object in order to digest all changes top-to-bottom
     * @param option
     * @param values
     */
    function setWidgetFilterOptionsValues(option, values) {
        _.each(shared.widgetFilterOptionList, function (filterOption) {
            if (filterOption.key === option.key) {
                filterOption.values = values;
            }
        });
    }

    /**
     * Sets source of truth data object in order to digest all changes top-to-bottom
     * @param option
     * @param values
     */
    function setWidgetDataWarehouseFilterOptionsValues(option, values) {
        _.each(shared.widgetDataWarehouseFilterOptionList, function (filterOption) {
            if (filterOption.key === option.key) {
                filterOption.values = values;
            }
        });
    }

    /**
     *
     * @param option
     */
    function setSelect2Options(option) {
        var defaultOptions = {
            key: option.key,
            width: '100%',
            listenToOtherSelect2: option.is_predefined,
            placeholder: gettextCatalog.getString('Select a {{option}}...', {option: option.label.toLowerCase()}),
            tooMuchDataCallback: option.tooMuchDataCallback,
            label: option.label,
            loaded: false,
            formatData: option.formatData || function(json) {
                dashFilterValuesToLoad--;
                option.values = json.values;
                this.loaded = true;
                _checkIfDone();
            },
            getDataCall: option.getDataCall,
            queryCallback: option.queryCallback
        };

        if (!option.is_predefined) {
            defaultOptions.multiple = true;
            if (!option.limit_available_values) {
                defaultOptions.tags = true;
            }
        }

        option.selectOptions = AppModelFactory.getSelectOptions(defaultOptions);
    }

    /**
     *
     * @param option
     * @param pageId - Page widget filter is associated to
     */
    function addFilter(option, pageId) {
        if (!option.data_source ) {
            option.data_source = {
                id_name: null,
                data_view_name: null
            };
        }
        let defaultValues = {
            field: option.field,
            format: option.format,
            label: option.label,
            association: FilterParam.ASSOCIATION_AND,
            id_name: option.data_source.id_name,
            data_view_name: option.data_source.data_view_name,
            type: FilterParam.FILTER_IN,
            values: [],
            globalFilter: [],
            is_live_integration: option.is_live_integration || false
        };
        if (!_.isUndefined(pageId)) {
            if (option.key === widgetDataWarehouseKey) {
                if (_.isUndefined(shared.filters[widgetDataWarehouseKey])) {
                    shared.filters[widgetDataWarehouseKey] = {};
                }
                if (_.isUndefined(shared.filters[widgetDataWarehouseKey][pageId])) {
                    shared.filters[widgetDataWarehouseKey][pageId] = {};
                }
                if (_.isUndefined(shared.filters[widgetDataWarehouseKey][pageId][option.key])) {
                    shared.filters[widgetDataWarehouseKey][pageId][option.key] = defaultValues;
                }
            } else {
                if (_.isUndefined(shared.filters[widgetSourcedKey])) {
                    shared.filters[widgetSourcedKey] = {};
                }

                if (_.isUndefined(shared.filters[widgetSourcedKey][pageId])) {
                    shared.filters[widgetSourcedKey][pageId] = {};
                }

                if (_.isUndefined(shared.filters[widgetSourcedKey][pageId][option.key])) {
                    shared.filters[widgetSourcedKey][pageId][option.key] = defaultValues;
                }
            }
        }
        else {
            if (_.isUndefined(shared.filters[option.key])) {
                shared.filters[option.key] = defaultValues;
            }
        }
    }



    /**
     * Determines which widgets match the data source of the current filter
     * @param filterDataSource
     * @param isLiveFilter
     * @returns {Array}
     */
    function getAffectedWidgetIds(filterDataSource, isLiveFilter = false) {
        var affectedWidgetIds = [];
        // Determine which widgets match the data source of the current filter
        _.each(DesignFactory.getAllWidgets(), function(widget) {
            if (DashboardContextService.isWidgetAffectedByFilter(widget, filterDataSource, isLiveFilter)) {
                affectedWidgetIds.push(widget.id);
            }
        });
        return affectedWidgetIds;
    }

    /**
     * Helper function returns if dashboard filters list should be fetched or not
     * @param currentPage
     * @param sharedData
     * @returns {boolean}
     */
    function shouldFetchFiltersData(currentPage, sharedData) {
        if ((_.isNull(currentPage.metadata.selected_entity) && !_.isEmpty(sharedData.previousClientGroupFilter)) ||
            currentPage.metadata.selected_entity?.type !== DataSourceType.CLIENT_GROUP ||
            sharedData?.previousClientGroupFilter?.id !== currentPage.metadata.selected_entity?.id) {
            return true;
        }

        return false;
    }
}
