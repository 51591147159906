import { ValueAxis } from '@amcharts/amcharts5/xy';
import { AxisFormatters, Constant } from '@/modules/core/charts/am5/charts.constants';
import { getChartConfig } from '@/modules/core/charts/am5/bar/config/chartconfig';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { useLabels } from '@/modules/core/charts/am5/base/composables/series/useLabels';
import { color } from '@amcharts/amcharts5';
import { useToolTips } from '@/modules/core/charts/am5/pie/composables/useTooltips';
import { getters } from '@/modules/core/app/helpers/store';
import { AppModule } from '@/modules/core/app/constants/app.constants';

export function useValueAxis(context) {
  const { root, config, isDarkTheme } = context();
  const { preFixCurrency } = useLabels(context);
  const { assignThemeColor } = useToolTips(context);

  function createValueAxis(props, renderer) {
    const fontColorPicker = assignThemeColor(isDarkTheme, config.value);
    const {
      isSparkLine,
      isFullStacked,
      isZeroScaled,
      isLogScaled,
      isNormalizedData,
      isRadialHistogram,
    } = config.value;
    const { axisOptions } = getChartConfig({
      isFullStacked,
      isZeroScaled,
      isLogScaled,
      isNormalizedData,
      isRadialHistogram,
    });
    const axis = ValueAxis.new(
      root.value,
      omitNils({
        max: props.max,
        extraMin: props.extraMin,
        extraMax: props.extraMax,
        strictMinMax: props.strictMinMax ?? false,
        ...axisOptions,
        extraTooltipPrecision: 1,
        renderer,
        ...getFormatter(props.dataItemFormat),
      })
    );

    if (isSparkLine) {
      renderer.grid.template.set(Constant.VISIBLE, false);
      renderer.labels.template.set(Constant.VISIBLE, false);
      axis.cursorTooltipEnabled = false;
    }
    // Give fontcolor for value axis labels
    axis.get(Constant.RENDERER).labels.template.set(Constant.FILL, color(fontColorPicker));
    return axis;
  }

  function getFormatter(selectedFormat) {
    const hasClientCurrencyModule = getters.session
      .getUserSettings()
      ?.isModuleAvailable(AppModule.CLIENT_CURRENCY);
    // if this is normalized then we need to use the selectedFormat
    let formatType = getDataFormat(selectedFormat);
    if (
      formatType === ColumnFormat.FORMAT_CURRENCY &&
      config.value.currencyDiscrepancy &&
      hasClientCurrencyModule
    ) {
      formatType = ColumnFormat.FORMAT_INTEGER;
    }
    const formatObject = AxisFormatters[formatType];
    const hasData = config.value?.data?.length > 0;

    if (formatType === ColumnFormat.FORMAT_CURRENCY) {
      if (
        (!config.value.currencyDiscrepancy && config.value.showCurrency) ||
        !hasClientCurrencyModule
      ) {
        // if the currency format is associated with any currency symbol before then remove it on the y axis
        if (
          formatObject.numberFormat !== AxisFormatters.FORMAT_CURRENCY &&
          formatObject.numberFormat.length > AxisFormatters.FORMAT_CURRENCY.length
        ) {
          formatObject.numberFormat = formatObject.numberFormat.slice(
            -AxisFormatters.FORMAT_CURRENCY.length
          );
        }
        const [item] = config.value.data;
        const row = item ?? {};
        formatObject.numberFormat = preFixCurrency(formatObject.numberFormat, row, hasData);
      }
    }

    return formatObject;
  }

  /**
   * All this so we don't display an axis with 2000% on
   * @returns {string}
   */
  function getDataFormat(defaultFormat) {
    if (config.value.isNormalizedData) {
      return defaultFormat;
    }

    const { series } = config.value;
    const formatTypes = [];
    if (!series) {
      return defaultFormat;
    }

    series.forEach((xySeries) => {
      formatTypes.push(xySeries.dataItemFormat);
    });

    // if everything matches then just return what they are
    const allTheSame = formatTypes.every((value) => value === formatTypes[0]);
    if (allTheSame) {
      return formatTypes[0];
    }

    // if they're different then by default return integer format.
    return ColumnFormat.FORMAT_INTEGER;
  }

  return {
    createValueAxis,
  };
}
