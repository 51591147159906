'use strict';
import angular from 'angular';
import { AppModule } from '@/modules/core/app/constants/app.constants';
import $ from 'jquery';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';
import EventBus from "../../../../../../../grok/src/modules/core/app/helpers/EventBus";
import {
    ChatGptSummariesRoute
} from "../../../../../../../grok/src/modules/ta/chatgptsummaries/chatgptsummaries.constants";
import { DownloadUtil } from '@/modules/core/app/utils/DownloadUtil';
var $fileDownload = $.fileDownload;

angular.module('widget.option.ctrls', [])

    .controller('WidgetOptionsController', WidgetOptionsController)

/**
 * @ngInject
 */
function WidgetOptionsController(
    $AlertEvents,
    $scope,
    $timeout,
    PubSub,
    LoadingState,
    AppFactory,
    DataSourceType,
    DesignFactory,
    LayoutFactory,
    ManageLayoutFactory,
    WidgetFactory,
    WidgetType,
    WidgetCreateFactory,
    WidgetDateRangeFactory,
    WidgetFilterFactory,
    WidgetTypeGrouping,
    AnnotationFactory,
    WidgetExportFactory,
    EditLayoutFactory,
    DataSourceFactory,
    SlidePanelFactory,
    ExportReportFactory,
    UIFactory,
    $WidgetLibraryEvents,
    WidgetUtilService,
    $WidgetBuilderEvents,
    LiveIntegrationFactory,
    DashboardContextService,
    WidgetHeaderModalFactory,
    ProductWidgetFactory,
    DateRangeFactory,
    MomentDateFormat,
    RelativeDateRange,
    DashboardFilterPanelFactory
) {
    $scope.LoadingState = LoadingState;

    $scope.canIncludeEditOption = canIncludeEditOption;
    $scope.editWidget = editWidget;
    $scope.swapWidget = swapWidget;
    $scope.toggleLayoutEditMode = toggleLayoutEditMode;
    $scope.toggleManageLayoutsPanel = toggleManageLayoutsPanel;
    $scope.filterWidgetData = filterWidgetData;
    $scope.overrideDateRange = overrideDateRange;
    $scope.canIncludeFilterOption = canIncludeFilterOption;
    $scope.canIncludeOverrideDateRangeOption = canIncludeOverrideDateRangeOption;
    $scope.alertWidgetData = alertWidgetData;
    $scope.canIncludeAlertOption = canIncludeAlertOption;
    $scope.initAnnotations = initAnnotations;
    $scope.canShowExportOption = canShowExportOption;
    $scope.canIncludeExportOption = canIncludeExportOption;
    $scope.exportDataToExcel = exportDataToExcel;
    $scope.exportDataToJson = exportDataToJson;
    $scope.canIncludeCloneOption = canIncludeCloneOption;
    $scope.confirmWidgetClone = confirmWidgetClone;
    $scope.setWidgetForPublishing = setWidgetForPublishing;
    $scope.canIncludeDeleteOption = canIncludeDeleteOption;
    $scope.confirmWidgetDelete = confirmWidgetDelete;
    $scope.canIncludePublishOptions = canIncludePublishOptions;
    $scope.canIncludeAnnotations = canIncludeAnnotations;
    $scope.canManageDashboards = canManageDashboards;
    $scope.isDemoModeEnabled = isDemoModeEnabled;
    $scope.canShowDataExporterOption = canShowDataExporterOption;
    $scope.exportDataToCsv = exportDataToCsv;
    $scope.exportDataToCsvWithElement = exportDataToCsvWithElement;
    $scope.editWidgetHeader = editWidgetHeader;
    $scope.canShowEditWidgetHeaderOption = canShowEditWidgetHeaderOption;
    $scope.exportToDataExporter = exportToDataExporter;
    $scope.canShowEditExportOptions = canShowEditExportOptions;
    $scope.canShowChatGptOption = canShowChatGptOption;
    $scope.sendWidgetDataToChatGpt = sendWidgetDataToChatGpt;
    $scope.canShowWidgetProductOption = canShowWidgetProductOption;
    $scope.initProductSidePanel = initProductSidePanel;
    $scope.canShowWidgetOptions = canShowWidgetOptions;
    $scope.viewAuditLogsForWidget = viewAuditLogsForWidget;
    const user = AppFactory.getUser();
    $scope.canViewAuditLogs = user.canAccessAuditLogs();

    /**
     * Tells us if widget is broken and cannot be used at all
     * @returns {*}
     */
    function widgetIsBroken() {
        return $scope.widget.metadata.dynamic && (!$scope.widget.metadata.dynamic.retriable_error && $scope.widget.metadata.dynamic.errors.length > 0);
    }

    /**
     * Resolves ng-if for edit-widget
     * @returns {boolean}
     */
    function canIncludeEditOption() {
        return $scope.widget.can_be_edited && Permission.hasPermissionToWrite(Permission.moduleName.WIDGET, $scope.widget.can_be_edited);
    }

    function editWidget() {
        if ($scope.widget.type === WidgetType.MEDIA) {
            WidgetCreateFactory.$initEdit($scope.state, $scope.widget);
        } else {
            PubSub.emit($WidgetBuilderEvents.INIT_PANEL, {
                model: $scope.widget,
                isDataWidget: !WidgetUtilService.isAdminWidget($scope.widget.type)
            });
        }
    }

    function swapWidget() {
        WidgetCreateFactory.setSwappedWidget($scope.widget);
        PubSub.emit($WidgetLibraryEvents.INIT_MODAL);
    }

    function toggleLayoutEditMode() {
        EditLayoutFactory.init();
    }

    function toggleManageLayoutsPanel() {
        ManageLayoutFactory.init($scope.widget.id);
    }

    /**
     * Resolves ng-if for filter-option
     * @returns {boolean}
     */
    function canIncludeFilterOption() {
        return LiveIntegrationFactory.canIncludeFilterOption($scope.widget)
            && $scope.state.isDataSourcedWidget
            && !$scope.state.isAdminWidget
            && Permission.hasPermissionToWrite(Permission.moduleName.WIDGET, Permission.permissionKey.CAN_READ_WRITE_FILTER_SET);
    }

    /**
     * Resolves ng-if for overrideDateRange-option
     * @returns {boolean}
     */
    function canIncludeOverrideDateRangeOption() {
        return $scope.state.isDataSourcedWidget
            && !$scope.state.isAdminWidget
            && Permission.hasPermissionToWrite(Permission.moduleName.WIDGET, Permission.permissionKey.CAN_READ_WRITE_FILTER_SET);
    }

    function filterWidgetData() {
        SlidePanelFactory.closeAll();
        WidgetFilterFactory.$init($scope.state, $scope.widget);
    }

    function overrideDateRange() {
        $scope.state.showDateRangeFilterPanel = false;
        WidgetDateRangeFactory.initModal($scope.widget);
    }

    /**
     * Resolves ng-if for alert-option
     * @returns {boolean}
     */
    function canIncludeAlertOption() {
        return LiveIntegrationFactory.canIncludeAlertOption($scope.widget)
            && AppFactory.getUser().isSuperAdmin()
            && $scope.state.isDataSourcedWidget
            && !$scope.state.isAdminWidget
            && !$scope.widget.is_predefined
            && DataSourceFactory.dataSourceContainsServices($scope.widget.metadata.data_source.type)
            && Permission.hasPermission(Permission.moduleName.WIDGET, Permission.permissionKey.CAN_EDIT_WIDGET_ALERTS, AppFactory.getUser().isSuperAdmin())
            && Permission.hasPermissionToWrite(Permission.moduleName.ALERT)
            && Permission.hasPermissionToWrite(Permission.moduleName.WIDGET);
    }

    function canIncludeAnnotations() {
        return LiveIntegrationFactory.canIncludeAnnotationOption($scope.widget)
            && $scope.state.isDataSourcedWidget
            && !$scope.state.isAdminWidget
            && !$scope.widget.is_predefined
            && Permission.hasPermission(Permission.moduleName.WIDGET, Permission.permissionKey.CAN_EDIT_WIDGET_ANNOTATIONS, AppFactory.getUser().isAdmin())
            && Permission.hasPermissionToWrite(Permission.moduleName.WIDGET);
    }

    function alertWidgetData() {
        SlidePanelFactory.closeAll();
        PubSub.emit($AlertEvents.INIT_WIDGET_ALERT, $scope.widget);
    }

    function initAnnotations() {
        SlidePanelFactory.closeAll();
        AnnotationFactory.$init($scope.state, $scope.widget);
    }

    function canManageDashboards() {
        return AppFactory.getUser().canManageDashboards;
    }

    /**
     * Resolves ng-if for export data options
     * @returns {boolean}
     */
    function canIncludeExportOption() {
        return $scope.state.isDataSourcedWidget;
    }

    /**
     * Resolves ng-show for export data options
     * @returns {boolean}
     */
    function canShowExportOption() {
        var state = $scope.state;
        return state.loadingState !== LoadingState.NO_DATA
            && state.loadingState !== LoadingState.ALL_EMPTY_DATA
            && $scope.widget.type !== WidgetType.MEDIA
            && $scope.widget.type !== WidgetType.GOAL
            && !$scope.state.isAdminWidget
            && canIncludeExportOption();
    }

    function exportDataToExcel() {
        if (!isDemoModeEnabled()) {
            var state = DesignFactory.state;
            $scope.$evalAsync(function () {
                state.exporting.loading = true;
                state.exporting.text = 'Exporting to Excel';
                state.exporting.icon = 'icon-file-excel-o';
                var params = {
                    widgetId: $scope.widget.id
                };
                $fileDownload(ExportReportFactory.exportWidgetToExcel(params).getRequestedUrl(), {
                    successCallback: function () {
                        doneLoading(state);
                    },
                    failCallback: function (err) {
                        var errObj = JSON.parse($(err).html());
                        doneLoading(state);
                        UIFactory.notify.showError(_.first(errObj.data));
                    }
                });
            });
        }
    }

    function exportDataToJson() {
        if (!isDemoModeEnabled()) {
            WidgetExportFactory.exportDataToJson($scope.widget, $scope.state);
        }
    }

    function exportDataToCsv() {
        if (!isDemoModeEnabled()) {
            var state = DesignFactory.state;
            $scope.$evalAsync(function () {
                state.exporting.loading = true;
                state.exporting.text = 'Exporting to Csv';
                state.exporting.icon = 'icon-file-excel-o';
                var params = {
                    widgetId: $scope.widget.id
                };
                $fileDownload(ExportReportFactory.exportWidgetToCsv(params).getRequestedUrl(), {
                    successCallback: function () {
                        doneLoading(state);
                    },
                    failCallback: function (err) {
                        var errObj = JSON.parse($(err).html());
                        doneLoading(state);
                        UIFactory.notify.showError(_.first(errObj.data));
                    }

                });
            });
        }
    }

    /**
     * There is a conflict between lib html2pdf and jquery $fileDownload,
     * so use A element instead
     * TODO
     * Should replace all the $fileDownloads here, will do after this function
     * is working with no error
     */
    function exportDataToCsvWithElement() {
        if (!isDemoModeEnabled()) {
            var state = DesignFactory.state;
            $scope.$evalAsync(function () {
                state.exporting.loading = true;
                state.exporting.text = 'Exporting to Csv';
                state.exporting.icon = 'icon-file-excel-o';
                const params = {
                    widgetId: $scope.widget.id
                };
                const responseUrl = ExportReportFactory.exportWidgetToCsv(params).getRequestedUrl();
                DownloadUtil.download(responseUrl, '');
                doneLoading(state);
            });
        }
    }

    /**
     * Resolves ng-if for dataexporter
     * @returns {boolean}
     */
    function canShowDataExporterOption() {
        const currentUser = AppFactory.getUser();
        // add more widget types later
        const allowedWidgetTypes = ['datagrid'];
        // super admin, new UI, data_exporter, valid type, valid datasource
        return currentUser.isSuperAdmin() &&
            currentUser.shouldUseNui() &&
            currentUser.isModuleAvailable(AppModule.DATA_EXPORTER) &&
            allowedWidgetTypes.includes($scope.widget.type) &&
            ($scope.widget.data_type === DataSourceType.SERVICE_DATA || $scope.widget.data_type === DataSourceType.CATEGORY_DATA) &&
            $scope.widget.metadata.data_source;
    }

    function exportToDataExporter() {
        const allFields = JSON.stringify(
          $scope.widget.metadata.data_columns.selected.map((selectedItem) => {
            return {
              field: selectedItem.field,
              label: selectedItem.label,
              client_id: selectedItem.client_id,
            };
          })
        );

        const icon = $scope.widget.metadata.data_source.type === DataSourceType.CATEGORY_DATA ? "ti ti-default" : $scope.widget.metadata.data_source.icon;
        const queryParams = {
          title: encodeURI($scope.widget.title.replace(/<[^>]+>/g, '')),
          datasource_id: encodeURI($scope.widget.metadata.data_source.id),
          datasource_type: encodeURI($scope.widget.metadata.data_source.type),
          datasource_id_name: encodeURI($scope.widget.metadata.data_source.id_name),
          datasource_icon: icon,
          datasource_color: encodeURI($scope.widget.metadata.data_source.color),
          dataview_id: encodeURI($scope.widget.metadata.data_source.data_view),
          dataview_name: encodeURI(
            $scope.widget.metadata.data_source.data_view_name
          ),
          customer_id: DashboardContextService.resolveSelectedEntityIdforType(DataSourceType.CLIENT) || null,
          client_group_id: DashboardContextService.resolveSelectedEntityIdforType(DataSourceType.CLIENT_GROUP) || null,
          fields: encodeURI(allFields),
        };

        window.location.href = `#/dataexporters/new?${$.param(queryParams)}`;
    }

    /**
     * @returns {boolean}
     */
    function canIncludeCloneOption() {
        return canIncludeEditOption() && !$scope.widget.is_predefined && canShowEditExportOptions();
    }

    /**
     * Creates a copy of the widget, saves it and adds it to the layout
     */
    function confirmWidgetClone() {
        var options = {};
        options.text = 'This will create a copy of this widget and all of its filters and annotations.';
        options.callback = function() {
            var dataOverride = {
                layout_id: $scope.widget.layout_id,
                copy_widget_filters: true
            };
            WidgetFactory.copy($scope.widget, dataOverride).then(function(clonedWidget) {
                swal.close();
                UIFactory.notify.showSuccess('Widget successfully cloned');
                LayoutFactory.addWidget(clonedWidget);
            }, function() {
                swal.enableButtons();
            });
        };

        UIFactory.confirm(options);
    }

    function canIncludePublishOptions() {
        return !isWidgetUsingClientSpecificCalculation() && canIncludeEditOption() && Permission.hasPermission(
              Permission.moduleName.DASHBOARD,
              Permission.permissionKey.CAN_PUBLISH_TO_LIBRARY,
            canIncludeEditOption()
        ) && canManageDashboards() && !$scope.widget.is_predefined && canShowEditExportOptions();
    }

    function isWidgetUsingClientSpecificCalculation() {
       return $scope.widget.metadata.dynamic?.filters.some(column => column.client_id) || 
        $scope.widget.metadata?.data_columns?.grouped.some(column => column.client_id) ||
        $scope.widget.metadata?.data_columns?.selected.some(column => column.client_id)
    }

    /**
     * Set widget to be published
     */
    function setWidgetForPublishing(bool=false) {
        $scope.widget.popup = bool; // assigning popup status for identifying from where it is triggering
        PubSub.emit($WidgetLibraryEvents.INIT_PUBLISH_MODAL, $scope.widget);
    }

    /**
     * @returns {boolean}
     */
    function canIncludeDeleteOption() {
        return WidgetFactory.canDelete($scope.widget);
    }

    function confirmWidgetDelete() {
        var options = {};
        options.text = 'This will remove the widget for all users that have access to this dashboard.';
        options.callback = function() {
            var widget = $scope.widget;
            WidgetFactory.remove({id: widget.id}).then(function() {
                swal.close();
                if (widget.metadata.dynamic.filters && widget.metadata.dynamic.filters.length) {
                    DashboardFilterPanelFactory.resetWidgetFilterOptionList();
                }
                UIFactory.notify.showSuccess('Widget successfully deleted');
                WidgetFactory.$getElement(widget.id).addClass('zoomOut magictime');
                $timeout(function() {
                    LayoutFactory.removeWidget(widget);
                }, 600);
            }, function() {
                swal.enableButtons();
            });
        };

        UIFactory.confirmDelete(options);
    }

    function doneLoading(state) {
        $scope.$evalAsync(function() {
            state.exporting.loading = LoadingState.DONE;
        });
    }

    function isDemoModeEnabled() {
        return DashboardContextService.isDemoModeEnabled();
    }

    $scope.hasGranularPermission = hasGranularPermission;
    function hasGranularPermission() {
        const dashboardContext = DashboardContextService.resolvePageId();
        if (!dashboardContext) return true;

        if (WidgetUtilService.isChatGptWidget($scope.widget.type)) return true;

        const page = DesignFactory.getCurrentPage();
        if (!page.has_granular_permissions) return true;

        return page.granular_access_levels.can_be_edited;
    }

    function editWidgetHeader() {
        WidgetHeaderModalFactory.initModal($scope.widget);
    }

    function canShowEditWidgetHeaderOption() {
        return ($scope.widget.type !== WidgetType.MEDIA);
    }

    function canShowEditExportOptions() {
        return !WidgetUtilService.isChatGptWidget($scope.widget.type);
    }

    function canShowChatGptOption() {
      return (
        !isDemoModeEnabled() &&
        AppFactory.canShowChatGptOption() &&
        WidgetUtilService.isDataWidget($scope.widget.type)
      );
    }

    function sendWidgetDataToChatGpt() {
        const daterange = AppFactory.getDateRange();
        const dashboardId = DesignFactory.getCurrentPage()?.id;
        const range = DateRangeFactory.getDateRangeFromRelativeRange($scope.widget.metadata.relative_date_range);
        let startDate = range.start.format(MomentDateFormat.ISO);
        let endDate = range.end.format(MomentDateFormat.ISO);

        if ($scope.widget.metadata.relative_date_range === RelativeDateRange.CUSTOM) {
            startDate = $scope.widget.metadata.start_date_override;
            endDate = $scope.widget.metadata.end_date_override;
        }

        const queryParams = {
            pageId: encodeURI(dashboardId),
            widgetId: encodeURI($scope.widget.id),
            dateRange: `${startDate}|${endDate}`,
            type: encodeURI('dashboard')
        };

        window.location.href = `#/${ChatGptSummariesRoute.CHATGPTSUMMARIES}/?${$.param(queryParams)}`;
    }

    function canShowWidgetProductOption() {
        return (
          AppFactory.getUser().isSuperAdmin() &&
          $scope.widget.can_be_edited &&
          (WidgetUtilService.isDataWidget($scope.widget.type) ||
            WidgetUtilService.isMediaWidget($scope.widget.type))
        );
    }

    function initProductSidePanel() {
        SlidePanelFactory.closeAll();
        ProductWidgetFactory.initPanel($scope.state, $scope.widget);
    }

    /**
     * Returns whether the widget options dropdown should be shown or not [RP-2625]
     *
     * @returns {boolean|*}
     */
    function canShowWidgetOptions() {
        return (
          canIncludeEditOption() ||
          canIncludeAlertOption() ||
          canIncludeAnnotations() ||
          canShowWidgetProductOption() ||
          canShowDataExporterOption() ||
          canShowChatGptOption() ||
          canShowEditExportOptions() ||
          canShowExportOption() ||
          canIncludePublishOptions() ||
          canIncludeCloneOption() ||
          canIncludeDeleteOption()
        );
    }

    function viewAuditLogsForWidget() {
        EventBus.signal('$PageNuiEvents.ViewAuditLogsForWidget', $scope.widget);
    }
}

