"use strict";
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import jQuery from 'jquery';
import {WeekDays} from "coreModules/daterange/base/daterange.constants";

var Core = $.core.datatable = {
    oTables: {}, //Object containing all initialized tables
    resetTables: function () {
        var tables = Core.oTables;
        $.each(tables, function(tableId) {
            var options = tables[tableId].options;
            if (!options.persist) {
                $(tables[tableId]).off();
                delete tables[tableId];
            }
        });
    },
    customSorting: function (type) {
        $.extend($.fn.dataTable.ext.oSort, {
            'date-pre': function(a) {
                let days = _.values(WeekDays);
                var angularAppInjectors = angular.element(document.body).injector();
                if(angularAppInjectors === undefined) {
                    angularAppInjectors = angular.element('#legacy-app').injector();
                }
                var gettextCatalog = angularAppInjectors.get('gettextCatalog');
                days = _.map(days, function(day) {
                    return gettextCatalog.getString(day);
                });
                let typeIndex = days.indexOf(type);
                while(typeIndex > 0){
                    days.push(days.shift());
                    typeIndex--;
                }
                return days.indexOf(a);
            },
            'date-asc': function(a, b) {
                return a - b;
            },
            'date-desc': function(a, b) {
                return b - a;
            }
        });
    },
    defaultOptions: function (item) {
        var options = {};
        options.persist = false;
        options.hasFixedHeader = true;

        options.item = item ? item.pluralize() : '';
        options.autoWidth = false;


        //Row grouping setup
        options.enableRowGrouping = false;
        options.rowGroupingDefaultSort = 0;
        options.rowGroupingColspan = 0;

        options.iDisplayLength = 50;
        options.dom = 'rplftp';

        options.order = [];

        options.language = {};
        options.language.paginate = {};
        var angularAppInjectors = angular.element(document.body).injector();
        if(angularAppInjectors === undefined) {
            angularAppInjectors = angular.element('#legacy-app').injector();
        }
        var gettextCatalog = angularAppInjectors.get('gettextCatalog');
        options.language.paginate.first = gettextCatalog.getString('First');
        options.language.paginate.last = gettextCatalog.getString('Last');
        options.language.paginate.next = gettextCatalog.getString('Next');
        options.language.paginate.previous = gettextCatalog.getString('Previous');

        options.language.search = '_INPUT_';
        options.language.lengthMenu = gettextCatalog.getString('Show _MENU_');
        options.language.zeroRecords = gettextCatalog.getString('No {{item}} were found.', {
            item: options.item
        });
        options.language.processing = '<div class="dataTables_processing_backdrop"></div><span><div class="loading-wheel"></div>'
            + gettextCatalog.getString('Fetching {{item}}', {item: options.item}) + '</span>';

        options.recordsInfoBadge = $('<div class="total-items label label-default"><span class="has-items"><span class="start"></span> '
            + gettextCatalog.getString('to')
            + ' <span class="end"></span> ' + gettextCatalog.getString('of')
            + ' <span class="total"></span></span><span class="no-items">'
            + gettextCatalog.getString('No items found') + '</span></div>');
        options.actionCallbacks = {};

        return options;
    },
    staticOptions: function (item) {
        var options = Core.defaultOptions(item);
        options.hasFixedHeader = false;
        options.static = true;
        options.iDisplayLength = -1;
        options.dom = 't';

        return options;
    },
    serverSideOptions: function (item, ajaxSource) {
        var options = Core.defaultOptions(item);
        options.processing = true;
        options.serverSide = true;
        options.sAjaxSource = ajaxSource;
        options.fnServerData = function ( sSource, aoData, fnCallback ) {
            $.post(sSource, aoData, function (json) {
                if (json.status == 'error') {
                    $.core.main.formErrorNotify(json);
                    fnCallback({aaData: []});
                }
                else {
                    fnCallback(json);
                }
            }, "json")
                .error(function(e) {
                    $.core.main.formErrorNotify(e.responseText);
                });
        };

        return options;
    },
    setColumns: function (columns, options) {
        options.aoColumns = [];
        var angularAppInjectors = angular.element(document.body).injector();
        if (!angularAppInjectors) {
            angularAppInjectors = angular.element('#legacy-app').injector();
        } 
        var brandMappings = angularAppInjectors.get('AppFactory').getBrandMappings();
        $.each(columns, function(i, column) {
            var oColumn = {};
            //oColumn.targets = i;
            oColumn.orderable = column.sortable == null;

            if (column.class_name == 'action-cell') {
                oColumn.orderable = false;
                oColumn.className = 'action-cell';
            }

            if (column.class_name == 'status-cell') {
                oColumn.className = 'status-cell';
            }

            if (column.label == 'Chart') {
                oColumn.orderable = false;
                oColumn.className = 'action-cell';
                //Increase ordering to next column
                options.order = [[1, 'asc']];
            }

            if (column.default_sort != null) {
                options.order = [[i, column.default_sort]];
            }
            //TODO: Should send a default sort order flag from backend rather than compare label
            else if (column.label == brandMappings.client || column.label == brandMappings.client_type) {
                options.order = [[i, 'asc']]; //Sort by client by default
            }
            else {
                options.order = [];
            }

            if (column.isNumeric) {
                oColumn.className = 'numeric';
                oColumn.orderSequence = ['desc', 'asc'];
            }

            options.aoColumns.push(oColumn);
        });

        return options;
    },
    build: function ($table, options) {
        //
        // Callbacks
        //
        if (!options.static) {
            // isNull/undefined check for ioTool. Needs skip if we want to set our custom functions
            if (_.isNull(options.fnCreatedRow) || _.isUndefined(options.fnCreatedRow)) {

                options.fnCreatedRow = function (row) {
                    $(row).find('a').each(function () {
                        var $cell = $(this).closest('td');

                        if (!options.skipActionCellBinding) {
                            //Make cell linkable (if not already an action-cell)
                            if (($(this).attr('href') != null || $(this).attr('ui-sref') != null)
                                && !$(this).hasClass('no-link-formatting')
                                && !$cell.hasClass('action-cell')
                            ) {
                                $cell.addClass('link-cell');

                                //$TODO: Need to change the structure in all the services
                                //This manipulates and extracts the text from the element to have full cell link
                                var $link = $cell.find('a');
                                var text = $link.text();
                                $link.empty();
                                $cell.append(text);
                            }
                            else if ($(this).hasClass('delete-action')) {
                                Core.bindDeleteRowAction($(this), options.actionCallbacks.delete);
                            }
                            else if ($(this).hasClass('terminate-action')) {
                                Core.bindTerminateRowAction($(this));
                            }
                            else if ($(this).hasClass('quick-delete-action')) {
                                Core.bindQuickDeleteRowAction($table.attr('id'), $(this), $(this).data('callback'));
                            }
                            else if ($(this).hasClass('chart-action')) {
                                Core.bindSubRowAction($table.attr('id'), $(this), $(row));
                            }
                            else if ($(this).hasClass('copy-action')) {
                                Core.bindCopyRowAction($table.attr('id'), $(this), options.actionCallbacks.copy);
                            }
                        }

                        //Add tooltips if any
                        if ($(this).hasClass('has-tooltip')) {
                            $.core.main.tooltip($cell);
                        }
                    });

                    //Add tooltips to any element that my have has-tooltip class
                    $(row).find('td .has-tooltip').each(function () {
                        //Add tooltips if any
                        if ($(this).hasClass('has-tooltip')) {
                            $.core.main.tooltip($(this).parent());
                        }
                    });
                };
            }

            if (_.isNull(options.fnInitComplete) || _.isUndefined(options.fnInitComplete)){
                options.fnInitComplete = function(settings) {

                    var $tableWrapper = $(settings.nTableWrapper);

                    //Show initially invisible search input and length menu
                    $tableWrapper.find('div.dataTables_filter, div.dataTables_length').showV();


                    //Add placeholder to search input
                    var angularAppInjectors = angular.element(document.body).injector();
                    if (!angularAppInjectors) {
                        angularAppInjectors = angular.element('#legacy-app').injector();
                    } 
                    var gettextCatalog = angularAppInjectors.get('gettextCatalog');

                    $tableWrapper.find('div.dataTables_filter input').attr('placeholder',
                        gettextCatalog.getString('Search {{item}}...', {item: options.item}));

                    //Set datatable header ///////

                    //Initialize records info for the first time
                    Core.setRecordsInfoBadge(options, settings);

                    //Prepend the main content title to the datatable and add the total item count
                    var $title = $tableWrapper.prev('div.main-content-title.use-in-datatable');
                    if ($title.length) {
                        $title.show();
                        $tableWrapper.prepend($title);
                        options.recordsInfoBadge.insertAfter($title.find('p.main-title'));
                    }
                    else {
                        $tableWrapper.prepend(options.recordsInfoBadge);
                    }
                    //Wrap the header contents of datatable for easier manipulation
                    $tableWrapper.prepend('<div class="dataTables_header"></div>');
                    var $header = $tableWrapper.find('div.dataTables_header');

                    $.each($(settings.nTable).prevAll(), function() {
                        if (!$(this).hasClass('dataTables_fixedHeader')) {
                            $header.prepend($(this));
                        }
                    });

                    //Extract the total row footer and place it at the top of the table
                    if ($table.find('tfoot.footer-total').length) {
                        $('<tbody class="total-row"></tbody>').html($(settings.nTFoot).html()).insertAfter($(settings.nTHead));
                        $(settings.nTFoot).remove();
                    }

                    //If any datatable footer note present on page, insert it at end of table wrapper
                    if ($('div.datatable-footer').length) {
                        $tableWrapper.append($('div.datatable-footer').show());
                    }

                    //Client side tables are initially hidden so that we dont see the weird formatting
                    if ($table.is(':hidden')) {
                        $table.show();
                    }

                    //Set the header initially as well as on redraws below
                    if (options.hasFixedHeader) {
                        $tableWrapper.find('div.dataTables_fixedHeader').remove();
                        //Will be used to contain and display the fixed table header
                        options.fixedHeader = $('<div class="dataTables_fixedHeader" id="' + $table.attr('id') + '_fixedHeader"></div>');
                        options.fixedHeader.insertBefore($table);

                        Core.setFixedHeader(options, settings, false);
                    }

                    //If any datatable filter group show it once table is drawn
                    if ($('div.datatable-filter-group').length) {
                        $('div.datatable-filter-group').show();
                    }

                    //Table draw callback (opt to use this instead of fnDrawCallback since the latter is called twice)
                    $table.on('draw.dt', function () {
                        //Update records info
                        Core.setRecordsInfoBadge(options, settings);

                        if (options.hasFixedHeader) {
                            Core.setFixedHeader(options, settings, true);
                            if(options.fixedHeader.hasClass('affix-top')){
                                $('#right-frame').scrollTop(0);
                            }
                        }
                    });
                };
            }

            //Certain functions needs to be in this callback to work for some reason (instead of on draw.dt above)
            options.fnDrawCallback = function (settings) {
                var $tableWrapper = $(settings.nTableWrapper);

                //If any popovers within table
                if ($tableWrapper.find('a[data-toggle=popover]').length) {
                    $tableWrapper.find('a[data-toggle=popover]').popover({
                        placement: 'top',
                        html: true,
                        trigger: 'hover',
                        container: 'body'
                    });
                }

                if (options.enableRowGrouping) {
                    var api = this.api();
                    var rows = api.rows({page: 'current'}).nodes();
                    var last = null;
                    api.column(options.rowGroupingDefaultSort, {
                        page: 'current'
                    }).data().each(function (group, i) {
                        if (last !== group) {
                            $(rows).eq(i).before('<tr class="group"><td colspan="' + options.rowGroupingColspan + '">' + group + '</td></tr>');
                            last = group;
                        }
                    });
                }
            };

            if (options.iActions > 0) {
                //If more than one action grow by 34 for each action, else 55 by default
                let width =  options.iActions > 1 ? options.iActions * 34 : 55;
                let target = $table.find('th.action-column').index();
                options.columnDefs = [
                    { orderable: false, targets: target, width: width }
                ];
            }
        }

        options.columnDefs = [{
            type: 'natural',
            targets: options.naturalColumnIndex,
        }];

        if (options.hasOwnProperty('nonSortableColumnIndex')) {
            // Use array push so we don't overwrite other columnDefs...
            options.columnDefs.push({orderable: false, targets: options.nonSortableColumnIndex});
        }

        //Add some extra css classes to the datatable
        $table.addClass('table table-striped');

        //Set a filter delay for server side data when filtering
        var oTable = $table.dataTable(options);

        //Make datatables and its respective options accessible throughout the app
        Core.oTables[$table.attr('id')] = {oTable: oTable, options: options};

        if (options.bServerSide) {
            oTable.fnSetFilteringDelay(700);
        }

        //Binding for row group sorting
        if (options.enableRowGrouping) {
            var api = oTable.api();
            $table.find('tbody').on('click', 'tr.group', function () {
                var currentOrder = api.order()[0];
                if (currentOrder[0] === options.rowGroupingDefaultSort && currentOrder[1] === 'asc') {
                    api.order([options.rowGroupingDefaultSort, 'desc']).draw();
                }
                else {
                    api.order([options.rowGroupingDefaultSort, 'asc']).draw();
                }
            });
        }

        return oTable;
    },
    bindSubRowAction: function ($tableId, $toggler, $row) {
        var row = Core.oTables[$tableId].oTable.api().row($row);
        $toggler.click(function() {
            var $this = $(this);
            var id = $this.data('rel');
            //Hide sub row
            if (row.child.isShown()) {
                $this.removeClass('active');
                row.child.hide();
            }
            //Show sub row
            else {
                $this.addClass('active');
                row.child('').show();

                var $subrow = $row.next();
                $subrow.addClass('subrow');

                //Init chart if not already
                if ($subrow.find('td').is(':empty')) {
                    $.get($('#chart-url').val() + 'service/' + $('#current-service-id').val() + '/campaign_id/' + id, function(data) {
                        $subrow.find('td').html(data);
                        $subrow.find('div.chart-filters button.active').click();
                    });
                }
            }
        });

    },
    setFixedHeader: function (options, settings, isRedraw) {
        var $tableHeaderClone = $(settings.nTHead).clone(true);
        var $totalRowClone = $(settings.nTable).find('tbody.total-row').clone(false);

        //Add the total row to the fixed header
        var $fixedHeader = $tableHeaderClone.add($totalRowClone);
        options.fixedHeader = options.fixedHeader || $();
        options.fixedHeader.html($fixedHeader);

        //Wrap with table style so that fixed header gets styling
        $fixedHeader.wrapAll('<table class="'+$(settings.nTable).attr('class')+'"></table>');

        if ($(settings.nTHead).length) {
            var $leftFrame = $('#left-frame');
            var $scrollFrame = $('#right-frame');
            var $dashFilters = $('div#dash-filters');
            var $datatableFilterGroup = $('div.datatable-filter-group');
            var $viewAsClient = $('#specific-client-view');

            //Width - left
            var colWidths = $.map($(settings.nTHead).find('th'), function (val) {
                return $(val).width();
            });

            $tableHeaderClone.find('th').each(function (i) {
                $(this).css({width: colWidths[i] + 'px'});
            });

            $(settings.nTable).addClass('drilldown-table');

            options.fixedHeader.css({width: $(settings.nTHead).outerWidth() + 'px', display: 'none'});

            //Height - top
            var headerTopPos = $(settings.nTHead).offset().top + $scrollFrame.scrollTop();

            //If dash filter mode fixed bar and/or client view bar is showing, we need to compensate spacing for fixed header
            if ($dashFilters.is(':visible') && $viewAsClient.is(':visible')) {
                options.fixedHeader.addClass('client-fixed');
                headerTopPos -= $dashFilters.height() + 20; //Includes view as client height
            }
            else if ($dashFilters.is(':visible')) {
                options.fixedHeader.addClass('dash-filter-fixed');
                headerTopPos -= $dashFilters.outerHeight();
            }

            //If datatafilter group is present we need to add it to the height since
            // its generated by PHP and not visible at time of table creation
            if ($datatableFilterGroup.length) {
                headerTopPos += $datatableFilterGroup.outerHeight();
            }

            //This only occurs when first initializing the datatable on fnInitComplete
            if (!isRedraw) {
                $scrollFrame.unbind('click'); //Unbind affix click function
                $scrollFrame.unbind('scroll'); //Unbind affix scroll function + scrollFrame scroll binding

                options.fixedHeader.affix({offset: {top: headerTopPos}, target: $scrollFrame});

                //Since the header is fixed, we need to adjust its left position as we scroll the frame
                $scrollFrame.scroll(function () {
                    if (options.fixedHeader.hasClass('affix') && $(this).scrollLeft() >= 0) {
                        options.fixedHeader.css({left: $leftFrame.width() - $(this).scrollLeft() + 'px'});
                    }
                    if ($scrollFrame.scrollTop() > headerTopPos) {
                        options.fixedHeader.css({display: 'block'});
                    } else {
                        options.fixedHeader.css({display: 'none'});
                    }
                });

                //When window is resized, we need to reinitialize the fixed header
                $(window).resize(function () {
                    Core.setFixedHeader(options, settings, true);
                });
            }
            else if (headerTopPos > 0 && options.fixedHeader.data('bs.affix') != null) {
                options.fixedHeader.data('bs.affix').options.offset.top = headerTopPos;
            }
        }

    },
    updateFixedHeaders: function () {
        var $tables = Core.oTables;
        $.each($tables, function(tableId) {
            var options = $tables[tableId].options;
            var oTable = $tables[tableId].oTable;
            if (options.hasFixedHeader && $(oTable).is(':visible')) {
                $.core.datatable.setFixedHeader(options, oTable.api().settings()[0], true);
            }
        });
    },
    setRecordsInfoBadge: function (options, settings) {

        var $elm = options.recordsInfoBadge;
        var start = settings._iDisplayStart + 1;
        var end = settings.fnDisplayEnd();
        var total = settings.fnRecordsDisplay();

        $elm.find('span.no-items').hide();
        $elm.find('span.has-items').hide();

        if(total == 0) {
            $elm.find('span.no-items').show();
        }
        else if ($elm.length) {
            $elm.find('span.has-items').show();
            $elm.find('span.start').html(start);
            $elm.find('span.end').html(end);
            $elm.find('span.total').html(total);
        }
    },
    bindQuickDeleteRowAction: function ($tableId, $delete, fnCallback) { //This function only removes the row from the ui
        $delete.click(function() {
            $(this).tooltip('destroy');
            var $row = $(this).closest('tr');
            Core.oTables[$tableId].oTable.api().row($row).remove().draw();

            $.fn.safeEval(fnCallback);
        });
    },
    bindDeleteRowAction: function ($delete, callback) {
        $delete.click(function() {
            Core.deleteRow($(this), $(this).data('name') == '' ? 'this row' : $(this).data('name'), callback);
        });
    },
    deleteRow: function ($deleteAction, title, callback) {
        var $modal = $('#delete-row-confirm-modal');
        var angularAppInjectors = angular.element(document.body).injector();
        if (!angularAppInjectors) {
            angularAppInjectors = angular.element('#legacy-app').injector();
        }
        var gettextCatalog = angularAppInjectors.get('gettextCatalog');
        $modal.find('div.modal-body').html(gettextCatalog.getString(
            'You are about to delete <b>{{title}}</b>. Are you sure you want to proceed?',
            {title: title}));
        $.core.main.showModal($modal);

        //Pass the jquery object to the confirm button for later use
        $('#delete-row-confirm').data('obj', $deleteAction);
        $('#delete-row-confirm').data('callback', callback);
    },
    bindTerminateRowAction: function ($terminate) {
        $terminate.click(function() {
            Core.terminateRow($(this), $(this).data('name') == '' ? 'this row' : $(this).data('name'), $(this).data('termination-object'));
        });
    },
    terminateRow: function ($terminateAction, title, termination_object) {
        var $modal = $('#terminate-row-confirm-modal');
        var angularAppInjectors = angular.element(document.body).injector();
        if (!angularAppInjectors) {
            angularAppInjectors = angular.element('#legacy-app').injector();
        }
        var gettextCatalog = angularAppInjectors.get('gettextCatalog');
        var confirm_message_start = gettextCatalog.getString('You are about to terminate');
        if (termination_object == 'user')
        {
            confirm_message_start = gettextCatalog.getString('You are about to make the following user inactive:');
        }
        $modal.find('div.modal-body').html(confirm_message_start + ' <b>' + title + '</b>.<br><br>'
            + gettextCatalog.getString('Are you sure you want to proceed?'));
        $.core.main.showModal($modal);

        //Pass the jquery object to the confirm button for later use
        $('#terminate-row-confirm').data('obj', $terminateAction);
    },
    bindCopyRowAction: function ($tableId, $copy, callback) {
        $copy.click(function(e) {
            Core.copyRow($tableId, $(this), callback);
        });
    },
    copyRow: function($tableId, $copyAction, fnCallback) {
        var angularAppInjectors = angular.element(document.body).injector();
        if (!angularAppInjectors) {
            angularAppInjectors = angular.element('#legacy-app').injector();
        }
        var gettextCatalog = angularAppInjectors.get('gettextCatalog');
        $.core.main.showAjaxMainLoading({text: gettextCatalog.getString('Copying...')});
        $.getJSON($.fn.apiUrl($copyAction.data('url')), function (json) {
            $.core.main.hideAjaxMainLoading();
            window.location = $copyAction.data('redirect') + json.data.id;
            $.core.main.notify(gettextCatalog.getString('Channel was successfully copied'), $.globals.notify.success);

            fnCallback && fnCallback();
        }).error(function(jqXHR) {
            var json = JSON.parse(jqXHR.responseText);
            $.core.main.hideAjaxMainLoading();
            $.core.main.formErrorNotify(json.msg);
        });
    },
    render: function(type, data) {
        var html = '';
        var angularAppInjectors = angular.element(document.body).injector();
        if (!angularAppInjectors) {
            angularAppInjectors = angular.element('#legacy-app').injector();
        }
        var gettextCatalog = angularAppInjectors.get('gettextCatalog');
        switch (type) {
            case 'copyButton':
                html = '<a class="action has-tooltip copy-action" title="'
                    + gettextCatalog.getString('Copy') + '" data-url="'
                    + data.url + '" data-redirect="#/' + data.redirect
                    + '"><span class="icon icon-copy"></span></a>';
                break;

            case 'editButton':
                html = '<a class="action has-tooltip" title="'
                    + gettextCatalog.getString('Edit') + '" href="' + data
                    + '"><span class="icon icon-pencil"></span></a>';
                break;

            case 'deleteButton':
                html = '<a class="action action-red delete-action has-tooltip" title="'
                    + gettextCatalog.getString('Delete') + '" data-url="' + data.url
                    + '" data-name="' + data.name
                    + '" data-is-angular="true"><span class="icon icon-trash"></span></a>';
                break;

            case 'disabledCopyButton':
                html = '<a class="action disabled"><span class="icon icon-copy"></span></a>';
                break;

            case 'disabledEditButton':
                html = '<a class="action disabled"><span class="icon icon-pencil"></span></a>';
                break;

            case 'disabledDeleteButton':
                html = '<a class="action disabled' + (data.tooltip ? ' has-tooltip" title="' + data.tooltip : '') + '"><span class="icon icon-trash"></span></a>';
                break;

            case 'prependIcon':
                var style = 'font-size:18px;position:relative;top:-1px;color:' + data.color;
                html = '<i class="icon ' + data.icon + ' mr5 left" style="' + style + ';"></i>' + data.value;
                break;
        }
        return html;
    },
    displayCopyRowButton: function(data, options, full) {
        var html = '';
        if (full.can_be_copied) {
            html += Core.render('copyButton', { url: options.entityName + '/copy/' + data, redirect: options.entityName + '/detail/'});
        }
        else {
            if(full.can_be_copied){
                html += Core.render('disabledCopyButton');
            }
        }
        return html;
    },
    displayEditRowButton: function(data, options, full) {
        var html = '';
        // /{parentName}/{:parentId}/detail/{:childId}
        if (full.can_be_edited) {
            if (options.isParentChildEntity) {
                html += (window.isNUI
                    ? Core.render('editButton', '#/' + options.entityName + '/' + full[options.entityIdName] + '/' + options.childEntityName + '/' + data)
                    : Core.render('editButton', '#/' + options.entityName + '/detail/' + full[options.entityIdName] + '/' + options.childEntityName + '/' + data));
            }
            else {
                html += (window.isNUI
                    ? Core.render('editButton', '#/' + options.entityName + '/' + data)
                    : Core.render('editButton', '#/' + options.entityName + '/detail/' + data));
            }
        }
        else {
            html += Core.render('disabledEditButton');
        }
        return html;
    },
    displayDeleteRowButton: function(data, options, full) {
        var html = '';
        if (full.can_be_deleted) {
            // /{parentName}/{:parentId}/{childName}/{:childId}
            if (options.isParentChildEntity) {
                html += Core.render('deleteButton', { url: options.entityName + '/' + full[options.entityIdName] + '/' + options.childEntityName + '/' + data, name: full['name'] });
            }
            else {
                html += Core.render('deleteButton', { url: options.entityName + '/' + data, name: full['name'] });
            }
        }
        else {
            html += Core.render('disabledDeleteButton', { tooltip: full.can_be_deleted_tooltip});
        }
        return html;
    },
};

/**
 * Disable datatable alerts
 * @type {string}
 */
jQuery.fn.dataTable.ext.errMode = 'none';

/**
 * Created by pbriss on 2014-07-03.
 */
jQuery.fn.dataTableExt.oApi.fnSetFilteringDelay = function ( oSettings, iDelay ) {
    var _that = this;

    if ( iDelay === undefined ) {
        iDelay = 250;
    }

    this.each( function ( i ) {
        $.fn.dataTableExt.iApiIndex = i;

        if (_that.fnSettings().aanFeatures.f) {
            var
                $this = this,
                oTimerId = null,
                sPreviousSearch = null,
                anControl = $('input', _that.fnSettings().aanFeatures.f);

            anControl.unbind('keyup search input').bind('keyup search input', function () {
                var $$this = $this;

                if (sPreviousSearch === null || sPreviousSearch != anControl.val()) {
                    window.clearTimeout(oTimerId);
                    sPreviousSearch = anControl.val();
                    oTimerId = window.setTimeout(function () {
                        $.fn.dataTableExt.iApiIndex = i;
                        _that.fnFilter(anControl.val());
                    }, iDelay);
                }
            });
        }

        return this;
    } );
    return this;
};

jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    'comparison-value-asc': function ( x, y ) {
        x = x.toString();
        y = y.toString();
        var x1 = stripToInteger(x.slice(0, x.lastIndexOf('[comparison]')));
        var y1 = stripToInteger(y.slice(0, y.lastIndexOf('[comparison]')));
        return compare(x1, y1, 'asc');
    },
    'comparison-value-desc': function ( x, y ) {
        x = x.toString();
        y = y.toString();
        var x1 = stripToInteger(x.slice(0, x.lastIndexOf('[comparison]')));
        var y1 = stripToInteger(y.slice(0, y.lastIndexOf('[comparison]')));
        return compare(x1, y1, 'desc');
    },
    'number-asc': function ( x, y ) {
        return compare(stripToInteger(x), stripToInteger(y), 'asc');
    },
    'number-desc': function ( x, y ) {
        return compare(stripToInteger(x), stripToInteger(y), 'desc');
    },
    'natural-asc': function ( a, b ) {
        return naturalSort(a, b);
    },
    'natural-desc': function ( a, b ) {
        return naturalSort(a, b) * -1;
    },
} );

function stripToInteger(str) {
    return _.isString(str) ? _.toNumber(str
      .replace(/<[^>]*>?/gm, '').replace(/[^0-9-.]/g, '')) : str;
}

function compare(x, y, order) {
    return order == 'asc' ? ((x < y) ? -1 : ((x > y) ? 1 : 0))
        : ((x < y) ? 1 : ((x > y) ? -1 : 0));
}

function naturalSort(a, b) {
    return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
}