import { Legend } from '@/modules/core/charts/am5/base/models/Legend';
import { Label } from '@/modules/core/charts/am5/base/models/Label';
import { StyleProperty } from '@/modules/core/charts/am5/base/models/StyleProperty';
import { SettingsObject } from '@/modules/core/app/models/SettingsObject';
import { ChartPlace, Constant } from '@/modules/core/charts/am5/charts.constants';

/**
 * @class ChartConfig
 */
export class ChartConfig extends SettingsObject {
  /**
   * Carries data for which graph has to created
   * @var {Array}
   */
  data;

  /**
   * holds configurations related to legend
   * @var {Legend}
   */
  legend;

  /**
   * A list of label elements for the chart.
   * @var {Array<Label>}
   */
  labels;

  /**
   * A list of titles elements on top of the chart.
   * @var {Array<Label>}
   */
  titles;

  /**
   * Increase depth of chart (3D effect)
   * @var {depth}
   */
  depth;

  /**
   * An angle of a "point of view" in degrees. Possible range 0 - 90.
   * @var {Number}
   */
  angle;

  /**
   * Time of animation when chart renders. Setting to 0 disables animation
   * @var {number}
   * @default 0
   */
  startDuration;

  /**
   * A shortcut to setting mouse cursor on hover.
   * @var {Array<StyleProperty>}k;kOh
   */
  cursorOverStyle;

  /**
   * Flag which indicate widget is exporting or not
   * @var {boolean}
   */
  isExporting;

  /**
   * @var {boolean}
   */
  currencyDiscrepancy;

  /**
   * @var {boolean}
   */
  showCurrency;

  /**
   * @var {boolean}
   */
  canDrillDown;

  /**
   * @var {boolean}
   */
  comparisonEnabled;

  /**
   * @var {boolean}
   */
  isInlineDrillDown;

  /**
   * Benchmark data including config
   * @var {{}}
   */
  benchmarks;

  /**
   * Whether we should fill the area above the line (for an area chart of ranking metrics)
   * @var {Boolean}
   * @default false
   */
  fillAreaAboveLine;

  constructor(model = {}) {
    super();
    const { startDuration = 0, fillAreaAboveLine = false } = model;
    this.data = model.data;
    this.legend = new Legend(model.legend);
    this.labels = model.labels?.map((label) => new Label(label));
    this.depth = model.depth;
    this.angle = model.angle;
    this.startDuration = startDuration;
    this.comparisonEnabled = model.comparisonEnabled;
    this.titles = model.titles?.map((title) => new Label(title));
    this.cursorOverStyle = model.cursorOverStyle?.map((style) => new StyleProperty(style));
    this.watch(Constant.CHART_PLACE, (val) => {
      // eslint-disable-next-line tap/no-raw-text-js
      const defaultsFunction = this[`set${val}ChartDefaults`];
      // eslint-disable-next-line tap/no-raw-text-js
      if (typeof defaultsFunction === 'function') {
        defaultsFunction.bind(this)();
      }
    });
    this.isExporting = model.isExporting;
    this.currencyDiscrepancy = model.currencyDiscrepancy;
    this.showCurrency = model.showCurrency;
    this.canDrillDown = model.canDrillDown;
    this.isInlineDrillDown = model.isInlineDrillDown;
    this.benchmarks = model.benchmarks;
    this.fillAreaAboveLine = fillAreaAboveLine;
  }

  canIncludeInteractivity() {
    return this.get(Constant.CHART_PLACE) === ChartPlace.DASHBOARD && !this.isExporting;
  }

  isReportStudioWidget() {
    return this.get(Constant.CHART_PLACE) === ChartPlace.REPORT;
  }

  setReportChartDefaults() {}

  setDashboardChartDefaults() {}
}
