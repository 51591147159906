'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';

angular.module('widget.daterange.services', [])

    .factory('WidgetDateRangeFactory', WidgetDateRangeFactory);

/**
 * @ngInject
 */
function WidgetDateRangeFactory(
    $timeout,
    MomentDateFormat,
    UIFactory,
    AppFactory,
    DesignFactory,
    LayoutFactory,
    WidgetFactory,
    DateRangeFactory,
    RelativeDateRange,
    $DateRangeEvents,
    PubSub,
    LanguageService,
    gettextCatalog,
    ChartPlotType,
    WidgetType,
    SparklineComparisonTypes
) {
    var dateOptions;
    var comparisonDateOptions;
    var $dateRangePicker;
    var $comparisonDateRangePicker;
    var $modal;
    var modalId = 'date-range-filter-modal';

    var relativeDateRanges = _.map(DateRangeFactory.getDatePickerRelativeRanges(), function(value, key) {
        return {value: DateRangeFactory.getRelativeDateRangeByTextDisplay(key), text: key};
    });
    relativeDateRanges.push({value: RelativeDateRange.CUSTOM, text: gettextCatalog.getString('Custom (Fixed Range)')});

    var comparisonRanges = _.map(DateRangeFactory.getComparisonDatePickerRelativeRanges(), function(value, key) {
        return {value: DateRangeFactory.getRelativeDateRangeByTextDisplay(key), text: key};
    });

    var shared = {
        state: {
            isSaving: false,
            includeModal: false
        },
        widgetId: null,
        model: {},
        metadata: {},
        relativeDateRanges: relativeDateRanges,
        comparisonRanges: comparisonRanges,
        onModalClose: onModalClose,
        updateCurrentRange: updateCurrentRange,
        updateComparisonRange: updateComparisonRange,
        canApplyOverride: canApplyOverride,
        canClearOverride: canClearOverride,
        applyDateRangeOverride: applyDateRangeOverride,
        clearDateRangeOverride: clearDateRangeOverride,
        closeModal: closeModal,
        canShowComparisonTypes: canShowComparisonTypes,
        getCompareClass: getCompareClass,
        canChangeComparisonPeriod: canChangeComparisonPeriod
    };

    return {
        shared: shared,
        initModal: initModal
    };

    function initModal(widget) {
        shared.state.includeModal = true;
        shared.widget = widget;
        shared.compareWordText = canShowComparisonTypes() ? 'Compare' : 'Compare to Prior?';

        $timeout(function () {
            _init();
        }, 500);
    }

    function _init() {
        shared.model.startDate = shared.widget.metadata.start_date_override;
        shared.model.endDate = shared.widget.metadata.end_date_override;
        shared.model.relativeDateRange = shared.widget.metadata.relative_date_range || RelativeDateRange.DEFAULT;
        shared.model.compareToPriorPeriod = shared.widget.metadata.compare_to_prior_period;
        shared.model.comparisonStartDate = shared.widget.metadata.comparison_start_date_override;
        shared.model.comparisonEndDate = shared.widget.metadata.comparison_end_date_override;
        shared.model.comparisonRelativeDateRange = shared.widget.metadata.comparison_relative_date_range || RelativeDateRange.PRIOR_PERIOD;

        var options = {
            opens: 'center',
            ranges: null,
            showCustomRangeLabel: false,
            autoApply: true
        };

        var dates = shared.widget.metadata.relative_date_range === RelativeDateRange.CUSTOM
            ? {start: moment(shared.model.startDate), end: moment(shared.model.endDate)}
            : DateRangeFactory.getDateRangeFromRelativeRange(shared.widget.metadata.relative_date_range);

        var defaultOptions = DateRangeFactory.getDefaultOptions(
            dates.start,
            dates.end
        );

        var comparisonDates = shared.widget.metadata.comparison_relative_date_range === RelativeDateRange.CUSTOM
            ? {start: moment(shared.model.comparisonStartDate), end: moment(shared.model.comparisonEndDate)}
            : DateRangeFactory.calculatePeriod(
                dates.start,
                dates.end,
                shared.widget.metadata.relative_date_range,
                shared.widget.metadata.comparison_relative_date_range
            );

        // Used only to display a date in the input box when Compare To Prior is disabled
        shared.model.comaprisonDateRangePlaceholder = comparisonDates.start.format(LanguageService.getDisplayDateFormat()) + ' - ' + comparisonDates.end.format(LanguageService.getDisplayDateFormat())

        var comparisonDefaultOptions = DateRangeFactory.getComparisonDefaultOptions(
            comparisonDates.start,
            comparisonDates.end
        );

        dateOptions = _.assign(defaultOptions, options);
        comparisonDateOptions = _.assign(comparisonDefaultOptions, options);

        $timeout(function() {
            $modal = angular.element('#' + modalId);
            $dateRangePicker = $modal.find('input.current-range');
            $comparisonDateRangePicker = $modal.find('input.comparison-range');

            initDatePicker();
            initComparisonDatePicker();
            UIFactory.showModal(modalId);
        }, 50, false);
    }

    /**
     * @param options
     */
    function initDatePicker(options) {
        DateRangeFactory.setDatePicker(
            _.assign(dateOptions, options || {}),
            comparisonDateOptions,
            $dateRangePicker,
            $comparisonDateRangePicker,
            function() {}
        );
    }

    /**
     * @param options
     */
    function initComparisonDatePicker(options) {
        DateRangeFactory.setComparisonDatePicker(
            dateOptions,
            _.assign(comparisonDateOptions, options || {}),
            $dateRangePicker,
            $comparisonDateRangePicker,
            function() {}
        );
    }

    function onModalClose() {
        shared.isActive = false;
        UIFactory.hideModal(modalId);
    }

    /**
     *
     */
    function updateCurrentRange() {
        if (shared.model.relativeDateRange !== RelativeDateRange.CUSTOM) {
            var range = DateRangeFactory.getDateRangeFromRelativeRange(shared.model.relativeDateRange);
            initDatePicker({
                startDate: range.start,
                endDate: range.end
            });

            // If comparison was Custom, set back to prior period by default
            if (shared.model.comparisonRelativeDateRange === RelativeDateRange.CUSTOM) {
                shared.model.comparisonRelativeDateRange = RelativeDateRange.PRIOR_PERIOD;
            }
        }
        else {
            shared.model.comparisonRelativeDateRange = RelativeDateRange.CUSTOM;
        }
        updateComparisonRange();
    }

    /**
     *
     */
    function updateComparisonRange() {
        if (shared.model.relativeDateRange !== RelativeDateRange.CUSTOM) {
            var currentRange = DateRangeFactory.getDateRangeFromRelativeRange(shared.model.relativeDateRange);
            var comparisonRange = DateRangeFactory.calculatePeriod(
                currentRange.start,
                currentRange.end,
                shared.model.relativeDateRange,
                shared.model.comparisonRelativeDateRange
            );
            initComparisonDatePicker({
                startDate: comparisonRange.start,
                endDate: comparisonRange.end
            });
        }
    }

    function canApplyOverride() {
        // No override date range on widget, compare with dashboard level date range
        if (shared.widget.metadata && !shared.widget.metadata.is_overriding_date_range) {
            return shared.model.relativeDateRange !== RelativeDateRange.DEFAULT
                || shared.model.compareToPriorPeriod !== AppFactory.getComparisonDateRange().enabled;
        }
        // If user applied override date range before, always allow user to override
        return true;
    }

    function canClearOverride() {
        return shared.widget.metadata
            && shared.widget.metadata.is_overriding_date_range;
    }

    function canShowComparisonTypes() {
      return (
        shared.widget.metadata.type === WidgetType.BIGNUMBER &&
        shared.widget.metadata.draw_options.plot_type ===
          ChartPlotType.SPARKLINE
      );
    }

    function getCompareClass() {
        return canShowComparisonTypes() ? '' : 'block';
    }

    function canChangeComparisonPeriod() {
      return canShowComparisonTypes()
        ? shared.model.compareToPriorPeriod &&
            shared.widget.metadata.comparison_type !==
              SparklineComparisonTypes.FINAL_INITIAL_VALUE
        : shared.model.compareToPriorPeriod;
    }

    /**
     *
     */
    function applyDateRangeOverride() {
        var dateRangePickerData = $dateRangePicker.data('daterangepicker');
        var comparisonDateRangePickerData = $comparisonDateRangePicker.data('daterangepicker');

        var metadata = shared.widget.metadata;
        metadata.is_overriding_date_range = shared.model.relativeDateRange !== RelativeDateRange.DEFAULT
            || shared.model.compareToPriorPeriod;
        metadata.start_date_override = shared.model.relativeDateRange === RelativeDateRange.CUSTOM
            ? dateRangePickerData.startDate.format(MomentDateFormat.ISO)
            : null;
        metadata.end_date_override = shared.model.relativeDateRange === RelativeDateRange.CUSTOM
            ? dateRangePickerData.endDate.format(MomentDateFormat.ISO)
            : null;
        metadata.relative_date_range  = shared.model.relativeDateRange;
        metadata.compare_to_prior_period = shared.model.compareToPriorPeriod;
        metadata.comparison_start_date_override = shared.model.comparisonRelativeDateRange === RelativeDateRange.CUSTOM
            ? comparisonDateRangePickerData.startDate.format(MomentDateFormat.ISO)
            : null;
        metadata.comparison_end_date_override = shared.model.comparisonRelativeDateRange === RelativeDateRange.CUSTOM
            ? comparisonDateRangePickerData.endDate.format(MomentDateFormat.ISO)
            : null;
        metadata.comparison_relative_date_range  = shared.model.comparisonRelativeDateRange;

        shared.state.isSaving = true;
        WidgetFactory.save(shared.widget).then(function() {
            PubSub.emit($DateRangeEvents.DID_SET_WIDGET_DATE_RANGE, angular.copy(shared.widget));
            UIFactory.notify.showSuccess('Widget date range successfully overridden');
            closeModal();
        });
    }

    /**
     * @param [widget]
     */
    function clearDateRangeOverride(widget) {
        widget = widget || shared.widget;

        var metadata = widget.metadata;
        metadata.is_overriding_date_range = false;
        metadata.start_date_override = null;
        metadata.end_date_override = null;
        metadata.relative_date_range = RelativeDateRange.DEFAULT;
        metadata.compare_to_prior_period = false;
        metadata.comparison_start_date_override = null;
        metadata.comparison_end_date_override = null;
        metadata.comparison_relative_date_range  = RelativeDateRange.PRIOR_PERIOD;

        shared.state.isSaving = true;
        WidgetFactory.save(widget).then(function() {
            PubSub.emit($DateRangeEvents.DID_SET_WIDGET_DATE_RANGE, angular.copy(widget));
            UIFactory.notify.showSuccess('Widget date range successfully removed');
            closeModal();
        });
    }

    function closeModal() {
        UIFactory.hideModal(modalId);
        DateRangeFactory.destroyDatePicker($dateRangePicker);
        DateRangeFactory.destroyDatePicker($comparisonDateRangePicker);
        $timeout(function() {
            shared.state.includeModal = false;
            shared.state.isSaving = false;
        }, 1000, false);
    }
}
