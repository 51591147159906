'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import { Locale } from 'coreModules/shared/scripts/constants/language.constants';
import { MomentDateFormat } from 'coreModules/daterange/base/daterange.constants';
import { WeeklyStartDay } from "coreModules/design/widget/design.widget.constants";

angular.module('widget.serialchart.services', [])

    .factory('SerialChartFactory', SerialChartFactory);

/**
 *
 * @returns {{init: init}}
 * @constructor
 * @ngInject
 */
function SerialChartFactory(
    ChartFactory,
    AppFactory,
    ChartDataType,
    ChartPlotType,
    DateFormatType,
    DesignFactory,
    DrawOption,
    WidgetType,
    WidgetFactory,
    TimeGrouping,
    ChartAxisService,
    ColumnFormat,
    gettextCatalog,
    WidgetUtilService,
) {
    let ToolTipHelper = new ChartFactory.toolTipHelper();

    return {
        init: init,
        getDefaultTimeFormatType: getDefaultTimeFormatType,
        isDateFormatOverridden: isDateFormatOverridden
    };

    /**
     * @param chartOptions
     * @param columns
     */
    function init(chartOptions, columns, widget) {
        // No need to perform these operations for amCharts5 based charts
        if (WidgetUtilService.isAm5Chart(chartOptions.chartType, chartOptions.plot_type)) {
            return;
        }

        // Override chart balloon function for serial charts
        function balloonFunction(item, graph) {
            let result = graph.balloonText;
            result = result.replaceAll("[[category]]", item.dataContext.category);
            result = result.replace("[[color]]", graph.lineColor);

            return ToolTipHelper.replacePlaceholder(result, item, columns.selected, graph.label, widget.metadata);
        }

        function getGroupName(datum, priorDatum, index) {
            let datumObj = _.isEmpty(datum) ? priorDatum : datum;

            // Set the category name based on grouped column selection
            if (columns.grouped.length && !_.isNull(columns.grouped[options.chartDataIndex])) {
                let groupedColumn = columns.grouped[options.chartDataIndex];
                if (groupedColumn.groupby_field_format === ColumnFormat.FORMAT_DATETIME || groupedColumn.groupby_field_format === ColumnFormat.FORMAT_DATE) {
                    let dateFormat = WidgetFactory.getDateFormats(datumObj.timegroup || options.time_grouping).momentFormat;
                    const timeGroup =  datumObj.timegroup || options.time_grouping;
                    // Try to use formatted_log_date if feasible
                    if (datumObj['formatted_' + groupedColumn.groupby_name_field]) {
                        if (isDateFormatOverridden(options)) {
                            return WidgetFactory.formatDate(
                              datumObj['formatted_' + groupedColumn.groupby_name_field],
                              options[DrawOption.DATE_FORMAT_TYPE],
                              datumObj.timegroup);
                        } else {
                            if (isUnitFrequency(options.time_grouping)) {
                                return gettextCatalog.getString(datumObj['formatted_' + groupedColumn.groupby_name_field]);
                            } else if (timeGroup === TimeGrouping.GROUPING_YEARLY
                              || timeGroup === TimeGrouping.GROUPING_HOURLY
                              || timeGroup === TimeGrouping.GROUPING_HOURLY_AUDIENCE
                              || timeGroup === TimeGrouping.GROUPING_HOURLY_ADVERTISER) {
                                return datumObj['formatted_' + groupedColumn.groupby_name_field];
                            }

                            // French dateFormat is reversed, which means it can't be parsed in English otherwise
                            const parseFormat = dateFormat === MomentDateFormat.DAY_MONTH_YEAR
                                ? MomentDateFormat.MONTH_DAY_YEAR
                                : dateFormat;
                            // For time grouping is By Week/Day/Hour, need to reformat according to WidgetFactory.getDateFormats().momentFormat
                            return moment(datumObj['formatted_' + groupedColumn.groupby_name_field], parseFormat, Locale.EN)
                              .locale(moment.locale())
                              .format(dateFormat);
                        }
                    }
                    if (moment.unix(datumObj[groupedColumn.groupby_name_field]).utc().isValid()) {
                        // Some dates don't need to/can't be formatted (e.g. 'January')
                        if (options.isSample && options.time_grouping === TimeGrouping.GROUPING_DYNAMIC) {
                            dateFormat = MomentDateFormat.ISO;
                        }

                        let dateString = moment.unix(datumObj[groupedColumn.groupby_name_field]).utc()
                          .format(dateFormat);

                        if (isDateFormatOverridden(options)) {
                            return WidgetFactory.formatDate(dateString,
                              options[DrawOption.DATE_FORMAT_TYPE],
                              datumObj.timegroup || options.time_grouping);
                        }

                        return dateString;
                    } else {
                        return datumObj[groupedColumn.groupby_name_field];
                    }
                }
                else {
                    return datumObj[groupedColumn.groupby_name_field];
                }
            }
            else {
                // If no groupname, we will display the index of the data for the
                return datumObj && datumObj[index];
            }
        }

        function checkLineOrColumn(column) {
            let graphOption = angular.copy(graphOptions.column);

            if (chartOptions.chartType == WidgetType.LINECHART ||
                column.isAnnotation ||
                (chartOptions.chartType === WidgetType.COMBINATIONCHART &&
                    (options.line_columns[column.field] || column.isComparison && options.line_columns[_.replace(column.field, 'Comparison', '')])) // necessary for line charts prior period
            ) {
                graphOption = angular.copy(graphOptions.line);

                if (options.has_bullets) {
                    _.extend(graphOption, {
                        // Bullets are needed in order for click events. Set bulletAlpha to 0 instead of removing bullet
                        bulletAlpha: 1
                    });
                }

                if (column.isAnnotation) {
                    _.extend(graphOption, {
                        fillColors: options.baseColor,
                        lineColor: options.baseColor,
                        // Bullets are needed in order for click events. Set bulletAlpha to 0 instead of removing bullet
                        bulletSize: 25,
                        lineThickness: 0,
                        bulletAlpha: 0.7,
                        isAnnotation: true,
                        labelText: '[value]',
                        labelFunction: function(item, label) {
                            let annotationData = item.dataContext.annotations;
                            if (annotationData) {
                                if (annotationData.length > 1) {
                                    return _.first(annotationData).index + '...';
                                }
                                else {
                                    return _.first(annotationData).index;
                                }
                            }
                        },
                        labelPosition: 'middle',
                    });

                    // Check if annotations, add correct label to the options.graphs[index]??

                }

                if (chartOptions.chartType === WidgetType.COMBINATIONCHART) {
                    graphOption.stackable = false;
                }

                // Area fill line chart
                if (chartOptions.chartType === WidgetType.LINECHART && options.plot_type !== ChartPlotType.LINE) {
                    // If bullets are turned off, no color will display in the legend.
                    // We must use the default legend settings in order to display colors
                    options.legend.useGraphSettings = options.has_bullets;

                    _.extend(graphOption, {
                        fillAlphas: 0.7,
                        lineAlpha: 0
                    });
                }

                if (options.is_smoothed_line) {
                    _.extend(graphOption, {
                        type: 'smoothedLine'
                    });
                }
            }

            // Need logic for adding a bubble for annotations... Or create different function with default annotation logic??

            if (options.is_normalized) {
                graphOption.valueAxis = column.field;
            }
            else {
                graphOption.valueAxis = column.format == ColumnFormat.FORMAT_TIME ? 'date' : 'numeric';
            }

            graphOption.format = column.format;
            graphOption.precision = column.format == ColumnFormat.FORMAT_TIME ? 0 : column.precision;


            return graphOption;
        }

        function pushGraphItem(graphItem, index) {
            if ([ChartPlotType.STACKED, ChartPlotType.FULL_STACKED, ChartPlotType.CLUSTERED].includes(options.plot_type)) {
                options.graphs.push(graphItem);
            }
            else {
                options.graphs.unshift(graphItem);
            }
            // Set firstGraphValueField for graph balloon
            if (index == 1) {
                options.firstGraphValueField = graphItem.valueField;
            }
        }


        // Reduce double grouped chart data for dataProvider
        let isTimeGrouped = columns.grouped.length && columns.grouped[chartOptions.chartDataIndex] && columns.grouped[chartOptions.chartDataIndex].is_primary_date_field;

        /**
         *
         * @param graphName
         * @param graphOption
         * @constructor
         */
        let GraphItem = function(graphName, selectedColumn, graphOption) {

            let graphItem = _.extend({
                field: graphName,
                label: graphName,
                format: selectedColumn.format,
                valueAxis: graphName,
                showBalloon : options.has_tooltip,
                title: graphName,
                valueField: graphName,
                moving_y_axis: selectedColumn.moving_y_axis,
                animationPlayed: true,
            }, graphOption);


            // Set tooltips for graph
            if (options.has_tooltip) {
                graphItem.balloonText = '<text><strong>[[category]]</strong>' + ToolTipHelper.getDefaultText(null, graphName, graphName, graphOption.opacity) + '</text>';

                graphItem.balloonFunction = function(item, graph) {
                    let result = graph.balloonText;
                    result = result.replaceAll("[[category]]", item.dataContext.category);
                    result = result.replace("[[color]]", graph.lineColor);

                    return ToolTipHelper.replacePlaceholder(result, item, selectedColumn, graphName, widget.metadata);
                };
            }

            return graphItem;
        };

        let reduceChartData = function(chartData, hasComparison) {
            let graphNames = {};
            let primaryGroupedColumn = columns.grouped[0];
            let secondaryGroupedColumn = columns.grouped[1];

            function addDualGroupGraph(accum, datum, selectedColumn, isComparison, index, usedKeys) {
                // Create chart graphs for both current and comparison data (not yet set for doubleGrouped chart)
                let priorValueName;
                let currPeriodDatum = hasComparison ? datum.current_period : datum;
                let priorPeriodDatum = hasComparison && !_.isEmpty(datum.prior_period) ? datum.prior_period : null;
                let graphOption = checkLineOrColumn(selectedColumn);
                let valueName = !_.isNil(currPeriodDatum[secondaryGroupedColumn.groupby_name_field]) ? currPeriodDatum[secondaryGroupedColumn.groupby_name_field] : currPeriodDatum[secondaryGroupedColumn.groupby_id_field];
                if (priorPeriodDatum) {
                    priorValueName = priorPeriodDatum[secondaryGroupedColumn.groupby_name_field] ? priorPeriodDatum[secondaryGroupedColumn.groupby_name_field] : priorPeriodDatum[secondaryGroupedColumn.groupby_id_field];
                    if (!valueName) {
                        valueName = priorValueName
                    }
                }
                if (secondaryGroupedColumn.is_primary_date_field) {
                    const momentFormat = WidgetFactory.getDateFormats(currPeriodDatum.timegroup || priorPeriodDatum.timegroup).momentFormat;
                    valueName = moment.unix(valueName).utc().format(momentFormat);
                    priorValueName = priorValueName ? moment.unix(priorValueName).utc().format(momentFormat) : null;
                }

                if (!valueName) {
                    graphOption.visibleInLegend = false;
                }

                let graphName = `${_.isNil(valueName) ? gettextCatalog.getString('Undefined') : valueName}: ${selectedColumn.label}`;
                if (!graphNames[graphName]) {
                    graphNames[graphName] = graphName;
                    let graphItem = new GraphItem(graphName, selectedColumn, graphOption);
                    pushGraphItem(graphItem, index);
                }
                if (priorPeriodDatum && !graphNames[priorValueName]) {
                    graphNames[priorValueName] = priorValueName;

                    let comparisonValueName = priorValueName + ': ' + selectedColumn.label + ' Comparison';
                    let comparisonGraphOption = checkLineOrColumn(selectedColumn);
                    comparisonGraphOption.opacity = options.comparisonOpacity;
                    let graphColor = options.getPaletteColor(index);

                    let comparisonGraphItem = new GraphItem(comparisonValueName, selectedColumn, comparisonGraphOption);

                    // In wkhtmltopdf, stroke-opacity will not be applied correctly, therefore in order to apply
                    // lineAlpha, convert hex color to RGBA and assign to lineColor
                    comparisonGraphItem.lineColor = ChartFactory.hexToRGBA(graphColor, options.comparisonOpacity);
                    comparisonGraphItem.bulletAlpha = options.has_bullets ? options.comparisonOpacity : 0;
                    comparisonGraphItem.balloonFunction = balloonFunction;
                    comparisonGraphItem.isComparison = true;

                    pushGraphItem(comparisonGraphItem);
                }

                let datumName = valueName + ': ' + selectedColumn.label;
                let priorPeriodDatumName = priorPeriodDatum ? priorValueName + ': ' + selectedColumn.label + ' ' + 'Comparison' : null;
                let datumObject = _.isEmpty(currPeriodDatum) ? priorPeriodDatum : currPeriodDatum;
                let groupedField = primaryGroupedColumn.groupby_name_field;
                let primaryGroupedColumnValue = datumObject[groupedField];
                if (primaryGroupedColumn.groupby_field_format === ColumnFormat.FORMAT_DATETIME
                    && datumObject['formatted_' + primaryGroupedColumn.groupby_name_field]) {
                    groupedField = 'formatted_' + primaryGroupedColumn.groupby_name_field;
                }

                if (!isNaN(datumObject[groupedField])) {
                  datumObject[groupedField] = datumObject[groupedField] + " ";
                }

                if (!_.isNil(usedKeys[datumObject[groupedField]])) {
                    accum[usedKeys[datumObject[groupedField]]][datumName] = _.isNull(currPeriodDatum[selectedColumn.groupby_name_field]) ? 0 : currPeriodDatum[selectedColumn.groupby_name_field];
                    if (!_.isEmpty(priorPeriodDatumName)) {
                        accum[usedKeys[datumObject[groupedField]]][priorPeriodDatumName] = _.isNull(priorPeriodDatum[selectedColumn.groupby_name_field]) ? 0 : priorPeriodDatum[selectedColumn.groupby_name_field];
                    }
                }
                else {
                    let chartDatum = {};
                    chartDatum[datumName] = _.isNull(currPeriodDatum[selectedColumn.groupby_name_field]) ? 0 : currPeriodDatum[selectedColumn.groupby_name_field];
                    if (priorPeriodDatumName) {
                        chartDatum[priorPeriodDatumName] = _.isNull(priorPeriodDatum[selectedColumn.groupby_name_field]) ? 0 : priorPeriodDatum[selectedColumn.groupby_name_field];
                    }

                    chartDatum[primaryGroupedColumn.groupby_name_field] = primaryGroupedColumnValue;
                    chartDatum['timegroup'] = datumObject['timegroup'];

                    // If the value at firstGraphValueField is null, graph balloon will not be displayed
                    if (!_.isEqual(options.firstGraphValueField, datumName) && chartDatum.hasOwnProperty(options.firstGraphValueField)) {
                        chartDatum[options.firstGraphValueField] = 0;
                    } else if (options.allEmptyData) {
                        options.allEmptyData = false;
                    }

                    usedKeys[datumObject[groupedField]] = datumObject[groupedField];
                    accum[datumObject[groupedField]] = chartDatum;
                }
            }

            // Format/reduce double grouped data to single grouped data for chart
            options.mappedGraphIndeces = AppFactory.arrayToMemoizedObj(options.graphs, 'valueField');

            let usedKeys = {};
            return chartData.reduce(function(accum, datum, index) {
                _.each(selectedColumns, function(selectedColumn) {
                    addDualGroupGraph(accum, datum, selectedColumn, false, index, usedKeys);
                });
                return accum;
            }, {});
        };

        // Function to parse and format data to comply with series dataProvider structure
        const groupedFields = columns.grouped.map(({ field }) => field);
        let provideData = function(json, createChart) {
            let chartData = angular.copy(json.data);
            let hasComparisonData = json.has_comparison_data;

            let selectedColumns = columns.selected.filter(column => !groupedFields.includes(column.field));
            options.allEmptyData = true;
            let graphIterator = selectedColumns;


            if (options.is_multi_grouped) {
                // Format chartData appropriately
                chartData = reduceChartData(chartData, hasComparisonData);
                graphIterator = options.graphs;
            }
            // avoiding null values if a groupby value field is null, because null values causing n/a issues 
            columns.grouped.forEach(({ groupby_name_field }) => {
              chartData = chartData.filter(
                (chart) => chart[groupby_name_field] !== null
              );
            });
            
            // setGraph options
            // check if emptyData
            formatGraphs(graphIterator, chartData, hasComparisonData);

            // Map chartData values to 'category' property
            let mappedData =  [];
            let weekStartDayFormatCode = (AppFactory.getUser().getDefaultWeeklyStartDay() === WeeklyStartDay.MONDAY) ? 'E' : 'e';
            _.each(chartData, function(datum, index) {
                let currDatum = hasComparisonData && !options.is_multi_grouped
                    ? !_.isEmpty(datum.current_period) ? datum.current_period : {}
                    : datum;
                let priorDatum = hasComparisonData && !options.is_multi_grouped ? datum.prior_period : null;
                let validDatum = !_.isEmpty(currDatum) ? currDatum : priorDatum;
                let groupName = getGroupName(validDatum, priorDatum, index);

                // Map annotations out for chartData
                if (options.annotations && isTimeGrouped) {
                    // Find annotation that corresponds to date (if any)...
                    let annotationArr = _.filter(options.annotations, function(annotation) {
                        return (moment(groupName, options.dateFormats.momentFormat).isBetween(moment(annotation.formatted_start, options.dateFormats.momentFormat), moment(annotation.formatted_end, options.dateFormats.momentFormat))
                            || moment(groupName, options.dateFormats.momentFormat).isSame(moment(annotation.formatted_start, options.dateFormats.momentFormat))
                            || moment(groupName, options.dateFormats.momentFormat).isSame(moment(annotation.formatted_end, options.dateFormats.momentFormat)))
                    });

                    if (!_.isEmpty(annotationArr)) {
                        // Add annotation graph if necessary
                        if (options.annotations && options.annotations.length && !options.annotationsGraph) {
                            options.annotationsGraph = true;
                            options.graphs.push(options.annotationGraph);
                        }
                        let maxValue;

                        // Find max of the display selected columns value, so that annotation bubble will display on top
                        if (options.is_multi_grouped) {
                            maxValue = _.max(_.map(validDatum));
                        }
                        else if (options.is_normalized) { // use the first selected column graph
                            maxValue = _.toNumber(validDatum[_.first(selectedColumns).field]);
                        }
                        else {
                            maxValue = _.max(_.map(selectedColumns, function(column) {
                                return _.toNumber(validDatum[column.field]);
                            }));
                        }
                        // annotation value can be at the bottom of chart
                        //      datum['annotationValue'] = 0;
                        // or on top of the selected column with the highest value
                        currDatum['annotationValue'] = maxValue;


                        // Set graph label text with the annotations display order
                        _.each(annotationArr, function(annotation) {
                            if (currDatum['annotations']) {
                                currDatum['annotations'].push(annotation);
                            }
                            else {
                                currDatum['annotations'] = [annotation];
                            }
                        });
                    }
                }
                currDatum['category'] = groupName || groupName === 0 ? groupName : 'N/A';

                // If widget is time grouped, we can not display at the mappedDataObj index
                if (hasComparisonData && !options.is_multi_grouped) {
                    let selectedColumnsObj = AppFactory.arrayToMemoizedObj(selectedColumns, 'field');
                    _.each(priorDatum, function(comparisonColumn, key) {
                        // Adding this property to deal with the issue of sorting date,
                        // (GROUPING_MONTH_OF_YEAR, GROUPING_DAY_OF_WEEK, etc)
                        if (isTimeGrouped) {
                            currDatum.hasPriorDatum = true;
                        }

                        if (datum.current_period && datum.prior_period) {
                            currDatum[key + (options.is_multi_grouped ? '' : 'Comparison')] = comparisonColumn;
                        }
                        else {
                            // Prepend chart data with comparison data
                            currDatum[key + (options.is_multi_grouped ? '' : 'Comparison')] = comparisonColumn;
                            currDatum['category'] = groupName || groupName === 0 ? groupName : 'N/A';
                        }
                        if (options.allEmptyData && !_.isUndefined(selectedColumnsObj[key])
                            && _.toNumber(comparisonColumn) > 0) {
                            options.allEmptyData = false;
                        }
                    });
                }


                // Only if this field is a selected column and comparison value is greater than 0
                mappedData.push(currDatum);
            });

            // Need to sort the chart data by the formatted day of week
            if (isTimeGrouped && options.time_grouping == TimeGrouping.GROUPING_DAY_OF_WEEK) {
                // Chart data will be returned sorted by the unix time datestamp
                mappedData = _.sortBy(mappedData, function(data, log_date) {
                    if (!_.isUndefined(log_date)) {
                        data.log_date = log_date;
                    }

                    return moment.unix(data.log_date).utc().format(weekStartDayFormatCode);
                })
            }

            // Handle cases for data if time grouping is unit frequency
            if (!options.isSample && isTimeGrouped && hasComparisonData) {
                mappedData = _.sortBy(mappedData, function(data) {
                    let key = 'log_date';
                    if (data.hasPriorDatum) {
                        key += (options.is_multi_grouped ? '' : 'Comparison');
                    }
                    let unixTimeStamp = data[key];
                    let order;
                    switch (options.time_grouping) {
                        case TimeGrouping.GROUPING_MONTH_OF_YEAR:
                            order = _.toNumber(moment.unix(unixTimeStamp).utc().format('M'));
                            break;
                        case TimeGrouping.GROUPING_DAY_OF_MONTH:
                            order = _.toNumber(moment.unix(unixTimeStamp).utc().format('D'));
                            break;
                        case TimeGrouping.GROUPING_DAY_OF_WEEK:
                            order = _.toNumber(moment.unix(unixTimeStamp).utc().format(weekStartDayFormatCode));
                            break;
                        case TimeGrouping.GROUPING_HOUR_OF_DAY:
                            order = _.toNumber(moment.unix(unixTimeStamp).utc().format('H'));
                            break;
                    }
                    return order;
                });
            }

            // Map comparison data to mappedData
            if (hasComparisonData && !options.is_multi_grouped) {
                // Need to format the colors of comparison graphs
                // Note: Comparison data is not correctly displayed in export from export builder
                _.each(columns.selected, function(selectedColumn, index) {
                    if (options.mappedGraphIndeces[selectedColumn.field + 'Comparison']) {
                        let graphIndex = options.mappedGraphIndeces[selectedColumn.field + 'Comparison'].index;
                        // In wkhtmltopdf, stroke-opacity will not be applied correctly, therefore in order to apply
                        // lineAlpha, convert hex color to RGBA and assign to lineColor
                        options.graphs[graphIndex].lineColor = ChartFactory.hexToRGBWithTransparency(
                            options.getPaletteColor(index),
                            options.comparisonOpacity
                        );
                        options.graphs[graphIndex].bulletAlpha = options.has_bullets ? 1 : 0;
                        if (options.graphs[graphIndex].type == 'column') {
                            options.graphs[graphIndex].fillColors = options.getPaletteColor(index);
                            options.graphs[graphIndex].fillAlphas = options.comparisonOpacity;
                        }
                        if (options.is_normalized) {
                            options.graphs[graphIndex].valueAxis = options.graphs[graphIndex].valueAxis.substr(0, options.graphs[graphIndex].valueAxis.indexOf('Comparison'));
                        }
                    }
                });
            }

            // If no chart data, run the renderCallback in order to display the no data placeholder
            if (options.allEmptyData) {
                // options.dataProvider is set for options.renderCallback.
                // A truthy value for 'dataprovider' is needed to update loading state appropriately.
                options.dataProvider = {};
                options.renderCallback(options);
            }
            else {
                // options.dataProvider is set for options.renderCallback.
                // A truthy value for 'dataprovider' is needed to update loading state appropriately.
                options.dataProvider = mappedData;
                createChart();
            }
        };

        // Default options for all parameters
        let defaultOptions = {
            annotationGraph: {
                bullet: 'round',
                bulletAlpha: 0.7,
                bulletSize: 25,
                dateFormat: 'JJ:NN:SS',
                fillColors: chartOptions.baseColor,
                id: 'annotation', // id field actually added as class 'amcharts-graph-[id]'
                labelFunction: function(item, label) {
                    let annotationData = item.dataContext.annotations;
                    if (annotationData) {
                        if (annotationData.length > 1) {
                            return (_.first(annotationData).index + 1) + '...';
                        }
                        else {
                            return _.first(annotationData).index + 1;
                        }
                    }
                },
                labelPosition: 'middle',
                labelText: '[value]',
                lineAlpha: 2,
                lineColor: chartOptions.baseColor,
                lineThickness: 0,
                isAnnotation: true,
                showBalloon: false,
                title: 'Annotations',
                type: 'line',
                valueAxis: 'numeric',
                valueField: 'annotationValue',
            },
            categoryAxis: {
                autoWrap: true,
                dateFormat: 'JJ:NN:SS',
                tickLength: 5,
                labelsEnabled: !chartOptions.isThumbPreview
            },
            getGuideDate: getGuideDate,
            clickType: 'clickGraphItem',
            provideData: provideData,
            sequencedAnimation: false,
            type: ChartDataType.SERIAL,
            zoomOutText: '',
        };

        let options = _.extend(chartOptions, defaultOptions);

        //
        // OPTION OVERRIDES
        //
        options.angle = options.angle || 0;
        options.depth3D = options.depth || 0;

        if (options.legend) {
            options.legend.useGraphSettings = true;
            options.legend.valueFunction = function (item) {
                if (item.graph) {
                    let value = item.values.value;
                    if (_.isUndefined(value) || _.isNull(value)) {
                        return '';
                    }
                    if (item.graph.format == ColumnFormat.FORMAT_TIME || item.graph.format == ColumnFormat.FORMAT_DATETIME) {
                        value = item.dataContext[item.graph.valueField];
                    }
                    return ChartFactory.formatLegendValue(value, item.graph.precision, item.graph.format);
                }
                return '';
            };
            if (options.plot_type === ChartPlotType.STACKED || options.plot_type === ChartPlotType.FULL_STACKED) {
                options.legend.reversedOrder = true;
            }
        }

        let labelOffsetCounter = 1;
        let labelFunction = function (item, value){
            if (chartOptions.chartType === WidgetType.COMBINATIONCHART && item.graph.type === ChartPlotType.LINE && item.index === 0 && labelOffsetCounter <= 3) {
                item.graph.labelOffset = 10 * labelOffsetCounter;
                labelOffsetCounter++;
            }
            return item.graph.format === ColumnFormat.FORMAT_PERCENT ? `${value}%` : value;
        }

        //
        // GRAPH OPTIONS
        //

        let graphOptions = {
            line: {
                bullet: 'round',
                bulletAlpha: 0,
                dateFormat: "JJ:NN:SS",
                labelText: options[DrawOption.SHOW_METRIC_LABELS] ? '[[value]]' : null,
                labelFunction: labelFunction,
                showAllValueLabels: options[DrawOption.SHOW_METRIC_LABELS],
                lineAlpha: 2,
                lineThickness: 2,
                type: 'line'
            },
            column: {
                dateFormat: "JJ:NN:SS",
                fillAlphas: 1,
                type: 'column',
                labelText: options[DrawOption.SHOW_METRIC_LABELS] ? '[[value]]' : null,
                labelFunction: labelFunction,
                showAllValueLabels: options[DrawOption.SHOW_METRIC_LABELS]
            }
        };

        let selectedColumns = _.differenceBy(columns.selected, columns.grouped, 'field');

        // Each annotation date needs to be formatted to the current time grouping (if any)
        function formatAnnotationDates() {
            _.each(options.annotations, function(annotation, index) {
                if (annotation.start_date) {
                    annotation.formatted_start = moment.unix(annotation.start_date).utc().format(options.dateFormats.momentFormat);
                    annotation.formatted_end = moment.unix(annotation.end_date).utc().format(options.dateFormats.momentFormat);
                }
                annotation.index = index;
            });
        }

        // Set graph colors
        // Check if empty data for selected iterator
        function formatGraphs(iterator, chartData, hasComparison) {
            // Iterator will be either selected columns (single grouped chart),
            // Or graphs built based on chartData (double grouped chart)
            let mappedGraphNames = AppFactory.arrayToMemoizedObj(options.graphs, 'valueField');
            let mappedValueAxes = AppFactory.arrayToMemoizedObj(options.valueAxes, 'id');
            _.each(iterator, function(column, index) {
                let graphItem = options.graphs[mappedGraphNames[column.field].index];
                // Set graph colors based on index
                if (!graphItem.isComparison) {
                    graphItem.fillColors = options.getPaletteColor(index);
                    graphItem.lineColor = options.getPaletteColor(index);
                }

                let minValue = 0;

                _.each(chartData, function(datum) {
                    let currDatum = hasComparison && !options.is_multi_grouped
                        ? !_.isEmpty(datum.current_period) ? datum.current_period : {}
                        : datum;
                    let priorDatum = hasComparison && !options.is_multi_grouped ? datum.prior_period : null;
                    let value = currDatum[column.field];
                    let priorValue = priorDatum ? priorDatum[column.field] : null;

                    // Check if chart has empty data
                    // Need to account for displaying negative values only?
                    if (options.allEmptyData && (_.toNumber(value) > 0 || column.format == ColumnFormat.FORMAT_TIME)) {
                        options.allEmptyData = false;
                    }

                    if (column.format == ColumnFormat.FORMAT_TIME) {
                        currDatum[column.field] = moment.duration(value).asSeconds();
                        if (priorValue) {
                            priorDatum[column.field] = moment.duration(priorValue).asSeconds();
                        }
                    }
                    else {
                        if (value < minValue) {
                            minValue = parseInt(value);
                        }
                    }
                });

                if (options[DrawOption.IS_ZERO_SCALED]) {
                    _.each(options.valueAxes, function(valueAxis) {
                        valueAxis.minimum = minValue; // 0 or negative
                    });
                }

                if (ChartAxisService.moveSingleYAxis(options)) {
                    options.valueAxes[0].position = ChartAxisService.getYAxisPosition(options, true);
                } else if (ChartAxisService.moveAllYAxes(options)) {
                    if (options.is_multi_grouped) {
                        mappedValueAxes[column.valueAxis].position = ChartAxisService.getYAxisPosition(options, column.moving_y_axis);
                    } else {
                        mappedValueAxes[column.field].position = ChartAxisService.getYAxisPosition(options, column.moving_y_axis);
                    }
                }
            });
        }

        function addChartCursor() {
            let chartCursor = null;

            // this uses AmCharts' built in listener to grab the graph/chart information through the returned event
            options.chartCursor.listeners.push({
                event : 'changed',
                method : function (event) {
                    chartCursor = event;
                }
            });

            // This is a Javascript event handler, which has access to AmCharts info through chartCursor
            // Note: Disables drag/highlight to zoom ability...
            options.chartCursor.handleMouseDown = function (event) {

                // if chartCursor has not been set (through the 'changed' listener above) or if the click is not on the chartCursor rectangle itself, return
                if (_.isNull(chartCursor) || event.target.tagName !== 'rect') {
                    return;
                }

                if (!_.isUndefined(chartCursor.mostCloseGraph)) {
                    options.drillDown(event, chartCursor.mostCloseGraph, chartCursor.mostCloseGraph.data[chartCursor.index]);
                }
            };
        }

        /**
         * Tell us if time grouping is unit frequency
         * @param timeGrouping
         * @returns {boolean}
         */
        function isUnitFrequency(timeGrouping) {
            return timeGrouping === TimeGrouping.GROUPING_MONTH_OF_YEAR
                || timeGrouping === TimeGrouping.GROUPING_DAY_OF_MONTH
                || timeGrouping === TimeGrouping.GROUPING_DAY_OF_WEEK
                || timeGrouping === TimeGrouping.GROUPING_HOUR_OF_DAY;
        }

        //
        // Set graph options
        //
        options.mappedGraphIndeces = {};
        if (options.annotations && options.annotations.length) {
            formatAnnotationDates();
        }
        if (options.is_multi_grouped) {
            options.graphs = [];

            // ChartCursor is used for our custom balloon text not needed for double grouped chart
            delete options.chartCursor;
        }
        else {
            // Add 'comparison' columns to selected columns, so that chart will correctly display comparison data
            if (ChartFactory.isComparisonEnabled(options)) {

                _.each(columns.selected, function(selectedColumn) {
                    let foundIndex = _.findIndex(selectedColumns, ['field', selectedColumn.field]);
                    let copiedSelectedColumn = angular.copy(selectedColumn);
                    copiedSelectedColumn.field += 'Comparison';
                    copiedSelectedColumn.label += ' ' + gettextCatalog.getString('Comparison');
                    copiedSelectedColumn.isComparison = true;
                    selectedColumns.splice(foundIndex, 0, copiedSelectedColumn);
                });
            }
            // Reverse the order of chart graphs, so that metrics stack in the correct order when stacked
            if (options.plot_type === ChartPlotType.STACKED
                || options.plot_type === ChartPlotType.FULL_STACKED) {
                selectedColumns = selectedColumns.reverse();
            }

            options.graphs = _.map(selectedColumns, function(column, index) {

                let graphOption = checkLineOrColumn(column);
                let newGraph = _.extend({
                    connect: false, // TA-12680 break line graph if value is null
                    precision: column.precision,
                    showBalloon : false,
                    title: column.label,
                    valueField: column.field,
                    animationPlayed: true,
                    index: index
                }, graphOption);

                options.mappedGraphIndeces[newGraph.valueField] = newGraph;

                return newGraph;
            });

            addChartCursor();

        }


        if (options.has_tooltip && !options.is_multi_grouped) {
            let copiedColumns = angular.copy(columns);
            copiedColumns.selected = _.differenceBy(columns.selected, columns.grouped, 'field');
            ToolTipHelper.setToolTip(options, copiedColumns, false, widget.metadata);
        } else {
            columns.selected = AppFactory.arrayToMemoizedObj(columns.selected, 'field');
            columns.selected = Object.values(columns.selected || []);
        }

        // Reorder chart graph to make sure that line chart is always on the top (TA-19779)
        // also preserve index (TA-19971)
        // reference: https://www.amcharts.com/kbase/setting-order-graphs-via-config/
        AmCharts.addInitHandler(function(chart) {
            chart.graphs.sort(function(a, b) {
                if ((a.type === 'line' && b.type === 'line')
                    || (a.type !== 'line' && b.type !== 'line')) {
                    return a.index > b.index ? 1 : -1;
                } else if (a.type === 'line') {
                    return 1;
                } else {
                    return -1;
                }
            });
        }, ["serial"]);

        if (options?.graphs && options.is_normalized) {  // this will work only normalized data is enabled, so the code won't break
            let axisNew = options.valueAxes.filter(column => !groupedFields.includes(column.id));
            if(options?.graphs[0]?.balloonText){
                let valueAxisCorrectColor = options.graphs[0].balloonText.split('background-color:'); // fetching the actual color scheme from ballon text
                valueAxisCorrectColor.shift();
                let colorCode = valueAxisCorrectColor.map(val => val.substring(0, 7));
                let colorCodeObj = {};
                _.each(colorCode, (val) => {
                    colorCodeObj[val] = ''
                });
                colorCode = Object.keys(colorCodeObj);
                _.each(colorCode, function(val, index){
                    if(axisNew[index])
                        axisNew[index].titleColor = val;
                });
                options.valueAxes = axisNew;
            }
        }
        ChartFactory.makeChart(options, columns);
    }

    function getGuideDate(grouping, time) {
        switch (grouping) {
            case TimeGrouping.GROUPING_YEARLY:
                return {
                    end: moment(time).add(1, 'year').subtract(1, 'day').unix(),
                    chartEnd: moment(time).add(1, 'year')
                };
            case TimeGrouping.GROUPING_MONTHLY:
            case TimeGrouping.GROUPING_MONTH_OF_YEAR:
                return {
                    end: moment(time).add(1, 'month').subtract(1, 'day').unix(),
                    chartEnd: moment(time).add(1, 'month')
                };
            case TimeGrouping.GROUPING_WEEKLY:
                return {
                    end: moment(time).add(1, 'week').subtract(1, 'day').unix(),
                    chartEnd: moment(time).add(1, 'week')
                };
            case TimeGrouping.GROUPING_DAILY:
            case TimeGrouping.GROUPING_DAY_OF_MONTH:
            case TimeGrouping.GROUPING_DAY_OF_WEEK:
                return {
                    end: moment(time).add(1, 'day').subtract(1, 'second').unix(),
                    chartEnd: moment(time).add(1, 'day')
                };
            case TimeGrouping.GROUPING_HOURLY:
            case TimeGrouping.GROUPING_HOUR_OF_DAY:
                return {
                    end: moment(time).add(1, 'hour').subtract(1, 'second').unix(),
                    chartEnd: moment(time).add(1, 'hour')
                };
        }
    }

    /**
     * Helper function to return default time format type
     * @param drawOptions
     * @returns {string}
     */
    function getDefaultTimeFormatType(drawOptions) {
        return _.isNil(drawOptions[DrawOption.DATE_FORMAT_TYPE])
            ? DateFormatType.DEFAULT
            : drawOptions[DrawOption.DATE_FORMAT_TYPE];
    }

    /**
     * Helper function to tell us if date format has been overridden
     * @param options
     * @returns {boolean}
     */
    function isDateFormatOverridden(options) {
        // If it is unit frequency, we don't allow to override date format
        return options[DrawOption.DATE_FORMAT_TYPE] !== DateFormatType.DEFAULT
            && !isUnitFrequency(options.time_grouping);
    }

    /**
     * Tell us if time grouping is unit frequency
     * @param timeGrouping
     * @returns {boolean}
     */
    function isUnitFrequency(timeGrouping) {
        return timeGrouping === TimeGrouping.GROUPING_MONTH_OF_YEAR
            || timeGrouping === TimeGrouping.GROUPING_DAY_OF_MONTH
            || timeGrouping === TimeGrouping.GROUPING_DAY_OF_WEEK
            || timeGrouping === TimeGrouping.GROUPING_HOUR_OF_DAY;
    }
}
