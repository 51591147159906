import { cloneDeep, differenceBy, groupBy, isEmpty, map } from 'lodash';
import { getDateFormatFromTimeGrouping } from '@/modules/ta/widget/helper';
import { PlotType } from '@/modules/ta/widget/widget.constants';
import ChartWidgetRenderService from '@/modules/ta/widget/services/types/ChartWidgetRenderService';
import { categoryAxesIndexOnChartClicked } from '@/modules/core/charts/events.helper';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { color, isDateAxis } from '@/modules/core/charts/am5/charts.helper';
import { BulletConfig } from '@/modules/core/charts/am5/base/models/BulletConfig';
import {
  BulletShapes,
  Constant,
  LineTypes,
  USE_DATE_AXIS_AS_CATEGORY,
  YAxisPosition,
} from '@/modules/core/charts/am5/charts.constants';
import moment from 'moment';
import { BenchmarkSeries } from '@/modules/core/charts/am5/base/models/BenchmarkSeries';

export default class SerialWidgetRenderService extends ChartWidgetRenderService {
  currentChartData = [];

  /**
   *
   * @type {[Number]}
   */
  layered_columns_width = [];

  /**
   * @type {?boolean}
   */
  _isCurrentGroupedByColumnIsString = null;

  /**
   * @param datum
   * @returns {any}
   * @private
   */
  _addComparisonSuffixToKeys(datum) {
    return Object.fromEntries(
      Object.entries(datum).map(([key, value]) => [this.appendComparisonSuffixToField(key), value])
    );
  }

  /**
   * @returns {boolean}
   */
  isInlineDrillDown() {
    return this.isMultiGrouped() && this.getGroupedColumns().length > 1;
  }

  isCurrentGroupedByColumnAsString() {
    if (this._isCurrentGroupedByColumnIsString === null) {
      this._isCurrentGroupedByColumnIsString = [
        ColumnFormat.FORMAT_STRING,
        ColumnFormat.FORMAT_CALLBACK,
      ].includes(this.getCurrentGroupByColumn()?.format);
    }
    return this._isCurrentGroupedByColumnIsString;
  }

  /**
   * @param datum
   * @param index
   * @param isPriorPeriod
   * @returns {{log_date}|*}
   * @private
   */
  _preProcessDatum(datum, index, isPriorPeriod = false) {
    if (this._isCurrentGroupedColumnDate()) {
      const groupByColumnField = this.getCurrentGroupByField();
      if (USE_DATE_AXIS_AS_CATEGORY) {
        datum[groupByColumnField] = this._getVisibleDate(datum, groupByColumnField);
      } else {
        datum[groupByColumnField] = moment.unix(datum[groupByColumnField]).toDate().getTime();
      }
    }

    if (this.isCurrentGroupedByColumnAsString()) {
      const currentGroupByField = this.getCurrentGroupByField();
      const checkData = isPriorPeriod ? this.currentPeriodData : this.priorPeriodData;

      if (!datum[currentGroupByField]) {
        const dataToUse = checkData[index] || this.currentPeriodData[index];
        datum[currentGroupByField] = dataToUse[currentGroupByField] || Constant.NOT_APPLICABLE;
      }
    }

    if (isPriorPeriod) {
      datum = this._addComparisonSuffixToKeys(datum);
    }
    return datum;
  }

  /**
   * @param newDatum
   * @param datum
   * @param newNonGroupedColumnsMap
   */
  addInlineDrillDownMetricsToDatum(newDatum, datum, newNonGroupedColumnsMap) {
    const secondGroupedField = this.getNextGroupByField();
    this.nonGroupedColumns.forEach((column) => {
      const field = `${datum[secondGroupedField]}_${column.field}`;
      const label =
        secondGroupedField === Constant.LOG_DATE
          ? `${this._getVisibleDate(datum)}: ${column.label}`
          : `${datum[secondGroupedField]}: ${column.label}`;
      if (!newNonGroupedColumnsMap[field]) {
        const newColumn = cloneDeep(column);
        newColumn.field = field;
        newColumn.label = label;
        newNonGroupedColumnsMap[field] = newColumn;
      }
      newDatum[field] = datum[column.field];
    });
  }

  /**
   * @param value
   * @returns {Array}
   */
  setNonGroupedColumns(value = null) {
    if (value) {
      this.nonGroupedColumns = value;
    }
    if (this.nonGroupedColumns) {
      return this.nonGroupedColumns;
    }

    const widget = this.getWidget();
    if (widget) {
      const { selected = [], grouped = [] } = widget.metadata.data_columns;

      // eslint-disable-next-line tap/no-raw-text-js
      this.nonGroupedColumns = differenceBy(selected, grouped, 'field').filter(
        (column) => column.isFormatNumerical() || column.isFormatTime()
      );
    }
  }

  /**
   * @param data
   * @param newNonGroupedColumnsMap
   * @returns {unknown[]}
   */
  preProcessDataForInlineDrillDown(data, newNonGroupedColumnsMap) {
    const firstGroupedField = this.getCurrentGroupByField();
    const groupedByFirstGroupedColumn =
      firstGroupedField === Constant.LOG_DATE
        ? Object.entries(
            groupBy(
              data,
              (datum) => datum[Constant.FORMATTED + firstGroupedField] || datum[firstGroupedField]
            )
          ).reduce((result, value) => {
            const [, cellData] = value;
            result[cellData[0][firstGroupedField]] = cellData;
            return result;
          }, {})
        : groupBy(data, (datum) => datum[firstGroupedField]);
    delete groupedByFirstGroupedColumn.undefined;
    return map(groupedByFirstGroupedColumn, (values, key) => {
      const newDatum = { [firstGroupedField]: key };
      values.forEach((datum) =>
        this.addInlineDrillDownMetricsToDatum(newDatum, datum, newNonGroupedColumnsMap)
      );
      return newDatum;
    });
  }

  /**
   * @param data
   * @param newNonGroupedColumnsMap
   * @param isPriorPeriod
   * @returns {({log_date}|*)[]}
   */
  preProcessData(data, newNonGroupedColumnsMap, isPriorPeriod = false) {
    let newData = this.isInlineDrillDown()
      ? this.preProcessDataForInlineDrillDown(data, newNonGroupedColumnsMap)
      : data;
    newData = newData.map((datum, index) =>
      this._preProcessDatum(datum ?? {}, index, isPriorPeriod)
    );

    // ranking metrics in area fill mode will by default fill from the line upwards to where amcharts
    // believes the origin is
    // for bar charts we have to do some manual graphics hacking but for area charts we need
    // to apply "openYValue" fields which tells the chart where to draw the line to
    // in this case we want it filling down to the bottom axis so we need to manually set
    // the maximum axis value and then tell the chart to fill to that point
    if (newData.length && this.isFillAreaAboveLine() && this.hasRankingMetricsSelected()) {
      const rankingMetrics = this.getRankingMetricColumns();
      const maxRankingMetrics = [];
      rankingMetrics.forEach((metric) => {
        const metricField = this.getMetricFieldName(metric.field, isPriorPeriod);
        const maxForField = [];
        newData.forEach((datum) => {
          maxForField.push(datum[metricField]);
        });

        maxRankingMetrics.push(Math.max(...maxForField));
      });

      const maxValue = Math.max(...maxRankingMetrics);
      rankingMetrics.forEach((metric) => {
        newData.forEach((datum) => {
          const openValueFieldName = this.getMetricFieldName(metric.field, isPriorPeriod, true);
          datum[openValueFieldName] = maxValue;
        });
      });
    }

    return newData;
  }

  getMetricFieldName(fieldName, isPriorPeriod = false, includeOpenValue = false) {
    if (isPriorPeriod) {
      // eslint-disable-next-line tap/no-raw-text-js
      fieldName += '_comparison';
    }

    if (includeOpenValue) {
      return `${fieldName}_${Constant.OPEN_VALUE_FIELD_NAME}`;
    }

    return fieldName;
  }

  /**
   * @param currentPeriodData
   * @param priorPeriodData
   * @returns {*}
   * @private
   */
  _getComparisonChartData(currentPeriodData, priorPeriodData) {
    const maxIndex =
      currentPeriodData.length >= priorPeriodData.length
        ? currentPeriodData.length
        : priorPeriodData.length;
    const comparisonChartData = [];
    for (let index = 0; index < maxIndex; index++) {
      const currentDatum = currentPeriodData[index];
      const priorDatum = priorPeriodData[index];

      const extras = {};
      if (USE_DATE_AXIS_AS_CATEGORY) {
        // eslint-disable-next-line tap/no-raw-text-js
        let categoryValue = currentDatum ? currentDatum[this.getCurrentGroupByField()] : 'NA';
        if (this._isCurrentGroupedColumnDate() && priorDatum?.log_date_comparison) {
          if (categoryValue !== '') {
            categoryValue = `${categoryValue}\nvs ${priorDatum.log_date_comparison}`;
          } else {
            categoryValue = `(${priorDatum.log_date_comparison})`;
          }
        } else if (!categoryValue && priorDatum[`${this.getCurrentGroupByField()}_comparison`]) {
          categoryValue = priorDatum[`${this.getCurrentGroupByField()}_comparison`];
        }
        extras[this.getCurrentGroupByField()] = categoryValue;
      }

      comparisonChartData.push({
        ...currentDatum,
        ...priorDatum,
        ...extras,
      });
    }
    return comparisonChartData;
  }

  /**
   * @returns {*}
   */
  getChartData() {
    if (this.currentChartData.length) {
      return this.currentChartData;
    }

    const newNonGroupedColumnsMap = {};
    this.currentChartData = this.preProcessData(this.currentPeriodData, newNonGroupedColumnsMap);
    if (this.hasComparison()) {
      const priorPeriodData = this.preProcessData(
        this.priorPeriodData,
        newNonGroupedColumnsMap,
        true
      );
      this.currentChartData = this._getComparisonChartData(this.currentChartData, priorPeriodData);
    }
    if (!isEmpty(newNonGroupedColumnsMap)) {
      this.setNonGroupedColumns(Object.values(newNonGroupedColumnsMap));
    }

    return this.currentChartData;
  }

  /**
   * @param field
   * @returns {string}
   */
  appendComparisonSuffixToField(field) {
    return `${field}_comparison`;
  }

  /**
   * @param field
   * @returns {*}
   */
  getColumnFromField(field) {
    return this.nonGroupedColumns.find((column) =>
      [column.field, this.appendComparisonSuffixToField(column.field)].includes(field)
    );
  }

  /**
   * @param label
   * @returns {string}
   */
  appendComparisonSuffixToLabel(label) {
    return `${label} Comparison`;
  }

  /**
   * @param column
   * @param columnIndex
   * @returns {{showLabels: *, tooltipEnabled: *, hideGridLines: boolean}}
   */
  // eslint-disable-next-line no-unused-vars
  getCommonAxisProperties(column, columnIndex) {
    const drawOptions = this.getDrawOptions();

    return {
      showLabels: drawOptions.show_labels,
      hideGridLines: drawOptions.hide_grid_lines,
      tooltipEnabled: false,
    };
  }

  /**
   * @returns {any}
   */
  getCategoryAxisProperties(category) {
    const drawOptions = this.getDrawOptions();
    const widget = this.getWidget();
    const column = this.getCurrentGroupByColumn();

    return {
      opposite: false,
      category,
      inversed: drawOptions.is_rotated,
      isValueDate: false,
      dateFormat: getDateFormatFromTimeGrouping(
        widget.metadata.time_grouping,
        drawOptions.date_format_type
      ),
      axisLabelLineHeight: this.hasComparison() && this._isCurrentGroupedColumnDate() ? 3.5 : 1.5,
      dataItemFormat: this.modifiedDataItemFormat(column?.groupby_field_format),
    };
  }

  modifiedDataItemFormat(dataItemFormat) {
    return isDateAxis(dataItemFormat) && USE_DATE_AXIS_AS_CATEGORY
      ? ColumnFormat.FORMAT_STRING
      : dataItemFormat;
  }

  /**
   * @param column
   * @param columnIndex
   * @returns {{}}
   */
  getValueAxisProperties(column, columnIndex) {
    const { is_zero_scaled } = this.getDrawOptions();
    const zeroScaledMinParam = is_zero_scaled ? 0 : null;

    return {
      min: this.isFullStacked() ? 0 : zeroScaledMinParam,
      max: this.isFullStacked() ? 100 : null,
      opposite: this.isYAxisMoved(column),
      isRankingMetric: this.isRankingMetric(column),
      titleText: column?.label,
      titleColor: this.getChartPalette(columnIndex),
      extraMax: this.isFullStacked() ? 0 : 0.1,
      dataItemFormat: column?.format,
    };
  }

  /**
   * @returns {{}}
   */
  getCategoryAxis(category) {
    return {
      ...this.getCommonAxisProperties(),
      ...this.getCategoryAxisProperties(category),
    };
  }

  /**
   * @param column
   * @param columnIndex
   * @returns {{min: (number|null), showLabels: *, tooltipEnabled: *, hideGridLines: boolean}}
   */
  getValueAxis(column, columnIndex) {
    return {
      ...this.getCommonAxisProperties(column, columnIndex),
      ...this.getValueAxisProperties(column, columnIndex),
    };
  }

  /**
   * @returns {{}[]}
   */
  getChartCategoryAxis() {
    const axes = [this.getCategoryAxis(this.getCurrentGroupByField())];
    if (!USE_DATE_AXIS_AS_CATEGORY) {
      if (this._isCurrentGroupedColumnDate()) {
        if (this.hasComparison()) {
          axes.push(
            this.getCategoryAxis(this.appendComparisonSuffixToField(this.getCurrentGroupByField()))
          );
        }
      }
    }
    return axes;
  }

  /**
   * @returns {*}
   */
  getChartValueAxis() {
    if (this.getDrawOptions().is_normalized) {
      return this.nonGroupedColumns.map((column, columnIndex) =>
        this.getValueAxis(column, columnIndex)
      );
    }
    if (this.isContainDurationMetric()) {
      const axes = [];
      const durationIndex = this.getDurationColumnIndex();
      const nonDurationIndex = this.getNonDurationColumnIndex();

      if (durationIndex !== -1) {
        axes.push(this.getValueAxis(this.nonGroupedColumns[durationIndex], durationIndex));
      }

      if (nonDurationIndex !== -1) {
        axes.push(this.getValueAxis(this.nonGroupedColumns[nonDurationIndex], nonDurationIndex));
      }

      if (axes.length === 2 && durationIndex > nonDurationIndex) {
        // Swap axes array items and return
        const [duration, noDuration] = axes;
        return [noDuration, duration];
      }

      return axes;
    }
    if (this.hasRankingMetricsSelected()) {
      return this.getRankingMetricAxes();
    }
    return [this.getValueAxis(this.nonGroupedColumns[0], 0)];
  }

  getRankingMetricAxes() {
    const axes = [];
    const nonRankedIndex = this.getNonRankingMetricColumnIndex();
    const rankedIndex = this.getFirstRankingMetricColumnIndex();

    if (nonRankedIndex !== -1) {
      // make sure for non-ranking metrics we don't show the label title
      axes.push({
        ...this.getValueAxis(this.nonGroupedColumns[nonRankedIndex], nonRankedIndex),
        hideAxisText: true,
      });
    }
    if (rankedIndex !== -1) {
      const valueAxis = this.getValueAxis(this.nonGroupedColumns[rankedIndex], rankedIndex);
      const rankingMetrics = this.getRankingMetricColumns();

      if (this.isFillAreaAboveLine()) {
        valueAxis.max = this.getRankingMetricMaxValue(rankingMetrics);
        valueAxis.strictMinMax = true;
      }

      if (rankingMetrics.length > 1) {
        const titleText = [];
        const tooltipText = [];
        rankingMetrics.forEach((rankingMetric, rankIndex) => {
          const columnIndex = this.getRankingMetricColumnIndex(rankingMetric);
          if (rankIndex <= 1) {
            // eslint-disable-next-line tap/no-raw-text-js
            titleText.push(`[bold #${this.getChartPalette(columnIndex)}]${rankingMetric.label}`);
          }

          tooltipText.push(rankingMetric.label);
        });

        // if there's more than 2 ranking metrics then we need to show ellipsis
        valueAxis.titleText = titleText.join(', ');
        if (rankingMetrics.length >= 3) {
          valueAxis.titleText += ` ${Constant.ELLIPSIS}`;
        }
        // tooltip only displays for more than 2 ranking metrics
        if (rankingMetrics.length > 2) {
          valueAxis.tooltipText = tooltipText.join(', ');
        }
      }
      axes.push(valueAxis);
    }

    return axes;
  }

  getRankingMetricMaxValue(rankingMetrics) {
    if (!rankingMetrics.length || !this.currentChartData.length) {
      return 0;
    }

    const maxRankingValues = [];
    rankingMetrics.forEach((rankingMetric) => {
      const metricValueName = `${rankingMetric.field}_${Constant.OPEN_VALUE_FIELD_NAME}`;
      // eslint-disable-next-line tap/no-raw-text-js
      if (typeof this.currentChartData[0][metricValueName] !== 'undefined') {
        maxRankingValues.push(this.currentChartData[0][metricValueName]);
      }
    });

    return Math.max(...maxRankingValues);
  }

  getFirstRankingMetricColumnIndex() {
    return this.nonGroupedColumns.findIndex((column) => this.isRankingMetric(column));
  }

  getNonRankingMetricColumnIndex() {
    return this.nonGroupedColumns.findIndex((column) => !this.isRankingMetric(column));
  }

  getRankingMetricColumns() {
    return this.nonGroupedColumns.filter((column) => this.isRankingMetric(column));
  }

  getRankingMetricColumnIndex(rankingMetric) {
    return this.nonGroupedColumns.findIndex((column) => column === rankingMetric);
  }

  getDurationColumnIndex() {
    return this.nonGroupedColumns.findIndex((column) => column.isFormatTime());
  }

  getNonDurationColumnIndex() {
    return this.nonGroupedColumns.findIndex((column) => column.isFormatNumerical());
  }

  /**
   * Returns true is chart contains any duration metric
   * @returns {boolean}
   */
  isContainDurationMetric() {
    return this.nonGroupedColumns.some((column) => column.format === ColumnFormat.FORMAT_TIME);
  }

  /**
   * @returns {boolean}
   */
  isStacked() {
    return [PlotType.STACKED_V2, PlotType.FULL_STACKED_V2].includes(
      this.getDrawOptions().plot_type
    );
  }

  isLollipopPlotType() {
    return [PlotType.LOLLIPOP_V2].includes(this.getDrawOptions().plot_type);
  }

  /**
   *
   * @returns {boolean}
   */
  isDeepStacked() {
    return [PlotType.DEEP_STACKED, PlotType.AREA, PlotType.AREA_V2, PlotType.LAYERED_V2].includes(
      this.getDrawOptions().plot_type
    );
  }

  isFillAreaAboveLine() {
    return this.getDrawOptions().plot_type === PlotType.AREA_V2;
  }

  /**
   *
   * @returns {boolean}
   */
  isClustered() {
    return [PlotType.CLUSTERED_V2].includes(this.getDrawOptions().plot_type);
  }

  /**
   * @returns {boolean}
   */
  isFullStacked() {
    return [PlotType.FULL_STACKED_V2].includes(this.getDrawOptions().plot_type);
  }

  isRadialHistogram() {
    return [PlotType.RADIAL_HISTOGRAM_V2].includes(this.getDrawOptions().plot_type);
  }

  isRadialBar() {
    return [PlotType.RADIAL_BAR_V2].includes(this.getDrawOptions().plot_type);
  }

  /**
   * @returns {boolean}
   */
  isLayeredPlotType() {
    return [PlotType.LAYERED_V2].includes(this.getDrawOptions().plot_type);
  }

  /**
   * @returns {boolean}
   */
  isSmoothedLine() {
    return this.getDrawOptions().is_smoothed_line;
  }

  getLineType() {
    return this.getDrawOptions().line_type;
  }

  /**
   * @returns {boolean}
   */
  isRotated() {
    return this.getDrawOptions().is_rotated;
  }

  /**
   * There are multiple ways that we define whether an axis moves or not
   * There's also multiple ways for a user to override those defaults
   * When normalisation is disabled then the user can directly override through a draw option
   * With normalisation enabled they can use the metric configure cong
   * @param column
   * @returns {boolean|*}
   */
  isYAxisMoved(column = null) {
    const move_y_axes = this.getWidget()?.metadata.move_y_axes;
    const { is_y_axis_moved, is_normalized, y_axis_override } = this.getDrawOptions();

    if (!column) {
      return is_y_axis_moved;
    }

    // when normalisation is enabled then we use the metric-level overrides
    // defaulting to ranking metrics on the right
    if (is_normalized) {
      return this.normalizedColumShouldMove(move_y_axes, column);
    }

    // with no normalisation then the user has the option of overriding the default
    switch (y_axis_override) {
      case YAxisPosition.SWAP:
        // ranking metrics become standard metrics
        return !this.rankingMetricShouldMove(column);

      case YAxisPosition.RIGHT:
        // push everything to the right
        return true;

      case YAxisPosition.LEFT:
        // push everything to the left
        return false;

      default:
        if (!this.hasRankingMetricsSelected()) {
          return is_y_axis_moved;
        }

        return this.rankingMetricShouldMove(column);
    }
  }

  /**
   * When normalized the opposite value selected in the metric axes configuration
   * is used
   * @param move_y_axes
   * @param column
   * @returns {boolean|*}
   */
  normalizedColumShouldMove(move_y_axes, column) {
    if (this.isRankingMetric(column)) {
      return !this.columnExistsInUserOverride(move_y_axes, column);
    }

    return this.shouldMoveAxis(move_y_axes, column) || this.rankingMetricShouldMove(column);
  }

  /**
   * Column either exists in the "move y axes" metadata field (when not normalised)
   * or it's a ranking metric
   * @param move_y_axes
   * @param column
   * @returns {boolean|*}
   */
  shouldMoveAxis(move_y_axes, column) {
    return (
      this.columnExistsInUserOverride(move_y_axes, column) || this.rankingMetricShouldMove(column)
    );
  }

  /**
   * Whether the user has selected to move a column in the metric configuration screen
   * @param move_y_axes
   * @param column
   * @returns {boolean}
   */
  columnExistsInUserOverride(move_y_axes, column) {
    // eslint-disable-next-line tap/no-raw-text-js
    return !!(column && typeof move_y_axes !== 'undefined' && move_y_axes[column.field]);
  }

  /**
   * @param value
   * @param column
   * @returns {string|*}
   */
  getFormattedTooltipValue(value, column) {
    if (column.isFormatPercent()) {
      return `${value}%`;
    }
    if (column.isFormatCurrency()) {
      return `${value}$`;
    }
    return value;
  }

  /**
   * @param column
   * @param columnIndex
   * @param isComparison
   * @returns {{}}
   */
  getBarSeries(column, columnIndex, isComparison) {
    const drawOptions = this.getDrawOptions();
    const roundedCorners = drawOptions.rounded_corners / 10;
    const hasComparison = this.hasComparison();
    const layeredProps = {};
    if (this.isLayeredPlotType()) {
      if (hasComparison) {
        layeredProps.locationX = isComparison ? 0.75 : 0.25;
      }
      layeredProps.seriesWidth = this.layered_columns_width[columnIndex] ?? 100;
    }

    return {
      ...layeredProps,
      columnWidth: drawOptions.width,
      fillOpacity: isComparison ? 0.6 : 1,
      columnCornerRadiusTopLeft: roundedCorners,
      columnCornerRadiusTopRight: roundedCorners,
      columnCornerRadiusBottomRight: roundedCorners,
      columnCornerRadiusBottomLeft: roundedCorners,
      isRankingMetric: this.isRankingMetric(column),
    };
  }

  /**
   * @returns {boolean}
   */
  isLineFillOpacityRequired() {
    return this.isStacked() || this.isFullStacked() || this.isDeepStacked();
  }

  getPerColumnBulletShape(column, defaultShape, isBenchmark = false) {
    const { bullets_per_column, bullets_per_benchmark } = this.getDrawOptions();
    const container = isBenchmark ? bullets_per_benchmark : bullets_per_column;
    const currentBulletTypes = container || {};
    if (!currentBulletTypes[column.field]) {
      return defaultShape;
    }

    const selectedItem = currentBulletTypes[column.field];
    return BulletShapes[selectedItem];
  }

  /**
   * @param column
   * @param columnIndex
   * @param isComparison
   * @returns {{}}
   */
  getLineSeries(column, columnIndex, isComparison) {
    const { has_bullets, bullets_shape, step_line_risers, line_type } = this.getDrawOptions();
    const customBulletShape = this.getPerColumnBulletShape(column, bullets_shape);
    const fillOpacityValue = isComparison ? 0.3 : 0.6;
    let risersProps = {};
    if (line_type === LineTypes.STEP_LINE) {
      risersProps = step_line_risers ? risersProps : { noRisers: true };
    }
    return {
      fill: this.isLineFillOpacityRequired() ? this.getChartPalette(columnIndex) : null,
      fillOpacity: this.isLineFillOpacityRequired() ? fillOpacityValue : null,
      opacity: isComparison ? 0.6 : 1,
      strokeWidth: 2,
      strokeOpacity: isComparison ? 0.6 : 1,
      bullet: new BulletConfig({
        enabled: has_bullets,
        fill: this.getChartPalette(columnIndex),
        fillOpacity: isComparison ? 0.6 : 1,
        shape: customBulletShape,
      }),
      tension: this.isSmoothedLine() ? 0.7 : null,
      color: this.getChartPalette(columnIndex),
      isRankingMetric: this.isRankingMetric(column),
      ...risersProps,
    };
  }

  /**
   * Whether the selected ranking metric should move to the opposite side of the axis
   * @param column
   * @returns {*|boolean}
   */
  rankingMetricShouldMove(column) {
    if (!column) {
      return false;
    }

    if (this.nonGroupedColumns.length === 1) {
      return false;
    }

    if (this.hasAllRankingMetricsSelected()) {
      return false;
    }

    return this.isRankingMetric(column);
  }

  /**
   * Column is a ranking metric
   * @param column
   * @returns {boolean}
   */
  isRankingMetric(column) {
    return column && column.is_ranking_metric;
  }

  /**
   *
   * @param column
   * @param columnIndex
   * @param axisIndex
   * @param isComparison
   * @returns {{}}
   */
  getSeries(column, columnIndex, axisIndex, isComparison = false) {
    const drawOptions = this.getDrawOptions();

    let categoryAxisIndex = 0;
    if (this._isCurrentGroupedColumnDate() && isComparison && !USE_DATE_AXIS_AS_CATEGORY) {
      categoryAxisIndex = 1;
    }

    let category;
    if (!USE_DATE_AXIS_AS_CATEGORY) {
      category = isComparison
        ? this.appendComparisonSuffixToField(this.getCurrentGroupByField())
        : this.getCurrentGroupByField();
    } else {
      category = this.getCurrentGroupByField();
    }

    return {
      axisIndex,
      categoryAxisIndex,
      name: isComparison ? this.appendComparisonSuffixToLabel(column.label) : column.label,
      value: isComparison ? this.appendComparisonSuffixToField(column.field) : column.field,
      category,
      isCategoryDate: false,
      stacked: this.isStacked(),
      isClustered: !this.isDeepStacked(),
      showMetricLabels: drawOptions.show_metric_labels,
      color: this.getChartPalette(columnIndex),
      opacity: isComparison ? 0.6 : 1,
      tooltipDisabled: !drawOptions.has_tooltip,
      formatTooltipValue: (value) => this.getFormattedTooltipValue(value, column),
      dataItemFormat: column?.format,
      strokeColor: isComparison ? color(0xffffff) : null,
      isRankingMetric: column.is_ranking_metric,
      bullet: new BulletConfig({
        enabled: true,
        fill: this.getChartPalette(columnIndex),
        fillOpacity: isComparison ? 0.6 : 1,
        shape: drawOptions.bullets_shape,
        rotation: drawOptions.is_rotated ? 90 : 0,
      }),
    };
  }

  /**
   * @param hasComparison
   * @param column
   * @param columnIndex
   * @param axisIndex
   * @returns {[]}
   */
  getAllSeriesForColumn(hasComparison, column, columnIndex, axisIndex) {
    const allSeries = [];
    if (hasComparison) {
      allSeries.push(this.getSeries(column, columnIndex, axisIndex, true));
    }
    allSeries.push(this.getSeries(column, columnIndex, axisIndex));
    return allSeries;
  }

  /**
   * @returns {[]}
   */
  getAllSeries() {
    let allSeries = [];
    const hasComparison = this.hasComparison();
    const { is_normalized: isNormalized } = this.getDrawOptions();
    if (isNormalized) {
      this.nonGroupedColumns.forEach((column, index) => {
        allSeries = allSeries.concat(
          this.getAllSeriesForColumn(hasComparison, column, index, index)
        );
      });
    } else if (this.isContainDurationMetric()) {
      const durationColumnIndex = this.getDurationColumnIndex();
      const nonDurationColumnIndex = this.getNonDurationColumnIndex();
      const containsOnlySingleValueAxis =
        durationColumnIndex === -1 || nonDurationColumnIndex === -1;
      if (containsOnlySingleValueAxis) {
        this.nonGroupedColumns.forEach((column, index) => {
          allSeries = allSeries.concat(this.getAllSeriesForColumn(hasComparison, column, index, 0));
        });
      } else {
        const durationAxisIndex = durationColumnIndex > nonDurationColumnIndex ? 1 : 0;
        const nonDurationAxisIndex = durationColumnIndex > nonDurationColumnIndex ? 0 : 1;
        this.nonGroupedColumns.forEach((column, index) => {
          allSeries = allSeries.concat(
            this.getAllSeriesForColumn(
              hasComparison,
              column,
              index,
              column.isFormatTime() ? durationAxisIndex : nonDurationAxisIndex
            )
          );
        });
      }
    } else if (this.hasRankingMetricsSelected()) {
      const rankingMetricColumns = this.getRankingMetricColumns();
      const allRankingMetrics = this.hasAllRankingMetricsSelected();

      this.nonGroupedColumns.forEach((column, index) => {
        let axisIndex = 0;
        if (!allRankingMetrics) {
          axisIndex = rankingMetricColumns.includes(column) ? 1 : 0;
        }
        allSeries = allSeries.concat(
          this.getAllSeriesForColumn(hasComparison, column, index, axisIndex)
        );
      });
    } else {
      this.nonGroupedColumns.forEach((column, index) => {
        allSeries = allSeries.concat(this.getAllSeriesForColumn(hasComparison, column, index, 0));
      });
    }

    return allSeries;
  }

  /**
   * @returns {[]}
   */
  getChartSeries() {
    return this.getAllSeries();
  }

  /**
   * @returns {{fontSize: number, text: string, align: string, fontWeight: number}}
   */
  getDrillDownInfoLabel() {
    const text = `${this.getCurrentGroupByColumn().label}s for ${
      this.getPreviousGroupByColumn().label
    }`;
    return {
      text,
      align: Constant.CENTER,
      fontWeight: 600,
      fontSize: 15,
    };
  }

  /**
   * @returns {null|Array}
   */
  getChartTitles() {
    return this.canUndoDrillDown()
      ? [this.getUndoDrillDownLabel(), this.getDrillDownInfoLabel()]
      : null;
  }

  /**
   * @returns {{depth: number, angle: number}}
   */
  getBarChartConfigProperties() {
    const drawOptions = this.getDrawOptions();

    return {
      depth: drawOptions.depth,
      angle: drawOptions.angle,
      cursorEnabled: false,
    };
  }

  /**
   * @returns {{depth: number, angle: number}}
   */
  getLineChartConfigProperties() {
    const {
      depth,
      angle,
      line_type,
      has_regression_line,
      has_metric_lines_visible,
      y_axis_override,
    } = this.getDrawOptions();

    return {
      depth,
      angle,
      cursorEnabled: false,
      lineType: line_type,
      hasRegressionLine: has_regression_line,
      hasMetricLinesVisible: line_type === LineTypes.STEP_LINE ? true : has_metric_lines_visible,
      yAxisOverride: y_axis_override,
    };
  }

  setLayeredWidth() {
    const seriesLength = this.nonGroupedColumns.length;
    this.layered_columns_width = Array.from({ length: seriesLength }, (e, i) => i);
    const maxValue = this.hasComparison() ? 50 : 80;
    const minValue = this.hasComparison() ? 10 : 20;
    const scaleFactor = maxValue / seriesLength;
    this.layered_columns_width = this.layered_columns_width.map((w) =>
      Math.round(minValue + w * scaleFactor)
    );
    this.layered_columns_width.reverse();
  }

  getBenchmarkData(column, isComparison = false) {
    const category = this.getCurrentGroupByField();
    const benchmarkData = [];
    const testData = [];

    let valueField = Constant.BENCHMARK_PREFIX + column.field;
    if (isComparison) {
      valueField += Constant.COMPARISON;
    }

    this.currentChartData.forEach((datum) => {
      const dataValue = Number(datum[valueField]) || null;
      testData.push(dataValue);

      benchmarkData.push({
        [column.field]: dataValue,
        [category]: datum[category] || null,
        client_currency: datum?.client_currency,
      });
    });

    if (testData.every((value) => value === null)) {
      return [];
    }

    return benchmarkData;
  }

  /**
   * Generate a series to pass through to the composable to create the benchmark line
   * @returns {*[]}
   */
  getBenchmarkSeries() {
    const { benchmarks, selected } = this.getDataColumns();
    const { benchmark_line_style, benchmark_bullets, bullets_shape, benchmark_line_type } =
      this.getDrawOptions();

    // color should only be calculated based on actual metric values.
    // group by values are gray
    const selectedMetrics = selected.filter((selectedColumn) => selectedColumn.is_metric);

    const benchmarkData = [];
    const colorPaletteIndex = selectedMetrics.length;
    const baseBenchmarkSeries = {
      lineStyle: benchmark_line_style,
      showBullets: benchmark_bullets,
      lineType: benchmark_line_type,
      category: this.getCurrentGroupByField(),
    };

    if (benchmarks.length) {
      benchmarks.forEach((column, benchmarkIndex) => {
        const seriesData = this.getBenchmarkData(column);
        if (seriesData.length) {
          benchmarkData.push(
            new BenchmarkSeries({
              color: this.getChartPalette(colorPaletteIndex + benchmarkIndex),
              name: column.label,
              value: column.field,
              bulletShape: this.getPerColumnBulletShape(column, bullets_shape, true),
              dataItemFormat: column.format,
              data: seriesData,
              ...baseBenchmarkSeries,
            })
          );
        }

        if (this.hasComparison()) {
          const comparisonSeriesData = this.getBenchmarkData(column, true);
          if (comparisonSeriesData.length) {
            benchmarkData.push(
              new BenchmarkSeries({
                color: this.getChartPalette(colorPaletteIndex + benchmarkIndex),
                name: `${Constant.COMPARISON_TEXT} ${column.label}`,
                value: column.field,
                bulletShape: this.getPerColumnBulletShape(column, bullets_shape, true),
                dataItemFormat: column.format,
                data: comparisonSeriesData,
                opacity: 0.3,
                ...baseBenchmarkSeries,
              })
            );
          }
        }
      });
    }

    return benchmarkData;
  }

  /**
   * @returns {BaseDrawOption}
   */
  getDataColumns() {
    return this.getWidget()?.metadata.data_columns;
  }

  hasBenchmarks() {
    const { benchmarks } = this.getDataColumns();
    const notSupportedPlotTypes = [
      PlotType.AREA_V2,
      PlotType.FULL_STACKED_V2,
      PlotType.RADIAL_BAR_V2,
      PlotType.RADIAL_HISTOGRAM_V2,
    ];

    if (notSupportedPlotTypes.includes(this.getDrawOptions().plot_type)) {
      return false;
    }

    return benchmarks && !!benchmarks.length;
  }

  getBenchmarkConfig() {
    const benchmarkConfig = {
      enabled: this.hasBenchmarks(),
    };

    if (benchmarkConfig.enabled) {
      benchmarkConfig.data = this.getBenchmarkSeries();
    }

    return benchmarkConfig;
  }

  /**
   * @returns {{data: *}}
   */
  getChartConfigProperties() {
    const drawOptions = this.getDrawOptions();
    if (this.isLayeredPlotType()) {
      this.setLayeredWidth();
    }
    return {
      ...super.getChartConfigProperties(),
      hasTooltip: drawOptions.has_tooltip,
      isTooltipOnCursor: !this.isInlineDrillDown(),
      valueAxis: this.getChartValueAxis(),
      categoryAxis: this.getChartCategoryAxis(),
      showMetricLabels: drawOptions.show_metric_labels,
      hasValueScrollbar: drawOptions.has_value_scroller,
      isYAxisMoved: this.isYAxisMoved(),
      isRotated: drawOptions.is_rotated,
      isFullStacked: this.isFullStacked(),
      isLayered: this.isLayeredPlotType(),
      isNormalizedData: drawOptions.is_normalized,
      isStacked: this.isStacked(),
      cursorOverStyle: this.getCursorOverStyle(),
      isInlineDrillDown: this.isInlineDrillDown(),
      lollipopChart: this.isLollipopPlotType(),
      roundedCorners: drawOptions.rounded_corners,
      showColumnShadows: drawOptions.show_column_shadows,
      fillType: drawOptions.fill_type,
      gradientColor: drawOptions.gradient_color,
      isZeroScaled: drawOptions.is_zero_scaled,
      isLogScaled: drawOptions.is_log_scaled,
      isCurvedColumns: drawOptions.curved_columns,
      radialInnerRadius: drawOptions.radial_inner_radius,
      isClustered: this.isClustered(),
      isRadialHistogram: this.isRadialHistogram(),
      isRadialBar: this.isRadialBar(),
      benchmarks: this.getBenchmarkConfig(),
      fontColorPicker: drawOptions.font_color_picker,
      hasRankingMetrics: this.hasRankingMetricsSelected(),
      fillAreaAboveLine: this.isFillAreaAboveLine(),
    };
  }

  /**
   * @returns {boolean}
   */
  hasRankingMetricsSelected() {
    return (
      this.nonGroupedColumns &&
      Array.isArray(this.nonGroupedColumns) &&
      this.nonGroupedColumns.some((column) => this.isRankingMetric(column))
    );
  }

  hasAllRankingMetricsSelected() {
    return (
      this.nonGroupedColumns &&
      Array.isArray(this.nonGroupedColumns) &&
      this.nonGroupedColumns.every((column) => this.isRankingMetric(column))
    );
  }

  /**
   * @returns {boolean}
   */
  canDrillDown() {
    return super.canDrillDown() && this.getGroupByIndexOnDrillDown() !== this.currentGroupByIndex;
  }

  /**
   * @param event
   * @returns {DrillDownConfig}
   */
  drillDown(event) {
    const categoryIndex = categoryAxesIndexOnChartClicked(event, this.isRotated());
    this.setCurrentGroupByIndex(this.getGroupByIndexOnDrillDown());
    const drillDownConfig = this.getDrillDownConfig(categoryIndex, this.currentPeriodData);
    // Below we push and return last element in drillDownConfigStack
    this.drillDownConfigStack.push(drillDownConfig);
    return drillDownConfig;
  }
}
