export const AppNavKey = {
  RIPLEY: 'ripley',
  ACORN: 'acorn',
  ZEST: 'zest',
  STELLAR: 'stellar',
  GLOW: 'glow',
  SPROUT: 'sprout',
  TAP_ORDERS: 'tap_orders',
  CHECKLIST: 'checklist',
  SHEETS: 'sheets',
  PLANNER: 'planner',
};

export const Product = {
  ACORN: 'acorn',
  GLOW: 'glow',
  RIPLEY: 'ripley',
  SPROUT: 'sprout',
  STELLAR: 'stellar',
  ZEST: 'zest',
  SHEETS: 'sheets',
};

export const AppFeature = {
  AUTO: 'auto',
  CALCULATIONS: 'custom_calculations',
  CATEGORIES: 'categories',
  CATEGORY_MANAGEMENT: 'category_management',
  CLUSTERS: 'clusters',
  DASHBOARD_MANAGEMENT: 'dashboard_view_management',
  EXPORT_BUILDER: 'export_builder',
  IMPORT_WIZARD: 'import_wizard',
};

export const AppModule = {
  /* ALPHABETICAL ORDER PLEASE */
  AUTO_LEADS: 'auto',
  AUTO_MAPPING: 'auto_mapping',
  AUDIENCE: 'audience',
  BENCHMARKS: 'benchmark',
  BUDGET_PACINGS: 'budget_pacing',
  BRAND_PACKAGE: 'brand_package',
  CATEGORY_MULTI_MODE: 'category_multi_mode',
  MINI_GOALS: 'mini_goals',
  CALCULATION_TEXT_EDITOR: 'calculation_text_editor',
  CHATGPT: 'chatgpt',
  CHATGPT_WIDGET: 'chatgpt_widget',
  CLIENT_CURRENCY: 'client_currency',
  CLIENT_CALCULATIONS: 'client_calculations',
  CLIENT_HOLD_STATUS: 'client_hold_status',
  COMPETITOR_RESEARCH: 'competitor_research',
  CUSTOM_GLOBAL_FILTER: 'custom_global_filter',
  CUSTOM_TRANSLATIONS: 'custom_translations',
  DATA_EXPORTER: 'data_exporter',
  DASH_FOLDERS: 'dashfolders',
  DISABLE_OLD_UI: 'disable_oui',
  DYNAMIC_LAYOUTS: 'dynamic_layouts',
  EXISTING_ROLES_USER: 'existing_roles_user',
  GDS: 'gds',
  GOALS: 'goals',
  GOALS_PRO: 'goals_pro',
  HIPAA: 'hipaa',
  IMPERSONATE_USERS: 'impersonate_users',
  IO_CLIENT_PORTAL: 'io_client_portal',
  IO_IN_OUT_STATUS_BOARD: 'io_in_out_status_board',
  IO_INVENTORY_CALENDAR: 'io_inventory_calendar',
  IO_TOOL: 'io_tool',
  IO_TOOL_STELLAR_SYNC: 'iotool_stellar_sync',
  KEYWORD_TRACKING: 'keyword_tracking',
  LEAD_NOTIFICATIONS: 'lead_notifications',
  MANAGE_LEADS: 'leads_management',
  MARKETPLACE: 'marketplace',
  MULTI_LANGUAGE_REPORTS: 'multi_language_reports',
  NEW_MDS_ACCESS: 'new_mds_access',
  NUI: 'nui',
  NUI_DARK_MODE: 'nui_dark_mode',
  PLANNER: 'planner',
  PREDEFINED_CATEGORY_DASHBOARD: 'predefined_category_dashboard',
  INSIGHTS_HQ: 'insights_hq',
  REPORT_STUDIO: 'report_studio',
  REPORT_STUDIO_AGENT_ACCESS: 'report_studio_agent_access',
  SEO: 'seo',
  SEPARATE_SCHEDULED_REPORTS_FROM_FETCH: 'separate_scheduled_reports_from_fetch',
  SERVICE_LOOKUP: 'service_lookup',
  SHEETS: 'sheets',
  SMART_CAMPAIGNS: 'smart_campaigns',
  SITE_AUDITOR: 'site_auditor',
  STELLAR: 'stellar',
  USER_LOGINS: 'user_logins',
  WEBSITE: 'website',
  /* ALPHABETICAL ORDER PLEASE */
};

export const AppModuleGroup = {
  CORE: 'core',
  SEO: 'seo',
  TA: 'ta',
  TO: 'to',
  AUDIENCE: 'audience',
};

export const AppConfig = {
  API_URL: '/server/api/',
  NUI_PATH: '/client/dist/#',
  IO_URL: '/client/iotool/dist/#',
};

export const Module = {
  /* ALPHABETICAL ORDER PLEASE */

  /* CLIENT SIDE MODULES */
  DASHBOARD_DRILLDOWN: 'dashboard/drilldown',
  DASHBOARD_EXPORT: 'dashboard/export',
  DASHBOARD_LAYOUT: 'dashboard/layout',
  DASHBOARD_METADATA: 'dashboard/metadata',
  DATACALENDAR: 'datacalendar',
  DATERANGE: 'daterange',
  DEVTOOLS: 'devtools',
  FORMBUILDER: 'formbuilder',
  FORMBUILDER_FIELD: 'formbuilder/field',
  FORMBUILDER_GROUP: 'formbuilder/group',
  FORMBUILDER_STEP: 'formbuilder/step',
  GUIDE: 'guide',
  HUB: 'hub',
  LIST: 'list',
  CALLBACKROUTE: 'callbackroute',
  SLIDERPANEL: 'sliderpanel',
  UNDO: 'undo',
  UI: 'ui',
  TABS: 'tabs',

  /* SERVER SIDE MODULES */
  ALERT: 'alert',
  ANOMALY: 'anomaly',
  APP: 'app',
  ASYNC: 'async',
  AUDIENCE: 'audience',
  AUDITLOG: 'auditlog',
  BADGE: 'badge',
  BILLING: 'billing',
  BUDGETPACING: 'budgetpacing',
  BUNDLE: 'bundle',
  CALCULATION: 'calculation',
  CAMPAIGN: 'campaign',
  CANCELLATIONREASON: 'cancellationreason',
  CATEGORY: 'category',
  CATEGORYDATA: 'categorydata',
  CATALOGUESETTING: 'cataloguesetting',
  CLIENT: 'client',
  CLIENTCALCULATIONS: 'clientcalculations',
  CLIENTCATEGORY: 'clientcategory',
  CLIENTGROUP: 'clientgroup',
  CLUSTER: 'cluster',
  CLUSTERMEMBER: 'clustermember',
  CLUSTERACCESS: 'clusteraccess',
  COMMENT: 'comment',
  COMPETITORRESEARCH: 'competitorresearch',
  CONNECTIONSTATUS: 'connectionstatus',
  DATALOADSTATUS: 'dataloadstatus',
  CONTENTSET: 'contentset',
  CREATIVE: 'creative',
  DATAEXPORTER: 'dataexporter',
  DATASOURCE: 'datasource',
  DASHBOARD: 'dashboard',
  DASHBOARDCHATGPTSUMMARIES: 'dashboardchatgptsummaries',
  DASHBOARDEXECUTIVESUMMARIES: 'dashboardexecutivesummaries',
  DASHFOLDERS: 'dashfolders',
  DESIGN: 'design',
  EMAIL: 'email',
  ENDCUSTOMER: 'endcustomer',
  ENTITYFORM: 'entityform',
  ENTITYSTATUS: 'entitystatus',
  EXECUTIVESUMMARIES: 'executivesummaries',
  EXPORT: 'export',
  FILETAG: 'filetag',
  FILE: 'file',
  GDS: 'gds',
  GENERIC: 'generic',
  GLOBALFILTER: 'globalfilter',
  GOAL: 'goals',
  GOAL_MODULE: 'goal',
  GOALDATA: 'goaldata',
  HISTORY: 'history',
  IMPORTWIZARD: 'importwizard',
  INDUSTRYBENCHMARK: 'industrybenchmark',
  IO: 'io',
  INSIGHTS_HQ: 'insightshq',
  KEYWORDTRACKING: 'keywordtracking',
  LAYOUT: 'layout',
  LEAD: 'lead',
  LEADDETAILS: 'leaddetails',
  LEADNOTES: 'leadnotes',
  LEADNOTIFICATION: 'leadnotification',
  LINEITEM: 'lineitem',
  MANAGESERVICE: 'manageservice',
  MARGINRULE: 'marginrule',
  MARKETPLACE: 'marketplace',
  MEMORYLOG: 'memorylog',
  NAMINGCONVENTION: 'namingconvention',
  NAV: 'nav',
  OAUTHUSER: 'oauthuser',
  ORDER: 'order',
  PLATFORMFILTER: 'platformfilter',
  PLATFORMSEARCH: 'platformsearch',
  NOTE: 'note',
  NOTIFICATIONPROFILE: 'notificationProfile',
  OAUTH: 'oauth',
  OAUTHCLUSTERACCESS: 'oauthclusteraccess',
  OBJECTIVE: 'objective',
  PAGELAYOUTWIDGET: 'pagelayoutwidget',
  PREFERENCE: 'preference',
  PRODUCT: 'product',
  PROPOSAL: 'proposal',
  QUICKREPORT: 'quickreport',
  QUICKVIEWSLIDER: 'quickviewslider',
  QUEUE: 'queue',
  RELATIONSHIP: 'relationship',
  REPORTINGPROFILE: 'reportingprofile',
  REPORT: 'report',
  REPORTSTUDIO: 'reportstudio',
  REPORTSTUDIOCHATGPTSUMMARIES: 'reportstudiochatgptsummaries',
  REPORTSTUDIOEXECUTIVESUMMARIES: 'reportstudioexecutivesummaries',
  REPORTINGTEMPLATE: 'reportingtemplate',
  ROLE: 'role',
  SCHEDULEDREPORT: 'scheduledreport',
  SERP: 'serp',
  SERVICE: 'service',
  SERVICESETTINGS: 'servicesettings',
  SERVICEDATA: 'servicedata',
  SERVICECLIENTMAPPING: 'serviceclientmapping',
  SERVICELOOKUP: 'servicelookup',
  SITEAUDITOR: 'siteauditor',
  SMARTCAMPAIGN: 'smartcampaign',
  SESSION: 'session',
  TAG: 'tag',
  TASKTAG: 'tasktag',
  TASKSETTINGS: 'tasksettings',
  TASK: 'task',
  SEO: 'seo',
  SHARED: 'shared',
  SHEETS: 'sheets',
  UDB: 'udb',
  USER: 'user',
  CUSTOMTRANSLATION: 'customtranslation',
  USERLOGIN: 'userlogin',
  USERIMAGE: 'userimage',
  WEBHOOKS: 'webhooks',
  WEBSITE: 'website',
  WIDGET: 'widget',
  WIDGET_BUILDER: 'widget/builder',
  WORKFLOWACTION: 'workflowaction',
  /* ALPHABETICAL ORDER PLEASE */
};

export const OnboardingStep = {
  SIGNUP: 1,
  CONNECT_DATA: 2,
  ASSIGN_CLIENT: 3,
  GENERATE_DASHBOARD: 4,
};

export const UserThemes = {
  TYPE_HYBRID: 'hybrid',
  TYPE_LIGHT: 'light',
  TYPE_DARK: 'dark',
  BRIGHT: 'bright',
  MINT: 'mint',
  VELVET: 'velvet',
  HOTH: 'hoth',
  CLASSIC: 'classic',
  AZURE: 'azure',
  LEATHER: 'leather',
  MIDAS: 'midas',
  EGGPLANT: 'eggplant',
  TEAL: 'teal',
  CUSTOM: 'custom',
  CUSTOMLIGHT: 'customlight',
  CUSTOMHYBRID: 'customhybrid',
  CUSTOMDARK: 'customdark',
};

export const IconPickerIconClasses = [
  'icon-search',
  'icon-envelope-o',
  'icon-heart',
  'icon-star',
  'icon-power-off',
  'icon-signal',
  'icon-gear',
  'icon-play-circle-o',
  'icon-list-alt',
  'icon-flag',
  'icon-tag',
  'icon-pie-chart',
  'icon-camera',
  'icon-picture-o',
  'icon-map-marker',
  'icon-info-circle',
  'icon-crosshairs',
  'icon-asterisk',
  'icon-leaf',
  'icon-fire',
  'icon-eye',
  'icon-comment',
  'icon-trophy',
  'icon-phone',
  'icon-rss',
  'icon-bullhorn',
  'icon-globe',
  'icon-tasks',
  'icon-filter',
  'icon-flask',
  'icon-table',
  'icon-magic',
  'icon-sellsy',
  'icon-dashboard',
  'icon-flash',
  'icon-sitemap',
  'icon-bar-chart-o',
  'icon-lightbulb-o',
  'icon-fighter-jet',
  'icon-desktop',
  'icon-suitcase',
  'icon-code',
  'icon-code-fork',
  'icon-shield',
  'icon-rocket',
  'icon-bullseye',
  'icon-sun-o',
  'icon-moon-o',
  'icon-pagelines',
  'icon-institution',
  'icon-paw',
  'icon-cubes',
  'icon-tree',
  'icon-database',
  'icon-ra',
  'icon-ge',
  'icon-diamond',
  'icon-send',
  'icon-bomb',
  'icon-line-chart',
  'serviceicon-adpro',
  'serviceicon-madhive',
  'serviceicon-sharethrough',
  'serviceicon-bidtellect',
  'serviceicon-sterling',
  'serviceicon-dbmorders',
  'serviceicon-dbm',
  'serviceicon-dfa',
  'serviceicon-adwords',
  'serviceicon-dcs',
  'serviceicon-liquidm',
  'serviceicon-whatconverts',
  'serviceicon-liveintentcampaigns',
  'serviceicon-liveintentinventory',
  'serviceicon-visto',
  'serviceicon-tmpi',
  'serviceicon-googleanalyticsmcf',
  'serviceicon-digdevdirect',
  'serviceicon-greenbananaseo',
  'serviceicon-salesforceleads',
  'serviceicon-measuredmarketing',
  'serviceicon-teads',
  'serviceicon-googlepagespeed',
  'serviceicon-siteimpact',
  'serviceicon-groundtruth',
  'serviceicon-vervevelocity',
  'serviceicon-adform',
  'serviceicon-uberall',
  'serviceicon-matchcraft',
  'serviceicon-invoca',
  'serviceicon-dotmailer',
  'serviceicon-convirzaforadvertisers',
  'serviceicon-verve',
  'serviceicon-shoutaboutus',
  'serviceicon-dataxu',
  'serviceicon-semrushdomains',
  'serviceicon-responsetap',
  'serviceicon-eqworks',
  'serviceicon-emma',
  'serviceicon-taboola',
  'serviceicon-authoritylabs',
  'serviceicon-appnexusio',
  'serviceicon-appnexus',
  'serviceicon-steprep',
  'serviceicon-simplifi',
  'serviceicon-dudamobile',
  'serviceicon-dudaone',
  'serviceicon-freespee',
  'serviceicon-liquidus',
  'serviceicon-googleanalytics',
  'serviceicon-googleanalyticscustom',
  'serviceicon-googleanalyticssegments',
  'serviceicon-unbounce',
  'serviceicon-aspenemail',
  'serviceicon-aspenshared',
  'serviceicon-aspenocpj',
  'serviceicon-aspendirect',
  'serviceicon-sitescout',
  'serviceicon-adobe-marketing-cloud',
  'serviceicon-appannie',
  'serviceicon-arrivalist',
  'serviceicon-cbslocal',
  'serviceicon-chatter',
  'serviceicon-cheetah',
  'serviceicon-conversant',
  'serviceicon-craiglist',
  'serviceicon-display',
  'serviceicon-espnradio',
  'serviceicon-eblast',
  'serviceicon-email',
  'serviceicon-engagetosell',
  'serviceicon-goodway',
  'serviceicon-hulu',
  'serviceicon-landing',
  'serviceicon-linkedininmail',
  'serviceicon-linkedingads',
  'serviceicon-mixpanel',
  'serviceicon-notifyme',
  'serviceicon-pandora',
  'serviceicon-presslaf',
  'serviceicon-print',
  'serviceicon-promojam',
  'serviceicon-pubmatic',
  'serviceicon-quancast',
  'serviceicon-rocketfuel',
  'serviceicon-salesforce',
  'serviceicon-serpbook',
  'serviceicon-snapchat',
  'serviceicon-social',
  'serviceicon-strata',
  'serviceicon-theweatherc',
  'serviceicon-tripadvisor',
  'serviceicon-typhoon',
  'serviceicon-watchme',
  'serviceicon-waze',
  'serviceicon-yellowpages',
  'serviceicon-zapier',
  'serviceicon-adgear',
  'serviceicon-adjuster',
  'serviceicon-adobe',
  'serviceicon-adroll',
  'serviceicon-adtegrity',
  'serviceicon-adventive',
  'serviceicon-agendize',
  'serviceicon-aoladtech',
  'serviceicon-apexchat',
  'serviceicon-appnexusadtaxi',
  'serviceicon-avanser',
  'serviceicon-benchmarkemail',
  'serviceicon-bing',
  'serviceicon-bingadtaxi',
  'serviceicon-bingwebmasterstools',
  'serviceicon-birdeye',
  'serviceicon-bitly',
  'serviceicon-bmielite',
  'serviceicon-boostability',
  'serviceicon-brightcovevideocloud',
  'serviceicon-buffer',
  'serviceicon-callcap',
  'serviceicon-callrail',
  'serviceicon-callsource',
  'serviceicon-campaignmonitor',
  'serviceicon-cds',
  'serviceicon-celtra',
  'serviceicon-choozle',
  'serviceicon-clipcentric',
  'serviceicon-constantcontact',
  'serviceicon-coreaudience',
  'serviceicon-ctm',
  'serviceicon-datadyn',
  'serviceicon-default',
  'serviceicon-delacon',
  'serviceicon-devhub',
  'serviceicon-dfp',
  'serviceicon-dialogtech',
  'serviceicon-dropbox',
  'serviceicon-ecl',
  'serviceicon-eltoro',
  'serviceicon-eyereturn',
  'serviceicon-facebook',
  'serviceicon-foursquare',
  'serviceicon-frontseat',
  'serviceicon-googledrive',
  'serviceicon-googlemybusiness',
  'serviceicon-googleplus',
  'serviceicon-googlewebmastertools',
  'serviceicon-gshift',
  'serviceicon-hubspot',
  'serviceicon-icontact',
  'serviceicon-ifbyphone',
  'serviceicon-importwizard_plus',
  'serviceicon-instagramsocial',
  'serviceicon-ipromote',
  'serviceicon-ivinteractive',
  'serviceicon-jivox',
  'serviceicon-jumptap',
  'serviceicon-kenshoo',
  'serviceicon-lamarkmedia',
  'serviceicon-linkedin',
  'serviceicon-linkedinprofile',
  'serviceicon-litbreaker',
  'serviceicon-mailchimp',
  'serviceicon-marchex',
  'serviceicon-marchexproxy',
  'serviceicon-marketo',
  'serviceicon-mediamath',
  'serviceicon-mediaprowler',
  'serviceicon-megaleads',
  'serviceicon-micrositemasters',
  'serviceicon-microstrategy',
  'serviceicon-mixpo',
  'serviceicon-moatdisplay',
  'serviceicon-mocentric',
  'serviceicon-movingcompanyreviews',
  'serviceicon-nativo',
  'serviceicon-ndn',
  'serviceicon-outbrain',
  'serviceicon-pinterest',
  'serviceicon-platform161',
  'serviceicon-pulsepoint',
  'serviceicon-reachlocal',
  'serviceicon-realmedia',
  'serviceicon-recrue',
  'serviceicon-recruemobile',
  'serviceicon-recruesocial',
  'serviceicon-reviewtrackers',
  'serviceicon-revlocal',
  'serviceicon-ripper',
  'serviceicon-rtbiq',
  'serviceicon-rubicon',
  'serviceicon-sendible',
  'serviceicon-seomoz',
  'serviceicon-shopify',
  'serviceicon-shoutlet',
  'serviceicon-silverpop',
  'serviceicon-simplififtp',
  'serviceicon-sizmek',
  'serviceicon-sizmekftp',
  'serviceicon-sizmekmdxnxt',
  'serviceicon-sizmeksearch',
  'serviceicon-strongemail',
  'serviceicon-strongemail2',
  'serviceicon-sweetiq',
  'serviceicon-tapclassifieds',
  'serviceicon-tapdrives',
  'serviceicon-taporders',
  'serviceicon-telmetrics',
  'serviceicon-thetradedesk',
  'serviceicon-tigerpistol',
  'serviceicon-tritonaudio',
  'serviceicon-tru',
  'serviceicon-tubemogul',
  'serviceicon-tubemogulapi',
  'serviceicon-twilio',
  'serviceicon-twitter',
  'serviceicon-ubermedia',
  'serviceicon-v12',
  'serviceicon-vast',
  'serviceicon-vertresp',
  'serviceicon-wufoo',
  'serviceicon-yahoo',
  'serviceicon-yahooapt',
  'serviceicon-yahoogemini',
  'serviceicon-yasabe',
  'serviceicon-yashi',
  'serviceicon-yelp',
  'serviceicon-yelpapi',
  'serviceicon-yext',
  'serviceicon-yextlocation',
  'serviceicon-youtube',
  'serviceicon-ypcalls',
  'serviceicon-yptraffic',
  'serviceicon-thryv',
  'serviceicon-zetadsp',
  'serviceicon-qmedia',
  'serviceicon-getintent',
  'serviceicon-beeswax',
  'serviceicon-chatmeter',
  'serviceicon-phonewagon',
  'serviceicon-pinterestaccounts',
  'serviceicon-sizmeksas',
  'serviceicon-spotifyads',
  'serviceicon-adcellerant',
  'serviceicon-amazondsp',
  'serviceicon-adobeanalytics',
  'serviceicon-tiktokads',
  'serviceicon-sc_knorex',
  'serviceicon-sc_wideorbit',
  'serviceicon-sc_amazondsp',
  'serviceicon-sc_admessenger',
  'serviceicon-sc_sqlapp',
  'serviceicon-sc_redcanyon',
  'serviceicon-sc_xpoapp',
  'serviceicon-sc_adlib',
  'serviceicon-sc_iqm',
  'serviceicon-sc_gamut',
  'serviceicon-sc_stirista',
  'serviceicon-sc_agencyplatform',
  'serviceicon-sc_appscience',
  'serviceicon-sc_datadrive',
  'serviceicon-sc_iovox',
  'serviceicon-sc_manual',
  'serviceicon-sc_googlebq',
  'serviceicon-sc_sftp',
  'serviceicon-sc_ftp',
  'serviceicon-sc_snowflake',
  'serviceicon-sc_postgresql',
  'serviceicon-sc_mysql',
  'serviceicon-sc_amazonredshift',
  'serviceicon-sc_amazons3',
  'serviceicon-sc_dropbox',
  'serviceicon-sc_salesforce',
  'serviceicon-sc_drive',
  'serviceicon-sc_tapapi',
  'serviceicon-sc_box',
  'serviceicon-sc_onedrive',
  'serviceicon-sc_geniusmonkey',
  'serviceicon-sc_a4media',
  'serviceicon-sc_loopme',
  'serviceicon-sc_gimbal',
  'serviceicon-sc_netsertive',
  'serviceicon-sc_infinity',
  'serviceicon-sc_googlesheet',
  'serviceicon-sc_adelphic',
  'serviceicon-sc_quickbooks',
  'serviceicon-sc_adtheorent',
  'serviceicon-sc_freewheel',
  'serviceicon-sc_friends2follow',
  'serviceicon-sc_leadsrx',
  'serviceicon-sc_logiqdigital',
  'serviceicon-yahoojapansearch',
  'serviceicon-sc_snowflake_isolated',
  'serviceicon-sc_sabio',
  'serviceicon-sc_mysql_isolated',
  'serviceicon-sc_madhive',
  'serviceicon-sc_brightedge',
  'serviceicon-marchexmarketingedge',
  'serviceicon-sc_amazonathena',
  'serviceicon-sc_azurebs',
  'serviceicon-sc_azuresql',
  'serviceicon-sc_amazonaurora',
  'serviceicon-sc_tapdirect',
  'serviceicon-sc_email',
  'serviceicon-sc_googlecloud',
  'serviceicon-sc_wehaa',
  'serviceicon-sc_onspot',
  'serviceicon-sc_agentz',
  'serviceicon-yahoojapandisplay',
  'serviceicon-googlelocalservices',
  'serviceicon-tritonnextgen',
  'serviceicon-klaviyonativemetrics',
  'serviceicon-basisbcireports',
  'serviceicon-tapleads',
  'serviceicon-sc_clickhouse',
  'serviceicon-sc_alloydb',
  'serviceicon-applesearchads',
  'serviceicon-stackadapt',
  'serviceicon-redditads',
  'serviceicon-sc_vistar_media',
  'serviceicon-sc_reveal',
  'serviceicon-sc_advanced_web_ranking',
  'serviceicon-sc_ad_geo',
  'serviceicon-adelphic',
  'serviceicon-linkedinbusinessreports',
  'serviceicon-stripe',
  'serviceicon-newravenranktracking',
  'serviceicon-walmartconnectdisplay',
  'serviceicon-walmartconnect',
  'serviceicon-sc_pilotx',
  'serviceicon-sc_compulse',
];

export const FormSelectTypes = {
  FORM_SELECT: 'formSelect',
  FORM_MULTI_SELECT: 'formMultiSelect',
};

export const DataView = {
  CGN: 'cgn',
  CLIENT: 'client',
  GEO: 'geo',
};

export const UploadSummaryStatus = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const FileExtension = {
  HTML: '.html',
  PDF: '.pdf',
  XLS: '.xls',
  DOC: '.doc',
  PPT: '.ppt',
  POTX: '.potx',
  PNG: '.png',
  JPG: '.jpg',
  CSV: '.csv',
  TSV: '.tsv',
  XLSX: '.xlsx',
  ODS: '.ods',
  JSON: '.json',
  ZIP: '.zip',
  GZ: '.gz',
  PO: '.po',
};

export const FileEncoding = {
  UTF8: 'utf-8',
};

export const StringBoolean = {
  YES: 'yes',
  NO: 'no',
};

export const Locale = {
  EN: 'en_US',
  FR: 'fr_FR',
};

export const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
};

export const ExportType = {
  PDF: 'PDF',
  XLS: 'XLS',
  PPT: 'PPT',
};

export const GenericPosition = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const MessageType = {
  IS_WARNING: 'is-warning',
  IS_DANGER: 'is-danger',
  IS_INFO: 'is-info',
  IS_SUCCESS: 'is-success',
  IS_PRIMARY: 'is-primary',
};

export const MessageSize = {
  IS_SMALL: 'is-small',
  IS_DEFAULT: 'is-default',
  IS_MEDIUM: 'is-medium',
  IS_LARGE: 'is-large',
};

export const ThemeType = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const CommonKey = {
  ID: 'id',
  NAME: 'name',
  TYPE: 'type',
};

export const ActionIconType = {
  DEFAULT: 'default',
  DISABLED: 'disabled',
};

export const HIPAA_ACK_REQUIRED = 'hipaa_acknowledgement_required';
export const HUBSPOT_SDK_SCRIPT_ID = 'hubspot_sdk';
export const TWO_STEP_VERIFICATION_REQUIRED = 'two_step_verification_required';
