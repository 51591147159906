'use strict';

import angular from 'angular';
import _ from "lodash";

import widgetBuilderPanel from './widget.builder.panel.html';
import {
    WidgetBuilderConstants,
    $WidgetBuilderEvents
} from 'coreModules/design/widget/builder/widget.builder.constants';

angular.module('widget.builder.components', [])
    .component('widgetBuilderPanel', {
        templateUrl: widgetBuilderPanel,
        controllerAs: 'vm',
        bindings: {
            'pageId': '<',
            'reportId': '<'
        },
        controller: WidgetBuilderPanelController
    });

/**
 * @ngInject
 */
function WidgetBuilderPanelController(
    $scope,
    $state,
    $element,
    gettextCatalog,
    $timeout,
    PubSub,
    $SlidePanelEvents,
    WidgetBuilderUIService,
    WidgetBuilderService,
    AppFactory,
    DashboardContextService,
    BigNumberUIService,
    WidgetUtilService,
    ColumnFormat,
    WidgetType,
    $ExportBuilderDashboardEvents
) {
    const vm = this;
    vm.WidgetBuilderConstants = WidgetBuilderConstants;

    /**
     * @type {WidgetBuilderPanelUIModel}
     */
    vm.uiModel = WidgetBuilderUIService.getUIModel();

    /**
     * @type {WidgetBuilderPanelState}
     */
    vm.state = WidgetBuilderService.getPanelState();

    /**
     * @type {WidgetBuilderPanelData}
     */
    vm.data = null;

    /**
     * @type {boolean}
     */
    vm.canSave = true;

    /**
     * @type {WidgetBuilderModel}
     */
    vm.widgetModel = null;
    
    /**
     * Null on purpose to re-fetch connected services if needed for client specific
     * @type {Array}
     */
    vm.connectedServices = null;
    
    vm.isLoading = true;

    vm.$onInit = $onInit;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.getPanelTitle = getPanelTitle;
    vm.changeWidgetType = changeWidgetType;
    vm.onToggleTab = onToggleTab;
    vm.saveBuilder = saveBuilder;
    vm.cancelBuilder = cancelBuilder;
    vm.canShowPanel = canShowPanel;
    vm.goToConnectServices = goToConnectServices;
    vm.setLoading = setLoading;
    vm.canShowInitSpinner = canShowInitSpinner;
    vm.disableSave = disableSave;

    function $onInit() {
        angular.element('body').append($element);
        _registerEvents();
    }

    function $onDestroy() {
        $element.remove();
        _unregisterEvents();
    }

    /**
     * @param options
     * @private
     */
    async function _init(options = {}) {

        if(!canShowPanel()){
            _reset();
            vm.isLoading = false;
            WidgetBuilderService.setAsActive(true);
            WidgetBuilderService.setIsEditing(false);

            WidgetBuilderUIService.showPanel();
            return;
        }

        let _widgetModel = options.model;
        let _isEditingWidget = !_.isEmpty(_widgetModel);

        _reset();
        WidgetBuilderService.setAsActive(true);
        WidgetBuilderService.setIsDataWidget(options.isDataWidget);
        WidgetBuilderService.setIsEditing(_isEditingWidget);
        WidgetBuilderService.setIsFirstCreate(!_isEditingWidget);
        WidgetBuilderService.setIsNewWidget(!_isEditingWidget);

        WidgetBuilderUIService.showPanel();

        await verifyConnectedServicesIfNeeded();
        
        if (_isEditingWidget) {
            WidgetBuilderService.storeOriginalWidgetModel(_widgetModel);
            WidgetBuilderService.setWidgetModel(_widgetModel);
            vm.widgetModel = WidgetBuilderService.getWidgetModel();
        }

        let _widgetType = vm.widgetModel ? vm.widgetModel.type : null;
        WidgetBuilderService.setPanelData(_widgetType);
        vm.data = WidgetBuilderService.getPanelData();

        // Default to data tab
        $timeout(function() {
            PubSub.$emit($WidgetBuilderEvents.INIT_DATA_TAB);
        }, 0);
    }

    /**
     * @returns {string}
     */
    function getPanelTitle() {
        return vm.state.isEditing
            ? gettextCatalog.getString('Edit Widget')
            : gettextCatalog.getString('New Widget');
    }

    /**
     * @returns {boolean}
     */
    function disableSave() {
        if(!vm.canSave) return true;

        const widgetModel = WidgetBuilderService.getWidgetModel();

        if(!_.isNil(widgetModel)){
            const [firstSelected, secondSelected] = widgetModel.metadata.data_columns.selected;

            if (widgetModel.type === WidgetType.GAUGECHART &&
                firstSelected &&
                secondSelected) {
                return (firstSelected.format === ColumnFormat.FORMAT_TIME && secondSelected.format !== ColumnFormat.FORMAT_TIME)
                    || (secondSelected.format === ColumnFormat.FORMAT_TIME && firstSelected.format !== ColumnFormat.FORMAT_TIME)
            }

            if (widgetModel.type === WidgetType.BIGNUMBER && !firstSelected) {
                return true;
            }

            if (WidgetUtilService.isEmbeddedSparklinesPlotType(widgetModel.metadata.draw_options)) {
                return _.some(widgetModel.metadata.data_columns.grouped, (column) => {
                    return column.format === ColumnFormat.FORMAT_DATETIME || column.format === ColumnFormat.FORMAT_DATE;
                });
            }

            if (
              WidgetUtilService.isGroupedColumnPlotType(
                widgetModel.metadata.draw_options
              )
            ) {
              return (
                WidgetUtilService.hasEmptyFieldGroups(widgetModel.metadata) ||
                WidgetUtilService.hasEmptyFieldGroupName(
                  widgetModel.metadata
                ) ||
                WidgetUtilService.hasEmptyFieldGroupMetric(widgetModel.metadata)
              );
            }

            if (
                WidgetUtilService.isConditionalPlotType(
                    widgetModel.metadata.draw_options
                )
            ) {
                return (
                    WidgetUtilService.hasEmptyConditionalGroups(widgetModel.metadata) ||
                    WidgetUtilService.hasEmptyConditionalGroupValue(
                        widgetModel.metadata
                    ) ||
                    WidgetUtilService.hasEmptyConditionalGroupRule(widgetModel.metadata)
                );
            }
        }

        return false;
    }

    /**
     * @returns {Promise<void>}
     */
    async function verifyConnectedServicesIfNeeded() {
        if (DashboardContextService.isDemoModeEnabled()) {
            vm.connectedServices = AppFactory.getAllServices();
            return;
        }
        
		if (!DashboardContextService.pageHasActiveFilter()) {
			vm.connectedServices = !_.isEmpty(DashboardContextService.resolveSelectedEntity())
				? await WidgetBuilderService.retrieveConnectedServices(true)
				: AppFactory.getConnectedServices(true);
		} else {
			vm.connectedServices = await WidgetBuilderService.retrieveConnectedServices(true);
		}
	}
    
    /**
     * @param widgetType
     */
    function changeWidgetType(widgetType) {
        _updateWidgetType(widgetType);
        WidgetBuilderService.refreshStylesTab();
        WidgetBuilderService.refreshDataTabColumns(widgetType);
        WidgetBuilderService.updateWidgetDisplay();
    }

    /**
     * @param widgetType
     * @private
     */
    function _updateWidgetType(widgetType) {
        if (widgetType.id === WidgetType.BIGNUMBER) {
            setWidgetModelDrawOptions();
        }
        WidgetBuilderService.resetMapboxLayers();
        WidgetBuilderService.setSelectedWidgetType(widgetType);
        WidgetBuilderService.setWidgetType(widgetType.id);
        WidgetBuilderService.setDefaultPlotType(widgetType.id, vm.widgetModel?.metadata?.type, vm.widgetModel?.metadata?.draw_options?.plot_type);
        if (!WidgetUtilService.isGeoChart(widgetType.id)) {
            WidgetBuilderService.resetGeoCode();
        }
    }

    function setWidgetModelDrawOptions() {
        const widgetModel = WidgetBuilderService.getWidgetModel();
        if (widgetModel) {
            let drawOptions = BigNumberUIService.getDefaultDrawOptions(widgetModel);
            drawOptions = _.assign(widgetModel.metadata.draw_options, drawOptions);
            WidgetBuilderService.setDrawOptions(drawOptions);
        }
    }

    /**
     * @param widgetType
     * @private
     */
    function _updateWidgetTypeWithoutPlotType(widgetType){
        WidgetBuilderService.setSelectedWidgetType(widgetType);
        WidgetBuilderService.setWidgetType(widgetType.id);
        if (!WidgetUtilService.isGeoChart(widgetType.id)) {
            WidgetBuilderService.resetGeoCode();
        }
    }

    /**
     * @param index
     */
    function onToggleTab(index) {
        WidgetBuilderUIService.toggleTab(index);

        switch (index) {
            case WidgetBuilderConstants.DATA_TAB_INDEX:
                PubSub.$emit($WidgetBuilderEvents.INIT_DATA_TAB);
                break;

            case WidgetBuilderConstants.STYLES_TAB_INDEX:
                PubSub.$emit($WidgetBuilderEvents.INIT_STYLES_TAB);
                break;

            case WidgetBuilderConstants.LIBRARY_TAB_INDEX:
                PubSub.$emit($WidgetBuilderEvents.INIT_LIBRARY_TAB);
                break;
        }

        $timeout(function () {
            $scope.$broadcast('rzSliderForceRender');
        });
    }

    function _reset() {
        vm.data = null;
        WidgetBuilderService.resetPanelState();
        vm.state = WidgetBuilderService.getPanelState();
        WidgetBuilderUIService.resetUIModel();
        vm.uiModel = WidgetBuilderUIService.getUIModel();
        vm.connectedServices = null;
    }

    function _closePanel() {
        WidgetBuilderUIService.hidePanel();
    }

    function saveBuilder() {
        _closePanel();
        if (WidgetBuilderService.getIsNewWidget()) {
            WidgetBuilderService.setIsNewWidget(false)
        }
        WidgetBuilderService.saveWidget();
        _reset();
    }

    function cancelBuilder() {
        _closePanel();
        if (WidgetBuilderService.getIsNewWidget()) {
            WidgetBuilderService.setIsNewWidget(false)
        }
        if(canShowPanel()){
            WidgetBuilderService.cancelWidget();
        }
        _reset();
    }

    function canShowPanel() {
        const { client_id: clientId } = DashboardContextService.resolvePageEntityQueryParam();
        const websites = AppFactory.getSeoWebsites();
        const clientWebsites = websites.filter( website => website.clientId == clientId );
        return !(vm.connectedServices && vm.connectedServices.length === 0) || clientWebsites.length > 0;
    }
    
    function canShowInitSpinner() {
        return _.isNil(vm.connectedServices);
    }

    function goToConnectServices() {
        window.location.href = AppFactory.getMdsRoute();
    }

    function setLoading(value) {
        vm.isLoading = value;
    }

    /**
     * @param sourcePanel
     * @private
     */
    function _onPanelCloseAll(sourcePanel) {
        if (sourcePanel !== vm.uiModel.panelId) {
            _closePanel();
            _reset();
        }
    }

    /**
     * @param value
     * @private
     */
    function _updateBuilderSaveStatus(value) {
        vm.canSave = value;
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($ExportBuilderDashboardEvents.UPDATE_WIDGET_BUILDER_ACTIONS, _updateBuilderSaveStatus);
        PubSub.on($WidgetBuilderEvents.INIT_PANEL, _init);
        PubSub.on($WidgetBuilderEvents.UPDATE_WIDGET_TYPE, _updateWidgetType);
        PubSub.on($WidgetBuilderEvents.UPDATE_WIDGET_TYPE_WITHOUT_PLOT, _updateWidgetTypeWithoutPlotType);
        PubSub.on($SlidePanelEvents.CLOSE_ALL, _onPanelCloseAll);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($ExportBuilderDashboardEvents.UPDATE_WIDGET_BUILDER_ACTIONS, _updateBuilderSaveStatus);
        PubSub.off($WidgetBuilderEvents.INIT_PANEL, _init);
        PubSub.off($WidgetBuilderEvents.UPDATE_WIDGET_TYPE, _updateWidgetType);
        PubSub.off($SlidePanelEvents.CLOSE_ALL, _onPanelCloseAll);
    }
}