export const RouteGroup = {
  /* ALPHABETICAL ORDER PLEASE */
  ALERTS: 'alerts',
  ANOMALIES: 'anomalies',
  AUDIENCES: 'audience/activeaudiences?sort=-created_at',
  AUDIENCES_ARCHIVE: 'audience/archivedaudiences',
  AUDIENCES_UPLOAD_NEXT_FILE_AUDIENCE_CHUNK: 'audience/uploadNextFileAudienceChunk',
  AUDIENCES_UPLOAD_NEXT_SUPPRESSION_CHUNK: 'audience/uploadNextSuppressionChunk',
  AUDITLOGS: 'auditlogs',
  ANNOTATION: 'annotation',
  ASYNC: 'async',
  BADGES: 'badges',
  BILLING: 'billing',
  BUNDLES: 'bundles',
  BILLING_ADDRESS: 'billing/address',
  BILLING_CREDITCARD: 'billing/creditcard',
  BILLING_OVERVIEW: 'billing/overview',
  BILLING_BILLINGDETAILS: 'billing/billingdetails',
  BILLING_CUSTOMIZE: 'billing/customize',
  BILLING_INVOICES: 'billing/invoices',
  BILLING_MANAGEPLANS: 'billing/manageplans',
  BUDGETPACINGS: 'budgetpacings',
  CALCULATIONS: 'calculations',
  CANCELLATIONREASONS: 'cancellationreasons',
  CATEGORIES: 'categories',
  CATEGORIES_DRILLDOWN: 'categories/drilldown',
  CATEGORIES_OVERVIEW: 'categories/overview',
  CATEGORYDATA: 'categorydata',
  CATALOGUESETTINGS: 'cataloguesettings',
  CHATGPTSUMMARIES: 'chatgptsummaries',
  CHATGPTSUMMARIES_ITEMS: 'chatgptsummaryitems',
  CLIENT_FORMS: 'clientforms',
  CLIENTCATEGORIES: 'clientcategories',
  CLIENTGROUPS: 'clientgroups',
  CLIENTSPRODUCTS: 'clientsproducts',
  CLIENTS: 'clients',
  CLUSTERACCESSS: 'clusteraccesss',
  CLUSTERMEMBERS: 'clustermembers',
  CLUSTERS: 'clusters',
  COLUMNS: 'columns',
  COMMENT: 'comment',
  CONNECTIONSTATUS: 'connectionstatus',
  CUSTOM_TRANSLATION_FORM: 'customtranslationsform:new',
  DATALOADTATUS: 'dataloadstatus',
  DATALOADTATUS_DATA_LOAD: 'dataloadstatus/dataload',
  DATALOADTATUS_DAILY_LOAD: 'dataloadstatus/dailyload',
  DATALOADTATUS_DAILY_LOAD_STAT: 'dataloadstatus/summary/dailyloadstat',
  DATALOADTATUS_MAPPING_STATUS: 'dataloadstatus/mappingstatus',
  DATALOADTATUS_MAPPING_STATUS_BY_DATA_SOURCE: 'dataloadstatus/mappingstatus/summary/datasource',
  CONTENTSETS: 'contentsets',
  CONTENTVALUES: 'contentvalues',
  CREATIVES: 'creatives',
  DASH: 'dash',
  DASHBOARDS: 'dashboards',
  DASH_WIDGETS: 'dash/widgets',
  DASH_DASHBOARDFILTERS: 'dash/dashboardfilters',
  DATAEXPORTERS: 'dataexporters',
  DATAEXPORTERLOGS: 'dataexporterlogs',
  DATAEXPORTER_DAILY_EXPORT_STAT: 'dataexporterlogs/dailyexportstat',
  DATASOURCES: 'datasources',
  DATAVIEWS: 'dataviews',
  DATERANGE: 'daterange',
  DESIGNS: 'designs',
  DASH_PAGES: 'dash/pages',
  DASH_LAYOUTS: 'dash/layouts',
  DASH_FOLDERS: 'dashfolders',
  DASH_RECENT_FOLDERS: 'dashrecentfolders',
  DASH_FILES: 'dashfiles',
  DRILLDOWNS: 'drilldowns',
  EDITSETTINGS: 'editsettings',
  ENDCUSTOMERS: 'endcustomers',
  ENTITYFORMS: 'entityforms',
  ENTITYFORM_LINEITEMS: 'entityforms/product',
  ENTITYFIELDS: 'entityfields',
  ENTITYGROUPS: 'entitygroups',
  ENTITYSTEPS: 'entitysteps',
  ENTITY_LINEITEMS: 'entity/lineitems',
  ENTITY_ORDERS: 'entity/orders',
  ENTITY_ORDERCARTS: 'entity/ordercarts',
  ENTITYITEMS: 'entityitems',
  EXECUTIVESUMMARIES: 'executivesummaries',
  EXECUTIVESUMMARIES_ITEMS: 'executivesummaries/items',
  EXPORT: 'export',
  FILE_TAGS: 'creatives/tags',
  GENERIC: 'generic',
  GENERIC_DATA: 'generic_data',
  GLOBAL_FILTERS: 'globalfilters',
  GOALS: 'goals',
  GRANULAR_PERMISSIONS: 'granularpermissions',
  FEEDBACKS: 'feedbacks',
  HISTORY: 'history',
  HUB: 'hub',
  FILTERSETS: 'filtersets',
  IMPORTWIZARD: 'importwizard',
  IOMASTER: 'iomaster',
  IOS: 'ios',
  IMPORT: 'import',
  THUMBNAIL_LOGS: 'thumbnail/logs',
  ISPIONAGE_RESEARCH_AD_OTHER_KEYWORDS: 'ispionage/research/ad/other_keywords',
  ISPIONAGE_RESEARCH_DOMAIN_COUNTS: 'ispionage/research/domain/counts',
  ISPIONAGE_RESEARCH_DOMAIN_COMPARE_OVERLAP: 'ispionage/research/domain/compare/overlap',
  ISPIONAGE_RESEARCH_DOMAIN_COMPARE_KEYWORDS: 'ispionage/research/domain/compare/keywords',
  ISPIONAGE_RESEARCH_DOMAIN_COMPARE_STATS: 'ispionage/research/domain/compare/stats',
  ISPIONAGE_RESEARCH_DOMAIN_PPC_COMPETITORS: 'ispionage/research/domain/ppc/competitors',
  ISPIONAGE_RESEARCH_DOMAIN_PPC_KEYWORDS: 'ispionage/research/domain/ppc/keywords',
  ISPIONAGE_RESEARCH_DOMAIN_PPC_KEYWORD_ADS: 'ispionage/research/domain/ppc/keyword/ads',
  ISPIONAGE_RESEARCH_DOMAIN_PPC_ADS: 'ispionage/research/domain/ppc/ads',
  ISPIONAGE_RESEARCH_DOMAIN_PPC_LPS: 'ispionage/research/domain/ppc/lps',
  ISPIONAGE_RESEARCH_DOMAIN_PPC_COMPETITOR_LPS: 'ispionage/research/domain/ppc/competitor/lps',
  ISPIONAGE_RESEARCH_DOMAIN_SEO_COMPETITORS: 'ispionage/research/domain/seo/competitors',
  ISPIONAGE_RESEARCH_DOMAIN_SEO_GROUPS: 'ispionage/research/domain/seo/groups',
  ISPIONAGE_RESEARCH_DOMAIN_SEO_GROUP_KEYWORDS: 'ispionage/research/domain/seo/group/keywords',
  ISPIONAGE_RESEARCH_DOMAIN_SEO_KEYWORDS: 'ispionage/research/domain/seo/keywords',
  ISPIONAGE_RESEARCH_DOMAIN_SUMMARY_PPC_COUNTS: 'ispionage/research/domain/summary/ppc/counts',
  ISPIONAGE_RESEARCH_DOMAIN_SUMMARY_SEO_COUNTS: 'ispionage/research/domain/summary/seo/counts',
  ISPIONAGE_RESEARCH_DOMAIN_SUMMARY_PPC_MONTHLY: 'ispionage/research/domain/summary/ppc/monthly',
  ISPIONAGE_RESEARCH_DOMAIN_SUMMARY_SEO_MONTHLY: 'ispionage/research/domain/summary/seo/monthly',
  ISPIONAGE_RESEARCH_DOMAIN_BACKLINKS_SUMMARY: 'ispionage/research/domain/backlinks/summary',
  ISPIONAGE_RESEARCH_DOMAIN_BACKLINKS_DATA: 'ispionage/research/domain/backlinks/data',
  ISPIONAGE_RESEARCH_KEYWORD_RELATED: 'ispionage/research/keyword/related',
  ISPIONAGE_RESEARCH_KEYWORD_SEO_COMPETITORS: 'ispionage/research/keyword/seo/competitors',
  ISPIONAGE_RESEARCH_KEYWORD_PPC_ADVERTISERS: 'ispionage/research/keyword/ppc/advertisers',
  ISPIONAGE_RESEARCH_KEYWORD_PPC_ADS: 'ispionage/research/keyword/ppc/ads',
  ISPIONAGE_RESEARCH_KEYWORD_SUMMARY: 'ispionage/research/keyword/summary',
  ISPIONAGE_RESEARCH_SUGGEST: 'ispionage/research/suggest',
  ISPIONAGE_RESEARCH_ROWLIMIT: '/ispionage/research/rowlimit',
  ISPIONAGE_RESEARCH_USAGELIMIT: '/ispionage/research/usagelimit',
  ISPIONAGE_RESEARCH_HISTORICAL: '/ispionage/research/historical',
  LANGUAGES: 'languages',
  LAYOUTS: 'layouts',
  LAYOUTTAGS: 'layouttags',
  LEADS: 'leads',
  LEADNOTIFICATIONS: 'leadnotifications',
  LIBRARY: 'library',
  LINEITEMS: 'lineitems',
  LINEITEMSREPORTS: 'lineitemsreports',
  LINEITEMSWITHTASKSREPORTS: 'lineitemswithtasksreports',
  LINEITEMTASKSREPORTS: 'lineitemtasksreports',
  LIBRARY_TEMPLATE: 'library/template',
  LOGIN: 'login',
  LOG: 'log',
  MAPPING_CANDIDATE_HISTORY: 'mappingcandidatehistory',
  MARGINRULES: 'marginrules',
  NAMINGCONVENTIONS_CUSTOM: 'namingconventions/custom',
  NAMINGCONVENTIONS: 'namingconventions',
  NAVMAIN: 'navmain',
  NOTES: 'notes',
  NOTIFBROADCAST: 'notifbroadcast',
  NOTIFTARGETED: 'notiftargeted',
  NOTIFICATIONPROFILES: 'notificationProfiles',
  OAUTH: 'oauth',
  OAUTH_CLIENTS: 'oauth/clients',
  OAUTH_AUTHORIZATIONUSER: 'oauth/authorizationusers',
  OAUTHUSERCLUSTERS: 'oauthuserclusters',
  OAUTHUSERS: 'oauthusers',
  OBJECTIVES: 'objectives',
  ORDERS: 'orders',
  ORDERSREPORTS: 'ordersreports',
  ORDERSWITHTASKSREPORTS: 'orderswithtasksreports',
  ORDERTASKSREPORTS: 'ordertasksreports',
  PRODUCTS: 'products',
  PAGES: 'pages',
  PAGETAGS: 'pagetags',
  PREFERENCES: 'preferences',
  PLATFORMSEARCH: 'platformsearch',
  PRODUCT_FORMS: 'productforms',
  PROJECT_FORMS: 'orderforms',
  PROPOSALS: 'proposals',
  QUEUES: 'queues',
  QUEUE: 'queue',
  INSIGHTS_HQ: 'insightshq',
  INSIGHTS_HQ_FILTERS: 'insightshq/filters',
  REPORTINGPROFILES: 'reportingprofiles',
  REPORTSTUDIO: 'reportstudio',
  RELATIONSHIPS: 'relationships',
  REPORTSTUDIO_LIBRARY: 'reportstudio/library',
  REPORTINGTEMPLATES: 'reportingtemplates',
  RESEARCH: '/raven/research',
  ROLES: 'roles',
  SAMPLE: 'sample',
  SAVECOLUMNPREFERENCES: 'savecolumnpreferences',
  SCHEDULEDREPORTS: 'scheduledreports',
  SERVICECLIENTMAPPING: 'serviceclientmapping',
  SERVICECLIENTMAPPINGS: 'serviceclientmappings',
  SERVICECLIENTMAPPING_LOG_DETAIL: 'serviceclientmapping/log/detail',
  SERVICECLIENTMAPPING_LOG: 'serviceclientmapping/log',
  SERVICECLIENTMAPPING_TEST: 'serviceclientmapping/test',
  SERVICELOOKUPS: 'servicelookups',
  SERVICES: 'services',
  SERVICESETTINGS: 'servicesettings',
  SERVICES_SETTINGS: 'services/settings',
  SERVICES_DRILLDOWN: 'services/drilldown',
  SERVICES_OVERVIEW: 'services/overview',
  SERVICES_ORDERITEMS: 'services/orderitems',
  SERVICES_ORDERS: 'services/orders',
  SERVICES_MANAGE: 'services/manage',
  SERVICE_MANAGE: 'service/manage',
  SERVICE_MAPPINGS: 'service/mappings',
  SERIVCECAMPAIGNS: 'service',
  SEO_ONBOARD_CHECKLIST: 'seo/onboard/checklist',
  SEO_WEBSITES: 'seo/websites',
  SEO_WEBSITES_USAGE: 'seo/websites/usage',
  SERP: 'serp',
  SERP_OVERVIEW: 'serp/overview',
  SERP_SEARCH_LOCATIONS: 'serp/search/locations',
  SERP_SUGGEST_KEYWORDS: 'serp/suggest/keywords',
  SERP_SUGGEST_ENGINES: 'serp/suggest/engines',
  SERP_KEYWORD_USAGE: 'serp/keyword-usage',
  SESSION: 'session',
  SITEAUDITOR: 'siteauditor',
  SITEAUDITOR_OVERVIEW: 'siteauditor/overview',
  SITEAUDITOR_SITES: 'siteauditor/sites',
  SITEAUDITOR_PAGESPEED: 'siteauditor/pagespeed',
  SITEAUDITOR_CRAWLSESSIONS: 'siteauditor/crawlsessions',
  SITEAUDITOR_ISSUES: 'siteauditor/issues',
  SITEAUDITOR_PAGES: 'siteauditor/pages',
  SMARTCAMPAIGNS: 'smartcampaigns',
  STATUSES: 'statuses',
  STATUSTEMPLATES: 'statustemplates',
  SUMMARYCOLUMNS: 'summarycolumns',
  SUMMARYCSV: 'summarycsv',
  TAGS: 'tags',
  TASK_STATUSES: 'task/statuses',
  TASK: 'task',
  TASK_FORMS: 'taskforms',
  TASKS: 'tasks',
  TASKTAGS: 'tasktags',
  TASKTEMPLATES: 'tasktemplates',
  TASKTEMPLATEACTIONS: 'tasktemplateactions',
  TAGVALUES: 'tagvalues',
  TESTGOALS: 'sup/goals',
  THEMEBUILDER: 'themebuilder',
  TIMEGROUPINGS: 'timegroupings',
  CUSTOM_TRANSLATIONS: 'customtranslations',
  USERS: 'users',
  USERIMAGES: 'userimages',
  USERLOGINS: 'userlogins',
  UPDATE_THUMBNAIL: 'updatethumbnail',
  WEBHOOKS: 'webhooks',
  WIDGETS: 'widgets',
  WIDGETTAGS: 'widgettags',
  WORKFLOWACTIONS: 'workflowactions',
  /* ALPHABETICAL ORDER PLEASE */
};

export const LegacyRouteGroup = {
  CLIENT: 'client',
  GETLOGOURL: 'api/getLogoUrl',
  REPORTINGTEMPLATE: 'reportingTemplate',
  REPORTING_GETNEXTSENDDATES: 'reporting/get_next_send_dates',
  REPORTING_DOWNLOADCSVSCHEDULEDREPORTLIST: 'reporting/download_csv_scheduled_report_list',
  APEXTRANSCRIPTVIEWER_TRANSCRIPT: 'apextranscriptviewer/transcript',
};

/**
 * These are the frontend routes which don't have a backend
 * route or differently named backend route.
 * Eg: server/api/dash/1 can be /dashboards/1 in front end
 */
export const PseudoRoute = {
  WELCOME: 'welcome',
  ONBOARDING: 'onboarding',
  RAVEN_WEBSITES: 'raven/websites',
  RAVEN_GETTING_STARTED: 'raven/gettingstarted',
  SHEETS_GETTINGSTARTED: 'sheets/gettingstarted',
  SHEETS_TEMPLATES: 'sheets/templates',
  GDS_GETTINGSTARTED: 'gds/gettingstarted',
};

export const EntityName = {
  AUDIENCES: 'audiences',
};

export const DataQuery = {
  TYPE_CONCAT: 'concat',
  TYPE_GROUP_CONCAT: 'group_concat',
  TYPE_JSON_CONCAT: 'json_concat',
  TYPE_DISTINCT_GROUP_CONCAT: 'distinct_group_concat',
  TYPE_JSON_OBJ_AGGREGATE: 'json_obj_aggregate',
  TYPE_CONCAT_MULTI_COLUMN: 'concat_multi_column',

  // Numeric query types
  TYPE_COUNT: 'count',
  TYPE_COUNT_DISTINCT: 'count_distinct',
  TYPE_SUM: 'sum',
  TYPE_AVERAGE: 'average',
  TYPE_PERCENTAGE: 'percentage',
  TYPE_SUM_OVER_SUM: 'sumoversum',
  TYPE_MAX: 'max',

  TYPE_CUSTOM: 'custom',
};

export const ResponseType = {
  CODE: 'code',
};
