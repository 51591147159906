import angular from 'angular';
import $ from 'jquery';
import _ from "lodash";
import hiddenIcon from '../../../../images/view-off.svg';
import exportBuilderPageAssignmentModalHtmlUrl from './exportbuilder.pageassignment.modal.html';

angular.module('exportbuilder.dashboard.components')
    .component('exportBuilderPageAssignmentModal', {
        bindings: {
        },
        templateUrl: exportBuilderPageAssignmentModalHtmlUrl,
        controller: ExportBuilderAssignDatasourceModal,
        controllerAs: 'vm'
    });

function ExportBuilderAssignDatasourceModal(
    $ExportBuilderPageAssignmentModalEvents,
    $ExportBuilderDashboardEvents,
    ExportBuilderPageAssignmentModalUIService,
    ExportBuilderDashboardPageService,
    ReportStudioTemplateDataService,
    ExportBuilderDashboardService,
    ExportBuilderElementActionService,
    ExportBuilderPageAssignmentType,
    ExportBuilderResource,
    ManageProductsService,
    AppModelFactory,
    gettextCatalog,
    DataSourceType,
    AppFactory,
    AppModule,
    PubSub
){
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onClose = onClose;
    vm.serviceState = ExportBuilderPageAssignmentModalUIService.getState();
    vm.onDatasourceChange = onDatasourceChange;
    vm.onProductChange = onProductChange;
    vm.showServiceSelect = showServiceSelect;
    vm.showCategorySelect = showCategorySelect;
    vm.dataSourceOnChange = dataSourceOnChange;
    vm.categoryOnChange = categoryOnChange;
    vm.setDataSourceToLayout = setDataSourceToLayout;
    vm.setCategoryToLayout = setCategoryToLayout;
    vm.onChangeToNone = onChangeToNone;
    vm.productOnChange = productOnChange
    vm.setProductsToLayout = setProductsToLayout;
    vm.onSaveClicked = onSaveClicked;
    vm.onTitleClicked = onTitleClicked;
    vm.onClearAllAssignments = onClearAllAssignments;
    vm.getHiddenIcon = getHiddenIcon;



    vm.dataCategoryFilterOptions = [DataSourceType.SERVICE_DATA, DataSourceType.CATEGORY_DATA];

    vm.dataSourcesSelectOptions = {
        autoOpen: true,
        loaded: false,
        allowClear: true,
        closeOnSelect: true,
        showAll: true
    };

    vm.canSelectProduct = AppFactory.getUser().isSuperAdmin();

    if (vm.canSelectProduct) {
        vm.dataCategoryFilterOptions.push(DataSourceType.PRODUCT);
        let options = {
            multiple: true,
            label: gettextCatalog.getString('Product'),
            placeholder: gettextCatalog.getString('Select Products...'),
            width: '100%',
            values: [],
            selectedValues: [],
            loaded: false,
            formatResult: function (item) {
                return item.text;
            },
            formatSelection: function (item) {
                return item.id_name || item.text || item.name;
            },
            getDataCall: function() {
                return ManageProductsService.getAllProducts();
            },
            formatData: function(data) {
                this.values = _.map(data, datum => {
                    return {
                        id: datum.id,
                        text: datum.name
                    }
                });
                this.loaded = true;
            }
        };
        vm.productSelectOptions = AppModelFactory.getSelectOptions(options);
    }

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function onClose() {
        ExportBuilderPageAssignmentModalUIService.setIsActive(false);
        vm.pages = null;
    }

    function onTitleClicked(page) {
        vm.selectedPage = page;
        if (_.isEmpty(page)) {
            page.metadata = {};
        }
        switch(page.metadata.assignment_type) {
            case ExportBuilderPageAssignmentType.DATA_CATEGORY:
                vm.isShowingProductSelectForPage = false;
                vm.isShowingDataCategorySelectForPage = true;
                break;
            case ExportBuilderPageAssignmentType.PRODUCT:
                vm.isShowingProductSelectForPage = vm.canSelectProduct;
                vm.isShowingDataCategorySelectForPage = false;
                break;
            default:
                vm.isShowingProductSelectForPage = false;
                vm.isShowingDataCategorySelectForPage = false;
        }
    }

    function getHiddenIcon() {
        return hiddenIcon;
    }


    function onSaveClicked() {
        PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_ASSIGNMENT, true);
        vm.pages = vm.pages.map((page) => {
            if (page.metadata.assignment_type && !(!_.isEmpty(page.metadata.assigned_values) && page.metadata.assigned_values[0].type)) {
                delete page.metadata.assignment_type;
                delete page.metadata.assigned_values;
            }
            return page;
        });
        ExportBuilderElementActionService.assignDatasourcesToPages(vm.pages);
        onClose();
    }

    function _onOpen(page = {}) {
        let clonedPage = _.isEmpty(page) ? page : _.cloneDeep(page);
        vm.report = ReportStudioTemplateDataService.getReport();
        vm.pages = _.cloneDeep(vm.report.pages);
        vm.onTitleClicked(clonedPage);
        ExportBuilderPageAssignmentModalUIService.setIsActive(true);
    }

    function onChangeToNone() {
        vm.isShowingProductSelectForPage = false;
        vm.isShowingDataCategorySelectForPage = false;
    }

    function onDatasourceChange(page) {
        vm.isShowingProductSelectForPage = false;
        vm.isShowingDataCategorySelectForPage = true;
        vm.selectedPage = page;
        if (_.isEmpty(page.metadata.assigned_values) || (page.metadata.assigned_values[0] && page.metadata.assigned_values[0].type === DataSourceType.PRODUCT)) {
            page.metadata.assigned_values = [{}];
        }
    }

    function onProductChange(page) {
        vm.isShowingDataCategorySelectForPage = false;
        vm.isShowingProductSelectForPage = vm.canSelectProduct;
        vm.selectedPage = page;
        if ( _.isEmpty(page.metadata.assigned_values) || _.isEmpty(page.metadata.assigned_values[0])) {
            page.metadata.assigned_values = [];
        }
        if (!_.isEmpty(page.metadata.assigned_values[0]) && page.metadata.assigned_values[0].type !== DataSourceType.PRODUCT) {
            page.metadata.assigned_values = [];
        }
    }

    function showServiceSelect(page) {
        return !_.isEmpty(page.metadata.assigned_values) && !_.isEmpty(page.metadata.assigned_values[0]) && page.metadata.assigned_values[0].type === DataSourceType.SERVICE_DATA;
    }

    function showCategorySelect(page) {
        return !_.isEmpty(page.metadata.assigned_values) && !_.isEmpty(page.metadata.assigned_values[0]) && page.metadata.assigned_values[0].type === DataSourceType.CATEGORY_DATA;
    }

    function updatePage(page) {
        if (page) {
            let pageIndex = vm.pages.findIndex((item) => item.id === page.id );
            if (pageIndex !== -1) {
                vm.pages[pageIndex].metadata.assigned_values = page.metadata.assigned_values;
            }
        }
    }


    function dataSourceOnChange($el, event) {
        let page;
        if ($el) {
            page = setDataSourceToLayout($el.id);
        } else {
            page = setDataSourceToLayout(null);
        }
        updatePage(page);
    }

    function categoryOnChange($el, event) {
        let page;
        if ($el) {
            page = setCategoryToLayout($el.id);
        } else {
            page = setCategoryToLayout(null);
        }
        updatePage(page);
    }

    function productOnChange($el) {
        let val = $el ? $el.select2('data') : null;
        if (!_.isNull(val)) {
            let ids = _.map(val, 'id');
            let page = setProductsToLayout(ids);
            updatePage(page);
        }
    }

    function setDataSourceToLayout(dataSourceId) {
        let page = vm.selectedPage;
        if (!page) return;

        if (!dataSourceId) {
            page.metadata.assigned_values = [{}];
            return page;
        }

        const dataSource = _.find(AppFactory.getConnectedServices(), {id: dataSourceId});
        page.metadata.assigned_values[0] = angular.copy(dataSource);
        return page;
    }

    function setCategoryToLayout(categoryId) {
        let page = vm.selectedPage;
        if (!page) return;

        if (!categoryId) {
            page.metadata.assigned_values = [{}]
            return page;
        }

        var dataSource = _.find(AppFactory.getConnectedCategories(), {id: categoryId});
        page.metadata.assigned_values[0] = angular.copy(dataSource);
        return page;
    }

    function setProductsToLayout(productIds) {
        let page = vm.selectedPage;
        if (!page) return;

        if (_.isEmpty(productIds)) {
            page.metadata.assigned_values = [];
        }

        let dataSources = _.filter(ManageProductsService.getProducts(), product => _.includes(productIds, product.id));
        page.metadata.assigned_values = angular.copy(dataSources);
        return page;
    }

    function onClearAllAssignments() {
        vm.pages = vm.pages.map((page) => {
                page.metadata.assignment_type = null;
                page.metadata.isHidden = false;
                delete page.metadata.assigned_values;
                return page;
        });
        vm.isShowingProductSelectForPage = false;
        vm.isShowingDataCategorySelectForPage = false;
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderPageAssignmentModalEvents.OPEN, _onOpen);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderPageAssignmentModalEvents.OPEN, _onOpen);
    }

}