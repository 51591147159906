'use strict';
import angular from 'angular';

export const RelativeDateRange = {
    CUSTOM: 'custom',
    DEFAULT: 'default',
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    TOMORROW: 'tomorrow',
    LAST_7_DAYS: 'last7days',
    LAST_14_DAYS: 'last14days',
    LAST_30_DAYS: 'last30days',
    LAST_90_DAYS: 'last90days',
    LAST_180_DAYS: 'last180days',
    LAST_WEEK: 'lastweek',
    LAST_MONTH: 'lastmonth',
    LAST_3_MONTHS: 'last3months',
    LAST_QUARTER: 'lastquarter',
    LAST_6_MONTHS: 'last6months',
    LAST_12_MONTHS: 'last12months',
    LAST_YEAR: 'lastyear',
    LAST_13_MONTHS: 'last13months',
    YEAR_TO_DATE: 'yeartodate',
    THIS_WEEK: 'thisweek',
    THIS_MONTH: 'thismonth',
    THIS_MONTH_EXCLUDE_TODAY: 'thismonthexcludetoday',
    THIS_QUARTER: 'thisquarter',
    THIS_YEAR: 'thisyear',
    NEXT_7_DAYS: 'next7days',
    NEXT_30_DAYS: 'next30days',
    NEXT_WEEK: 'nextweek',
    NEXT_MONTH: 'nextmonth',
    NEXT_YEAR: 'nextyear',
    ALL_TIME: 'alltime',
    PRIOR_PERIOD: 'priorperiod',
    FUTURE_PERIOD: 'futureperiod',
    PRIOR_MONTH: 'priormonth',
    PRIOR_YEAR: 'prioryear'
};

export const $DateRangeEvents = {
    DATE_RANGE_CHANGE: '$DateRangeEvents:DATE_RANGE_CHANGE',
    COMPARISON_DATE_RANGE_CHANGE: '$DateRangeEvents:COMPARISON_DATE_RANGE_CHANGE',
    DID_SET_WIDGET_DATE_RANGE: '$DateRangeEvents:DID_SET_WIDGET_DATE_RANGE'
};

export const MomentDateFormat = {
    UNIX: 'X',
    ISO: 'YYYY-MM-DD',
    MONTH_DAY_YEAR: 'MMM DD, YYYY',
    DAY_MONTH_YEAR: 'DD MMM YYYY',
    TIME: 'HH:mm:ss',
    ISO_TIME: 'YYYY-MM-DD HH:mm:ss',
    DAYOFWEEK_MONTH_DAY_YEAR: 'ddd MMM DD YYYY',
    MONTH_DAY_YEAR_TWELVE_HOUR: 'MMM DD, YYYY hh:mm A',
    DAY_MONTH_YEAR_HYPHEN: 'DD-MM-YYYY',
    MONTH_DAY_YEAR_NO_COMMA: 'MMM DD YYYY'
};

export const WeekDays = {
    SUNDAY: 'Sunday',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday'
};

export const SparklineComparisonTypes = {
    PRIOR_DATE_RANGE: 'prior_date_range',
    FINAL_INITIAL_VALUE: 'final_initial_value',
};

export default angular.module('daterange.constants', [])
    .constant('RelativeDateRange', RelativeDateRange)
    .constant('$DateRangeEvents', $DateRangeEvents)
    .constant('MomentDateFormat', MomentDateFormat)
    .constant('WeekDays', WeekDays)
    .constant('SparklineComparisonTypes', SparklineComparisonTypes);
