'use strict';
import angular from 'angular';

angular.module('importwizard.constant', [])
    .constant('IMPORT_CONN_TYPE', {
        FORM_FIELDS: 'form_fields',
        MANUAL: 'manual',
        OAUTH: 'oauth',
        DYNAMIC: 'dynamic',
        LIVE_CONNECTOR: 'live_connector',
    })
    .constant('IMPORT_AUTH_TYPE', {
        ALLOYDB: 'AlloyDB',
        AMAZON_S3: 'Amazon S3',
        AMAZON_ATHENA: 'Amazon Athena',
        AMAZON_AURORA_MYSQL: 'Amazon Aurora MySQL',
        AMAZON_AURORA_PGSQL: 'Amazon Aurora PostgreSQL',
        BIGQUERY: 'Google BigQuery',
        BIGQUERY_SERVICE_ACCOUNT: 'Google BigQuery (Service Account)',
        BIGQUERY_LIVE: 'Google BigQuery (Live)',
        CLICKHOUSE: 'ClickHouse',
        BOX: 'Box',
        BRIGHT_EDGE_QL: 'BrightEdge Query Language',
        EMAIL: 'Email',
        FTP: 'FTP',
        GOOGLE_DRIVE: 'Google Drive',
        GOOGLE_CLOUD_STORAGE: 'Google Cloud Storage',
        GOOGLE_CLOUD_STORAGE_SERVICE_ACCOUNT: 'Google Cloud Storage (Service Account)',
        GOOGLE_SHEET: 'Google Sheet',
        MANUAL: 'Manual',
        GOOGLE_ANALYTICS: 'Google Analytics',
        MICROSOFTAZURESQL: 'Microsoft Azure SQL',
        MICROSOFTAZUREBLOBSTORAGE: 'Microsoft Azure Blob Storage',
        MICROSOFTONEDRIVE: 'Microsoft OneDrive',
        MICROSOFTSQLSERVER: 'Microsoft SQL Server',
        MYSQL: 'MySQL',
        PGSQL: 'PgSQL',
        REDSHIFT: 'Amazon Redshift',
        SFTP: 'SFTP',
        SOQL: 'Salesforce SOQL',
        SNOWFLAKE: 'Snowflake',
        SNOWFLAKE_LIVE: 'Snowflake (Live)',
        TAPAPI: 'TapAPI',
        QUICKBOOKSONLINE: 'QuickBooks Online',
        TAPDIRECT: 'TapDirect',
    })
    .constant('IMPORT_WIZARD', {
        CHANNEL: 'channel',
        DATA_SOURCE: 'data-source',
        FILE: 'file',
        MANUAL: 'manual',
        SQL: 'sql',
        DYNAMIC: 'dynamic',
        READONLY: 'Read only', // 1
        HIDDEN: 'Hidden', // 2
        READONLY_KEY: 'Read only key', // 3
        EDITABLE: 'Editable/Visible', // 0
        SC_VIRTUAL_MAPPING: 'smart_connector_virtual_mapping',
        SC_VIRTUAL_MAPPING_LABEL: 'All Data',
        VIRTUAL_SC_NODATE: 'VIRTUAL_SC_NODATE',
        SC_TAB_MAPPING: 'smart_connector_tab_mapping',
        SC_TAB_MAPPING_LABEL: 'Tab Name',
        SC_GEO_SINGLE_COUNTRY: 'country',
        AUTH_TYPE_CATEGORY_BASIC: 'basic',
        AUTH_TYPE_CATEGORY_HOSTED_FILE: 'hosted_file',
        AUTH_TYPE_CATEGORY_DATABASE: 'database',
        AUTH_TYPE_CATEGORY_DIRECT_TO_SOURCE: 'direct_to_source',
        AUTH_TYPE_CATEGORY_OTHER: 'other',
        AUTH_TYPE_CATEGORY: {
            basic: 'Basic',
            hosted_file: 'Hosted File',
            database: 'Database',
            direct_to_source: 'Direct to Source',
            other: 'Other'
        },
        EXTRA_FORMAT_INTEGER_BIG: 'integer_big',
        EXTRA_FORMAT_STRING_ID: 'string_id',
        TIMEZONE_TYPE_CODE: 'code',
        TIMEZONE_TYPE_FIELD: 'field',
        SMART_CONNECTOR_VIRTUAL_ASSIGMENT_TEXT: 'Smart Connector Virtual Assignment',
        DATETIME: 'Datetime',
        GEO_COUNTRY_FIELD_MODE: {
            COUNTRY_IN_DATA: 'country_in_data',
            SINGLE_COUNTRY: 'single_country'
        },
        GEO_COUNTRY_FIELD_MODES: {
            'country_in_data': 'Select Country field from the Data',
            'single_country': 'Assign all data to a single Country Code'
        },
        COUNTRY: {
            US: 'US'
        },
        GEO_LOCATION_TYPE: {
            COUNTRY: 'country'
        },
        US_DEPENDANT_GEO_LOCATION_TYPES: [
            'state',
            'city',
            'county',
            'congressional_district',
            'zip',
            'dma'
        ]
    })
    .constant('DEFAULT_PREPROCESS_FUNCTION', {
        selected: '',
        url: '',
        custom_fields: [],
        is_custom: false
    })
    .constant('PREPROCESS_FUNCTION_CUSTOM_FUNCTION_VALUE', 'custom');
