/* eslint tap/no-raw-text-js: 0 */ // --> OFF
import _, { forEach, map, first, isArray, isEmpty, find } from 'lodash';
import NumeralService from '@/modules/core/app/services/NumeralService';
import { MapboxRenderService } from '@/modules/ta/widget/services/types/MapboxRenderService';
import { BLUE, SYMBOL } from '@/modules/ta/widget/mapbox.constants';

export const MapboxMixin = {
  props: {
    id: {
      type: String,
      required: true,
    },
    isDashboard: {
      type: Boolean,
      default: true,
    },
    token: {
      type: String,
      required: true,
    },
    state: {
      type: Object,
      default: () => ({}),
    },
    widget: {
      type: Object,
      required: true,
    },
    isExporting: {
      type: Boolean,
      required: true,
    },
    chartPalette: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    scaleControl: {
      type: Object,
      default: () => ({
        show: true,
        position: 'top-left',
      }),
    },
    fullscreenControl: {
      type: Object,
      default: () => ({
        show: true,
        position: 'top-left',
      }),
    },
    geoLocateControl: {
      type: Object,
      default: () => ({
        show: true,
        position: 'top-left',
      }),
    },
    zoomLevel: {
      type: Number,
      default: 4,
    },
    centerPosition: {
      type: Array,
      default: () => [-96, 37.8],
    },
  },
  computed: {
    latLongSource() {
      return this.renderService.getUniqueId('latlongsource', this.id);
    },
    latLongClusterLayer() {
      return this.renderService.getUniqueId('latlongclusterlayer', this.id);
    },
    latLongClusterCountLayer() {
      return this.renderService.getUniqueId('latlongclustercountlayer', this.id);
    },
    latLongUnClusterLayer() {
      return this.renderService.getUniqueId('latlongunclusterlayer', this.id);
    },
    countrySource() {
      return this.renderService.getUniqueId('countrysource', this.id);
    },
    countryLayer() {
      return this.renderService.getUniqueId('countrylayer', this.id);
    },
    stateSource() {
      return this.renderService.getUniqueId('statesource', this.id);
    },
    stateLayer() {
      return this.renderService.getUniqueId('statelayer', this.id);
    },
    citySource() {
      return this.renderService.getUniqueId('citysource', this.id);
    },
    cityLayer() {
      return this.renderService.getUniqueId('citylayer', this.id);
    },
    countySource() {
      return this.renderService.getUniqueId('countysource', this.id);
    },
    countyLayer() {
      return this.renderService.getUniqueId('countylayer', this.id);
    },
    zipSource() {
      return this.renderService.getUniqueId('ziplinesource', this.id);
    },
    zipLayer() {
      return this.renderService.getUniqueId('ziplinelayer', this.id);
    },
    dMASource() {
      return this.renderService.getUniqueId('dmasource', this.id);
    },
    dMALayer() {
      return this.renderService.getUniqueId('dmalayer', this.id);
    },
    congressionalDistrictSource() {
      return this.renderService.getUniqueId('congressionaldistrictsource', this.id);
    },
    congressionalDistrictLayer() {
      return this.renderService.getUniqueId('congressionaldistrictlayer', this.id);
    },
  },
  methods: {
    resetMap() {
      this.resetLatLong();
      this.resetCountry();
      this.resetState();
      this.resetCounty();
      this.resetCity();
      this.resetZipLine();
      this.resetDMA();
      this.resetCongressionalDistrict();
    },
    resetLatLong() {
      if (this.map.getLayer(this.latLongClusterLayer)) {
        this.map.removeLayer(this.latLongClusterLayer);
      }
      if (this.map.getLayer(this.latLongClusterCountLayer)) {
        this.map.removeLayer(this.latLongClusterCountLayer);
      }
      if (this.map.getLayer(this.latLongUnClusterLayer)) {
        this.map.removeLayer(this.latLongUnClusterLayer);
      }
      if (this.map.getSource(this.latLongSource)) {
        this.map.removeSource(this.latLongSource);
      }
    },
    resetState() {
      if (this.map.getLayer(this.stateLayer)) {
        this.map.removeLayer(this.stateLayer);
      }
      if (this.map.getSource(this.stateSource)) {
        this.map.removeSource(this.stateSource);
      }
    },
    resetCity() {
      if (this.map.getLayer(this.cityLayer)) {
        this.map.removeLayer(this.cityLayer);
      }
      if (this.map.getSource(this.citySource)) {
        this.map.removeSource(this.citySource);
      }
    },
    resetCountry() {
      if (this.map.getLayer(this.countryLayer)) {
        this.map.removeLayer(this.countryLayer);
      }
      if (this.map.getSource(this.countrySource)) {
        this.map.removeSource(this.countrySource);
      }
    },
    resetZipLine() {
      if (this.map.getLayer(this.zipLayer)) {
        this.map.removeLayer(this.zipLayer);
      }
      if (this.map.getSource(this.zipSource)) {
        this.map.removeSource(this.zipSource);
      }
    },
    resetCounty() {
      if (this.map.getLayer(this.countyLayer)) {
        this.map.removeLayer(this.countyLayer);
      }
      if (this.map.getSource(this.countySource)) {
        this.map.removeSource(this.countySource);
      }
    },
    resetDMA() {
      if (this.map.getLayer(this.dMALayer)) {
        this.map.removeLayer(this.dMALayer);
      }
      if (this.map.getSource(this.dMASource)) {
        this.map.removeSource(this.dMASource);
      }
    },
    resetCongressionalDistrict() {
      if (this.map.getLayer(this.congressionalDistrictLayer)) {
        this.map.removeLayer(this.congressionalDistrictLayer);
      }
      if (this.map.getSource(this.congressionalDistrictSource)) {
        this.map.removeSource(this.congressionalDistrictSource);
      }
    },
    getMatchData() {
      const firstSelectedColumn = first(this.renderService.getSelectedColumns(this.widget));
      const groupedColumn = MapboxRenderService.getSelectedColumn(this.widget);
      const cachedData = [];
      const nonBoundaryData = [];
      const boundaryData = [];
      const columnGradients = MapboxRenderService.getColumnGradients(
        this.getData(),
        firstSelectedColumn.field
      );
      for (let j = 0; j < this.getData().length; j++) {
        const row = this.getData()[j];
        const color = MapboxRenderService.getGradientColor(
          firstSelectedColumn.color ||
            (this.widget.metadata.chart_palette && this.widget.metadata.chart_palette[0]) ||
            this.chartPalette[0],
          MapboxRenderService.getColumnGradientValue(
            columnGradients,
            row[firstSelectedColumn.field]
          )
        );
        let value = row[groupedColumn];
        if (
          this.renderService.canShowCountryLayer(this.widget) &&
          MapboxRenderService.isValidGeoConfig(row.geocode0)
        ) {
          value = MapboxRenderService.getValueFromGeoConfig(row.geocode0);
        }
        if (
          this.renderService.canShowStateLayer(this.widget) &&
          MapboxRenderService.isValidGeoConfig(row.geocode1)
        ) {
          value = MapboxRenderService.getValueFromGeoConfig(row.geocode1);
        }
        if (
          this.renderService.canShowCountyLayer(this.widget) &&
          MapboxRenderService.isValidGeoConfig(row.geocode2)
        ) {
          value = MapboxRenderService.getValueFromGeoConfig(row.geocode2);
        }
        if (value && !cachedData.includes(value.toUpperCase())) {
          cachedData.push(value.toUpperCase());
          boundaryData.push(value.toUpperCase(), BLUE);
          nonBoundaryData.push(value.toUpperCase(), color);
        }
      }
      return [boundaryData, nonBoundaryData];
    },
    getCityMatchData(isBoundary = false) {
      const cachedData = {};
      const matchData = {};
      const firstSelectedColumn = first(this.renderService.getSelectedColumns(this.widget));
      const groupedColumn = MapboxRenderService.getSelectedColumn(this.widget);
      const columnGradients = MapboxRenderService.getColumnGradients(
        this.getData(),
        firstSelectedColumn.field
      );
      for (let j = 0; j < this.getData().length; j++) {
        const row = this.getData()[j];
        let color = BLUE;
        if (!isBoundary) {
          color = MapboxRenderService.getGradientColor(
            firstSelectedColumn.color ||
              (this.widget.metadata.chart_palette && this.widget.metadata.chart_palette[0]) ||
              this.chartPalette[0],
            MapboxRenderService.getColumnGradientValue(
              columnGradients,
              row[firstSelectedColumn.field]
            )
          );
        }
        const value = row[groupedColumn];
        const stateJoin = row.geocode1;
        if (stateJoin) {
          const stateDetails = stateJoin.split('-');
          if (!cachedData[stateDetails[1]]) {
            cachedData[stateDetails[1]] = [];
          }
          if (value && !cachedData[stateDetails[1]].includes(value)) {
            cachedData[stateDetails[1]].push(value);
            if (!matchData[stateDetails[1]]) {
              matchData[stateDetails[1]] = [];
            }
            matchData[stateDetails[1]].push(value.toUpperCase(), color);
          }
        }
      }
      return matchData;
    },
    tileSetColor(fields) {
      fields = isArray(fields) ? fields : [fields];
      const [boundaryMatchData, matchData] = this.getMatchData();
      if (isEmpty(matchData) && isEmpty(boundaryMatchData)) {
        return ['rgba(255,255,255,0)', 'rgba(255,255,255,0)'];
      }
      let boundaryMatchExpression = [];
      let matchExpression = [];

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const expression = ['match', ['upcase', ['get', field]], ...matchData];
        const boundaryExpression = ['match', ['upcase', ['get', field]], ...boundaryMatchData];

        if (i === fields.length - 1) {
          expression.push('rgba(255, 255, 255, 0)');
          boundaryExpression.push('rgba(255, 255, 255, 0)');
        }
        if (matchExpression.length === 0) {
          matchExpression = expression;
        } else {
          let pre = matchExpression;
          let last = _.last(matchExpression);
          while (isArray(last)) {
            pre = last;
            last = _.last(last);
          }
          pre.push(expression);
        }

        if (boundaryMatchExpression.length === 0) {
          boundaryMatchExpression = boundaryExpression;
        } else {
          let pre = boundaryMatchExpression;
          let last = _.last(boundaryMatchExpression);
          while (isArray(last)) {
            pre = last;
            last = _.last(last);
          }
          pre.push(boundaryExpression);
        }
      }
      return [boundaryMatchExpression, matchExpression];
    },
    updateLatLongLayer() {
      if (this.renderService.canShowLatLongLayer(this.widget)) {
        this.map.addSource(this.latLongSource, {
          type: 'geojson',
          data: this.mapboxData,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });
        this.map.addLayer({
          id: this.latLongClusterLayer,
          type: 'circle',
          source: this.latLongSource,
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#51bbd6',
              100,
              '#f1f075',
              750,
              '#f28cb1',
            ],
            'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
          },
        });
        this.map.addLayer({
          id: this.latLongClusterCountLayer,
          type: 'symbol',
          source: this.latLongSource,
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-size': 12,
          },
        });
        this.map.addLayer({
          id: this.latLongUnClusterLayer,
          type: 'circle',
          source: this.latLongSource,
          filter: ['!has', 'point_count'],
          paint: {
            'circle-color':
              first(this.renderService.getSelectedColumns(this.widget)).color ||
              (this.widget.metadata.chart_palette && this.widget.metadata.chart_palette[0]) ||
              this.chartPalette[0] ||
              '#F72119',
            'circle-radius': 4,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
          },
        });
      }
    },
    updateCountryLayer() {
      if (this.renderService.canShowCountryLayer(this.widget)) {
        this.map.addSource(this.countrySource, {
          type: 'vector',
          url: 'mapbox://mapbox.country-boundaries-v1',
        });
        const [, fillColor] = this.tileSetColor(['name', 'iso_3166_1', 'iso_3166_1_alpha_3']);
        this.map.addLayer(
          {
            id: this.countryLayer,
            source: this.countrySource,
            'source-layer': 'country_boundaries',
            type: 'fill',
            paint: {
              'fill-color': fillColor,
              'fill-opacity': this.getLayerOpacity(),
            },
            filter: ['has', 'iso_3166_1_alpha_3'],
          },
          this.getSymbolLayer()
        );
      }
    },
    updateStateLayer() {
      if (this.renderService.canShowStateLayer(this.widget)) {
        this.map.addSource(this.stateSource, {
          type: 'vector',
          url: 'mapbox://tapclicks.cwo7b88z',
        });
        const [fillOutlineColor, fillColor] = this.tileSetColor(['NAME', 'STUSPS']);
        this.map.addLayer(
          {
            id: this.stateLayer,
            type: 'fill',
            source: this.stateSource,
            'source-layer': 'tl_rd22_us_state-2qpq3k',
            paint: {
              'fill-outline-color': fillOutlineColor,
              'fill-color': fillColor,
              'fill-opacity': this.getLayerOpacity(),
            },
          },
          this.getSymbolLayer()
        );
      }
    },
    updateCountyLayer() {
      if (this.renderService.canShowCountyLayer(this.widget)) {
        this.map.addSource(this.countySource, {
          type: 'vector',
          url: 'mapbox://tapclicks.1e1hc39s',
        });
        const [fillOutlineColor, fillColor] = this.tileSetColor(['NAME', 'NAMELSAD']);
        this.map.addLayer(
          {
            id: this.countyLayer,
            type: 'fill',
            source: this.countySource,
            'source-layer': 'tl_rd22_us_county-0pz5qq',
            paint: {
              'fill-outline-color': fillOutlineColor,
              'fill-color': fillColor,
              'fill-opacity': this.getLayerOpacity(),
            },
          },
          this.getSymbolLayer()
        );
      }
    },
    cityTileSetColor(fields, isBoundary = false) {
      fields = isArray(fields) ? fields : [fields];
      const matchData = this.getCityMatchData(isBoundary);
      if (isEmpty(matchData)) {
        return 'rgba(255, 255, 255,0)';
      }

      const stateExpression = ['match', ['upcase', ['get', 'STUSPS']]];
      let matchExpression = [];
      forEach(matchData, (cityInfo, state) => {
        matchExpression = [];
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          const expression = ['match', ['upcase', ['get', field]], ...cityInfo];
          if (i === fields.length - 1) {
            expression.push('rgba(255, 255, 255, 0)');
          }
          if (matchExpression.length === 0) {
            matchExpression = expression;
          } else {
            let pre = matchExpression;
            let last = _.last(matchExpression);
            while (isArray(last)) {
              pre = last;
              last = _.last(last);
            }
            pre.push(expression);
          }
        }
        stateExpression.push(state.toUpperCase(), matchExpression);
      });
      stateExpression.push('rgba(255, 255, 255, 0)');
      return stateExpression;
    },
    updateCityLayer() {
      if (this.renderService.canShowCityLayer(this.widget)) {
        this.map.addSource(this.citySource, {
          type: 'vector',
          url: 'mapbox://tapclicks.2sp9ab9u',
        });
        this.map.addLayer(
          {
            id: this.cityLayer,
            type: 'fill',
            source: this.citySource,
            'source-layer': 'cb_2022_us_place_500k-52iz77',
            paint: {
              'fill-outline-color': this.cityTileSetColor(['NAME', 'STUSPS', 'NAMELSAD'], true),
              'fill-color': this.cityTileSetColor(['NAME', 'STUSPS', 'NAMELSAD']),
              'fill-opacity': this.getLayerOpacity(),
            },
          },
          this.getSymbolLayer()
        );
      }
    },
    updateZipLayer() {
      if (this.renderService.canShowZipCodeLayer(this.widget)) {
        this.map.addSource(this.zipSource, {
          type: 'vector',
          url: 'mapbox://tapclicks.blqzgepw',
        });
        const [fillOutlineColor, fillColor] = this.tileSetColor('NAME20');
        this.map.addLayer(
          {
            id: this.zipLayer,
            type: 'fill',
            source: this.zipSource,
            'source-layer': 'cb_2020_us_zcta520_500k-a84xva',
            paint: {
              'fill-outline-color': fillOutlineColor,
              'fill-color': fillColor,
              'fill-opacity': this.getLayerOpacity(),
            },
            filter: ['has', 'NAME20'],
          },
          this.getSymbolLayer()
        );
      }
    },
    updateDMALayer() {
      if (this.renderService.canShowDmaLayer(this.widget)) {
        this.map.addSource(this.dMASource, {
          type: 'vector',
          url: 'mapbox://tapclicks.67094e1h',
        });
        const [fillOutlineColor, fillColor] = this.tileSetColor(['NAME', 'Key']);
        this.map.addLayer(
          {
            id: this.dMALayer,
            type: 'fill',
            source: this.dMASource,
            'source-layer': 'NatDMA-5bs38p',
            paint: {
              'fill-outline-color': fillOutlineColor,
              'fill-color': fillColor,
              'fill-opacity': this.getLayerOpacity(),
            },
          },
          this.getSymbolLayer()
        );
      }
    },
    updateCongressionalDistrictLayer() {
      if (this.renderService.canShowCongressionalDistrictLayer(this.widget)) {
        this.map.addSource(this.congressionalDistrictSource, {
          type: 'vector',
          url: 'mapbox://tapclicks.cju5nw9b',
        });
        const [fillOutlineColor, fillColor] = this.tileSetColor(['NAMELSAD', 'STATEFP']);
        this.map.addLayer(
          {
            id: this.congressionalDistrictLayer,
            type: 'fill',
            source: this.congressionalDistrictSource,
            'source-layer': 'cb_2022_us_cd118_500k-dbmhqv',
            paint: {
              'fill-outline-color': fillOutlineColor,
              'fill-color': fillColor,
              'fill-opacity': this.getLayerOpacity(),
            },
          },
          this.getSymbolLayer()
        );
      }
    },
    handleLayerHover(event, values, hoveredName) {
      const dataColumns = this.renderService.getSelectedColumns(this.widget);
      const temp = {};
      forEach(values, (val) => {
        forEach(dataColumns, (column) => {
          if (temp[column.field]) {
            temp[column.field] += Number(val[column.field]);
          } else {
            temp[column.field] = Number(val[column.field]);
          }
        });
      });

      this.popUp
        .setLngLat(event.lngLat)
        .setHTML(
          this.renderService.getPopupHtml({
            grouped: JSON.stringify({
              field: hoveredName,
            }),
            columns: JSON.stringify(
              map(dataColumns, (column, index) => ({
                field: NumeralService.formatValue(
                  temp[column.field],
                  column.format,
                  column.precision,
                  column.isCompactNumber,
                  column.currencySymbol
                ),
                label: column.label,
                color:
                  column.color ||
                  this.renderService.getPaletteColor(
                    index,
                    this.widget.metadata.chart_palette || this.chartPalette
                  ),
              }))
            ),
          })
        )
        .addTo(this.map);
    },
    getSymbolLayer() {
      if (this.widget.metadata.draw_options.map_geographic_features) {
        return null;
      }
      const { layers } = this.map.getStyle();
      return find(layers, (layer) => layer.type === SYMBOL)?.id;
    },
    getLayerOpacity() {
      const { geo_layer_opacity } = this.widget.metadata.draw_options;

      if (!geo_layer_opacity) {
        return 1;
      }

      return geo_layer_opacity / 100;
    },
  },
};
