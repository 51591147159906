export const ListControlViewType = {
  GALLERY: 'gallery',
  DATATABLE: 'datatable',
  LISTCARDS: 'listcards',
};

export const FilterType = {
  BTW: 'btw',
  GT: 'gt',
  LT: 'lt',
  SINGLE_SELECT: 'single_select',
};

export const PredefinedFilterSlotPrefix = 'predefined_filter_';

export const PredefinedFilterChangeEvent = 'on-predefined-filter-change';

export const DomEvents = {
  SCROLL: 'scroll',
  DOM_MOUSE_SCROLL: 'DOMMouseScroll',
  MOUSE_WHEEL: 'mousewheel',
  TOUCH_START: 'touchstart',
  TOUCH_MOVE: 'touchmove',
};

export const TouchEventType = {
  TOUCH_START: 'touchstart',
  TOUCH_MOVE: 'touchmove',
};
